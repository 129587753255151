import React, { useState } from 'react';
import MultipleItems from '../../Carousels/MultipleItems';
import Dropzone from '../../Dropzone/Dropzone';

const MultiImageInput = (props) => {
    const images = props.field.value.map(x=>x.localSrc)
    
    return (
        <>
            <div>
                {props.field.dataToggle && 
                <a id={props.id} 
                    data-toggle={props.field.dataToggle}
                    title={props.field.title}
                    data-placement={props.field.dataPlacement}
                />}
                <Dropzone {...props} />
            </div>
            <div className="mt-5">
                <MultipleItems 
                    slidesToShow={14} 
                    laptop={11} 
                    tablet={7}
                    mobile={3} 
                    images={images} 
                    removePhoto={props.field.onRemove}
                />
            </div>
           
        </>
    );
}

export default MultiImageInput;