import { useEffect, useState } from "react"
import { getLocateTickets, getLocateTicketsJobs } from "../../../shared/api/LocateTicketsApi";
import { LocateTicketEntity } from "../../../shared/data/LocateTickets";
import { JobEntity } from "../../../shared/data/job/Job";

function useLocateTickets(inventoryId:string) {
    const [locateTickets, setLocateTickets] = useState<LocateTicketEntity[]>([]);
    const [jobs, setJobs] = useState<JobEntity[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const responseLocateTickets = await getLocateTickets(inventoryId);
            const responseLocateTicketData = (responseLocateTickets.data ?? []).sort((a, b) => (a.ticketID ?? "") > (b.ticketID ?? "") ? 1 : -1);

            var responseJobs = null
            if (inventoryId) {
                const jobIds = responseLocateTicketData?.filter((data)=>(data && data.jobID && data.jobID!='')).map((data)=>data.jobID??"")
                responseJobs = await getLocateTicketsJobs(jobIds, inventoryId)
            }
            setLocateTickets(responseLocateTicketData)
            setJobs(responseJobs?.data as JobEntity[])
        }

        fetchData()
    }, [inventoryId])

    return { locateTickets, jobs }

}

export default useLocateTickets