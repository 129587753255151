import React, { Component } from 'react';
import * as moment from 'moment';
import SimpleForm from '../../../components/Form/SimpleForm';
import { Edit } from '../../../components/Icons/Svg';

class JobDetailsContainer extends Component {
    state = {
        editMode: false
    }

    toggleEditMode = () => {
        this.setState({ editMode: !this.state.editMode });
    }

    getDates = () => {
        return moment(this.props.job?.startDate?.toDate()).format("MM/DD/YY") + " - " + moment(this.props.job?.endDate?.toDate()).format("MM/DD/YY");
    }

    capitalize = (str) => {
        if (!str)
            return str;

        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    edit = () => {
        return (
            <div>
                <SimpleForm
                    formUniqueName="edit-job-details"
                    fields={this.props.form.fields}
                    touchField={this.props.touchField}
                    submitButton={this.submitButton()}
                    cancelButton={this.cancelButton()}
                    handleInputChange={this.props.handleChange}
                    onSubmit={async (e) => { e.preventDefault(); await this.props.handleSubmit(e); this.toggleEditMode(); }}
                />
            </div>
        );
    }

    cancelButton = () => {
        return {
            title: "Cancel",
            className: "btn btn-secondary mr-2",
            onClick: ()=>{
                this.props.resetForm();
                this.toggleEditMode();
            }
        };
    }

    submitButton = () => { 
        const submitBtnClasses = 'btn btn-primary mr-2';
        const submitBtnClassesWithError = this.props.form.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
        const submitBtnClassesFinal = this.props.form.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

        return {
            title: "Save",
            className: submitBtnClassesFinal,
            onClick: ()=>{
                this.props.handleSubmit()
                this.toggleEditMode();
            },
            disabled: this.props.form.loading
        };
    }

    getJobStatusString = (status) => {
        if(status === 'draft') {
            return "Bid";
        }

        if(status === 'published') {
            return "Active Job";
        }

        if(status === 'completed') {
            return "Completed Job";
        }

        return status;
    }

    preview = () => {
        return (
            <div className="mt-2 p-5">
                <div className="d-flex justify-content-between mb-2">
                    <span className="text-dark-75 font-weight-bolder mr-2">Job:</span>
                    <a href="#" className="text-muted text-hover-primary">{this.props.job.title}</a>
                </div>
                <div className="d-flex justify-content-between my-1 mb-2">
                    <span className="text-dark-75 font-weight-bolder mr-2">ID:</span>
                    <a href="#" className="text-muted text-hover-primary">{this.props.job.jobID}</a>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <span className="text-dark-75 font-weight-bolder mr-2">Dates:</span>
                    <span className="text-muted font-weight-bold">{this.getDates()}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <span className="text-dark-75 font-weight-bolder mr-2">Status:</span>
                    <span className="text-muted font-weight-bold">{this.getJobStatusString(this.props.job.status)}</span>
                </div>
                <div className="d-flex justify-content-between my-1 mb-2">
                    <span className="text-dark-75 font-weight-bolder mr-2">Total Footage:</span>
                    <a href="#" className="text-muted text-hover-primary">{this.props.job.totalFootage}</a>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <span className="text-dark-75 font-weight-bolder mr-2">Description:</span>
                    <span style={{ whiteSpace: "pre-line" }} className="text-muted font-weight-bold pl-6">{this.props.job.description}</span>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column mr-auto">
                                <a href="#" className="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1">Job Details</a>
                            </div>
                            {!this.state.editMode && this.props.canEdit && !this.props.form.loading &&
                                <div className="card-toolbar mb-auto">
                                    <div className="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                        <a onClick={this.toggleEditMode} className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="svg-icon svg-icon-primary svg-icon-2x">
                                                <Edit/>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            }
                            {this.props.form.loading &&
                                <div className="card-toolbar mb-auto">
                                    <button className="btn spinner spinner-black spinner-right"></button>
                                </div>
                            }
                        </div>
                        {this.state.editMode ? this.edit() : this.preview()}
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default JobDetailsContainer;