import React, { useContext, useState } from "react";
import { SelectClient, Trash } from "../../../../components/Icons/Svg";
import { ClientEntity } from "../../../../shared/data/ClientEntity";
import { useHistory } from "react-router";
import { AuthContext } from "../../../../shared/Auth";
import { ContactEntity } from "../../../../shared/data/ContactEntity";
import DeleteClientConfirmation from "./DeleteClientConfirmation";
import SelectContactList from "./SelectContactList";
import { JobClient } from "../../../../shared/data/job/Job";

interface JobClientListProps {
  clients?: ClientEntity[] | null;
  jobClients?: JobClient[] | null;
  onSelectClient?: () => void;
  onRemoveClient?: (clientId: string) => void;
  onUpdateJobClient?: (jobClient: JobClient) => void;
}

const JobClientList = ({
  clients,
  jobClients = [],
  onSelectClient = () => {},
  onRemoveClient = () => {},
  onUpdateJobClient = () => {},
}: JobClientListProps) => {
  const history = useHistory();

  const [formState, setFormState] = useState<{
    selectedClient?: ClientEntity | null;
    selectedContacts?: ContactEntity[] | null;
    deleteClient: boolean;
    selectContact: boolean;
  }>({
    selectedClient: null,
    selectedContacts: null,
    deleteClient: false,
    selectContact: false,
  });

  return (
    <React.Fragment>
      {formState.deleteClient && formState.selectedClient != null && (
        <DeleteClientConfirmation
          client={formState.selectedClient}
          onRemoveClient={(clientId) => {
            if (clientId != null) {
              onRemoveClient(clientId);
            }
            setFormState((previousState) => {
              return {
                ...previousState,
                selectedClient: null,
                deleteClient: false,
                selectContact: false,
              };
            });
          }}
        />
      )}
      {formState.selectContact && (
        <SelectContactList
          contacts={formState.selectedClient?.contacts ?? []}
          preSelectedContacts = {jobClients?.find((jc)=>jc.clientID==formState.selectedClient?.id)?.contactIDs??[]}
          onContactsSelected={(contacts) => {
            const contactIDs =
              contacts?.map((c) => c?.id ?? "").filter((c) => c.length > 0) ??
              [];
            if (contacts != null) {
              onUpdateJobClient(
                new JobClient(formState.selectedClient?.id ?? "", contactIDs)
              );
            }
            setFormState((previousState) => {
              return {
                ...previousState,
                selectedClient: null,
                selectedContacts: null,
                deleteClient: false,
                selectContact: false,
              };
            });
          }}
        />
      )}

      {!formState.deleteClient && !formState.selectContact && (
        <div key={`job-client-list`}>
          <div className="navbar-nav-scroll h-200px">
            {clients?.map((client) => {
              return (
                <div>
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className="d-flex flex-column flex-row-fluid pointer"
                      onClick={() => {
                        history.push("/clients/" + client.id + "/edit");
                      }}
                    >
                      <h6>{client?.companyName ?? "No name"}</h6>
                      <span>{client?.phoneNumber ?? "No phone number"}</span>
                    </div>
                    <div
                      className="d-flex flex-column flex-row-auto mr-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (client.id)
                          setFormState((previousState) => {
                            return {
                              ...previousState,
                              selectedClient: client,
                              deleteClient: false,
                              selectContact: true,
                            };
                          });
                      }}
                    >
                      <SelectClient />
                    </div>
                    <div
                      className="d-flex flex-column flex-row-auto mr-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (client.id)
                          setFormState((previousState) => {
                            return {
                              ...previousState,
                              selectedClient: client,
                              deleteClient: true,
                              selectContact: false,
                            };
                          });
                      }}
                    >
                      <Trash />
                    </div>
                  </div>
                  <div className="separator separator-solid my-1"></div>
                </div>
              );
            })}
          </div>
          <button
            className="btn btn-primary text-uppercase float-right mt-8"
            onClick={() => onSelectClient()}
          >
            Select clients and contacts
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default JobClientList;
