import React, { useContext, useEffect, useState } from "react";
import {
  ImageEntity,
  TimeLogEntity,
} from "../../../../shared/data/taskmanagement/TimeLog";
import {
  getImageUrlFromFirebase,
  parseFromFirebaseDate,
} from "../../../../shared/Util";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../shared/Auth";
import Viewer from "react-viewer";
import { TaskEntity } from "../../../../shared/data/taskmanagement/Task";

interface TimeLogItemProps {
  timeLog: TimeLogEntity;
  task?: TaskEntity;
  onDelete: (timeLogId: string) => void;
}

export const TimeLogItem = ({
  timeLog,
  task,
  onDelete = () => {},
}: TimeLogItemProps) => {
  const auth = useContext(AuthContext);
  const [imageUrls, setImageUrls] = useState<Map<number, string>>(new Map());
  const [imageViewer, setImageViewer] = useState<boolean>(false);

  const getImages = async () => {
    const imageEntities: ImageEntity[] =
      TimeLogEntity.getAllImagesUrls(timeLog);
    setImageUrls(() => {
      const urls = new Map<number, string>();
      imageEntities.forEach((item) => {
        if (item.value) urls.set(item.value.seconds, "");
      });
      return urls;
    });
    imageEntities.forEach(async (image) => {
      const result = await getImageUrlFromFirebase(
        auth.currentUser.userProfile.companyID,
        image.src
      );
      if (result)
        setImageUrls((previousState) => {
          const imageUrl =  new Map(previousState)
          if (image.value?.seconds) imageUrl.set(image.value.seconds, result);
          return imageUrl;
        });
    });
  };

  useEffect(() => {
    if (timeLog.imageTimeStamp) getImages();
  }, []);

  const getViewerImages = () => {
    const urls = Array.from(imageUrls?.values() ?? []);
    const viewImages = urls.map((url) => {
      return { src: url };
    });
    return viewImages;
  };

  return (
    <React.Fragment>
      <div className="card p-6 mt-6">
        <div className="d-flex flex-row align-items-center col-stretch mb-1">
          <div className="d-flex flex-column flex-sm-wrap col-stretch ml-1">
            <div className="font-weight-boldest mb-2">Spent hours</div>
            <div>{timeLog.duration} h</div>
            <div className="font-weight-boldest mb-2">Start date and time</div>
            <div>
              {" "}
              {parseFromFirebaseDate(timeLog.startDate, "MM/DD/YY hh:mm A")}
            </div>
            <div className="font-weight-boldest mb-2">Notes</div>
            <div>{timeLog.notes}</div>
          </div>

          <div
            className="d-flex flex-row flex-column-fluid ml-10"
            id="time_log_image"
            onClick={() => {
              if (imageUrls) setImageViewer(() => true);
            }}
          >
            {imageUrls?.size > 0 && (
              <div className="symbol symbol-100 symbol-sm m-2">
                <div
                  style={{
                    backgroundImage: `url('${
                      Array.from(imageUrls?.values())[0]
                    }')`,
                  }}
                  className="symbol-label"
                >
                  <span className="badge badge-square badge-dark">
                    {imageUrls.size}
                  </span>
                </div>
              </div>
            )}
          </div>

          <Viewer
            images={getViewerImages()}
            visible={imageViewer}
            onClose={() => {
              setImageViewer(() => false);
            }}
            activeIndex={0}
            onChange={(image, index) => {}}
          />

          <div
            className="d-flex flex-column dropdown dropdown-inline ml-2"
            data-toggle="tooltip"
            title="Quick actions"
            data-placement="left"
          >
            <a
              className="btn btn-hover-light-primary btn-sm btn-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="ki ki-bold-more-hor" />
            </a>

            <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
              <ul className="navi navi-hover">
                {/* {canEdit && ( */}
                <li className="navi-item">
                  <Link
                    to={{
                      pathname: `/jobs/${timeLog.jobID}/tasks/timelog`,
                      state: {
                        timeLog: timeLog,
                        task: task,
                      },
                    }}
                    href="#"
                    className="navi-link"
                  >
                    <span className="navi-text">Edit Timelog</span>
                  </Link>
                </li>
                {/* )} */}
                {/* {canEdit && ( */}
                <li className="navi-item">
                  <div
                    onClick={() => {
                      onDelete(timeLog.id ?? "");
                    }}
                    className="navi-link"
                  >
                    <span className="navi-text">Delete Timelog</span>
                  </div>
                </li>
                {/* )} */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimeLogItem;
