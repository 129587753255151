import React, { Component } from 'react';
import { db } from '../../firebase';
import * as moment from 'moment';

import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import ConfirmModal from '../../components/Modals/ConfirmModal';

import { Link, useHistory } from 'react-router-dom';

import { storage } from '../../firebase';
import { CheckIcon, Edit, Trash, UpdateIcon } from '../../components/Icons/Svg';
import { getMessages } from '../../components/Messages/GetMessages';
import { showToast } from '../../shared/Util';
import { addCrewSchedule } from '../../shared/Api';

class JobCrewDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  canEdit = this.user.permissions !== "crewmember";

  state = {
    crewmember: null,
    appointment: null,
    deleteCrewModalActive: false,
    startDate: null,
    endDate: null,
    allAppointments: [],
    updating: false,
    removing: false,
    reserving: false,
    canAdd: true,
    reservedDates: [],
  };

  componentDidMount = async () => {
    this.loadData();
  };

  loadData = async () => {
    const jobId = this.props.match.params.id;
    const crewId = this.props.match.params.crewmemberId;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    let job = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("jobs")
      .doc(jobId)
      .get();
    job = job.data();
    job.id = jobId;

    let result = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("crew")
      .doc(crewId)
      .get();

    let obj = result.data();
    obj.id = result.id;

    if (obj.hasProfileImage && obj.imageTimeStamp) {
      try {
        const storageRef = storage.ref(
          "/" +
            currentUser.userProfile.companyID +
            "/crewMember/" +
            obj.id +
            "/" +
            obj.imageTimeStamp.seconds +
            "/"
        );
        const url = storageRef
          .child("med")
          .getDownloadURL()
          .then((url) => {
            obj.image = url;
            this.setState({ crewmember: obj });
          })
          .catch((err) => {
            this.setState({ crewmember: obj });
          });
      } catch (error) {}
    } else {
      this.setState({ crewmember: obj });
    }

    result = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("crew_schedules")
      .where("jobID", "==", jobId)
      .where("crewMemberID", "==", crewId)
      .get();

    const datacrewSchedules = await Promise.all(
      result.docs.map(async (doc) => {
        let dat = doc.data();
        dat.id = doc.id;
        return dat;
      })
    );

    let allAppointments = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("crew_schedules")
      .where("crewMemberID", "==", crewId)
      .get();
    allAppointments = await Promise.all(
      allAppointments.docs.map(async (doc) => {
        let dat = doc.data();
        dat.id = doc.id;
        return dat;
      })
    );

    const appointment =
      datacrewSchedules && datacrewSchedules.length > 0
        ? datacrewSchedules[0]
        : null;
    const sd = moment(
      this.getDatesForDatepicker(job, allAppointments, "start", appointment)
    );
    const ed = moment(
      this.getDatesForDatepicker(job, allAppointments, "end", appointment)
    );

    // this.setState({ startDate: sd, endDate: ed });

    // this.state.allAppointments.forEach(x => {
    //     if (x.jobID !== job.id) {
    //         let tmpInvalid = (moment(date).isBetween(moment(x.startDate.toDate()), moment(x.endDate.toDate()), undefined, '[]'));
    //         if (tmpInvalid) invalid = true;
    //     }
    // });

    const reservedDates = this.getCrewmemberReservedDates(allAppointments, job);

    this.setState({
      startDate: sd,
      endDate: ed,
      appointment: appointment,
      crewmember: obj,
      allAppointments: allAppointments,
      reservedDates: reservedDates,
    });
    this.setDates(appointment, job, allAppointments);
  };

  getCrewmemberReservedDates = (appointments, job) => {
    const result = appointments
      .filter((appointment) => {

        return appointment.jobID !== job.id
      })
      .map((appointment) => {
        const startDate = moment(appointment.startDate.toDate()).format(
          "MM/DD/YY"
        );
        const endDate = moment(appointment.endDate.toDate()).format("MM/DD/YY");
        return `[${startDate} - ${endDate}]`;
      });

      return result
  };

  setDates = (appointment, job, allAppointments) => {
    const self = this;
    const id = "daterangepicker_appointment_dates";

    window.$("#" + id).daterangepicker(
      {
        buttonClasses: "btn",
        cellClass: (date) => {
          return "red";
        },
        applyClass: "btn-primary",
        cancelClass: "btn-secondary",
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        minDate: job.startDate.toDate(),
        maxDate: job.endDate.toDate(),
      },
      function (start, end, label) {
        self.setState({ startDate: moment(start), endDate: moment(end) });
      }
    );
  };

  getDatesForDatepicker = (job, allAppointments, dateType, appointment) => {
    if (appointment && dateType === "start") {
      return appointment.startDate.toDate();
    }
    if (appointment && dateType === "end") {
      return appointment.endDate.toDate();
    }

    const jobDates = this.getJobDates(job);
    let availableDates = [];

    if (
      !allAppointments ||
      (allAppointments.length === 0 && dateType === "start")
    ) {
      return job.startDate.toDate();
    }

    if (
      !allAppointments ||
      (allAppointments.length === 0 && dateType === "end")
    ) {
      return job.endDate.toDate();
    }

    jobDates.forEach((date) => {
      let isValid = true;
      allAppointments.forEach((x) => {
        if (x.jobID !== job.id) {
          let tmpValid = !moment(date).isBetween(
            moment(x.startDate.toDate()),
            moment(x.endDate.toDate()),
            undefined,
            "[]"
          );
          if (!tmpValid) {
            isValid = false;
          }
        }
      });
      if (isValid) {
        availableDates.push(date);
      }
    });

    if (
      !availableDates ||
      (availableDates.length === 0 && dateType === "start")
    ) {
      this.setState({ canAdd: false });
      return job.startDate.toDate();
    }
    if (
      !availableDates ||
      (availableDates.length === 0 && dateType === "end")
    ) {
      return job.endDate.toDate();
    }

    let indexToFind = this.getLastConsecutiveIndex(availableDates);

    if (dateType === "start") {
      return availableDates[0];
    }

    if (dateType === "end") {
      return availableDates[indexToFind];
    }
  };

  getLastConsecutiveIndex = (arr) => {
    let index = arr.length - 1;

    if (arr.length === 1) {
      return arr.length - 1;
    }

    for (let i = 1; i < arr.length; i++) {
      if (moment(arr[i]).diff(moment(arr[i - 1]), "days") > 1) {
        index = i - 1;
        break;
      }
    }
    return index;
  };

  getJobDates = (job) => {
    let startDate = job.startDate.toDate();
    let endDate = job.endDate.toDate();
    let dateArray = [];

    while (startDate <= endDate) {
      dateArray.push(startDate);
      startDate = moment(startDate).add(1, "d").toDate();
    }
    return dateArray;
  };

  deleteCrewmember = async () => {
    this.setState({ deleteCrewModalActive: false });
    this.setState({ removing: true });
    const id = this.state.crewmember.id;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("crew_schedules")
      .doc(this.state.appointment.id)
      .delete();

    this.setState({ removing: false });
    this.props.history.goBack();
  };

  reserveCrewMember = async () => {
    this.setState({ reserving: true });
    const jobId = this.props.match.params.id;
    const crewId = this.props.match.params.crewmemberId;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const obj = {
      crewMemberID: crewId,
      jobID: jobId,
      startDate: this.state.startDate.toDate(),
      endDate: this.state.endDate.toDate(),
    };

    await addCrewSchedule(inventoryId, obj)

    // await db
    //   .collection("inventories")
    //   .doc(inventoryId)
    //   .collection("crew_schedules")
    //   .add(obj);

    this.loadData();
    this.setState({ reserving: false });
  };

  updateReservation = async () => {
    this.setState({ updating: true });
    const jobId = this.props.match.params.id;
    const crewId = this.props.match.params.crewId;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("crew_schedules")
      .doc(this.state.appointment.id)
      .update({
        startDate: this.state.startDate.toDate(),
        endDate: this.state.endDate.toDate(),
      });

    this.loadData();
    this.setState({ updating: false });
    showToast("success", "Reservation has been updated successfully.");
  };

  getReserveIconOrMessage = () => {
    if (this.state.canAdd) {
      return (
        <a
          onClick={this.reserveCrewMember}
          className={
            this.state.reserving
              ? "btn btn-success btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
              : "btn btn-success btn-sm font-weight-bold mr-4"
          }
        >
          <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
            <CheckIcon />
          </span>
          Reserve
        </a>
      );
    }
  };

  getCalendar = () => {
    if (this.state.canAdd) {
      return (
        <div className="form-group row my-2">
          <label className="col-4 col-form-label">
            Crewmember appointment dates:
          </label>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              id="daterangepicker_appointment_dates"
              readOnly
              placeholder="Select dates"
            />
          </div>
        </div>
      );
    }
  };

  render() {
    if (this.state.crewmember) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            {/*begin::Header*/}
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">
                  Crewmember details
                  <span className="d-block text-muted pt-2 font-size-sm">
                    {this.state.crewmember.firstName}{" "}
                    {this.state.crewmember.lastName}
                  </span>
                </h3>
              </div>
              <div className="card-toolbar">
                {this.canEdit && (
                  <Link
                    to={"/crewmembers/" + this.state.crewmember.id + "/edit"}
                    className="btn btn-success btn-sm font-weight-bold mr-4"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Edit />
                    </span>
                    Edit
                  </Link>
                )}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-4">
              {this.state.crewmember.image ? (
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label"></label>
                  <div className="col-8">
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                      style={{
                        backgroundImage: "url(assets/media/users/blank.png)",
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{
                          backgroundImage:
                            "url(" + this.state.crewmember.image + ")",
                          backgroundSize: "contain, cover",
                          backgroundPosition: "center",
                          width: "160px",
                          height: "160px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.crewmember.firstName ? (
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">First name:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {this.state.crewmember.firstName}
                    </span>
                  </div>
                </div>
              ) : null}
              {this.state.crewmember.lastName ? (
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Last name:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {this.state.crewmember.lastName}
                    </span>
                  </div>
                </div>
              ) : null}
              {this.state.crewmember.email ? (
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Email:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext">
                      <span className="font-weight-bolder">
                        {this.state.crewmember.email}
                      </span>
                    </span>
                  </div>
                </div>
              ) : null}
              {this.state.crewmember.phoneNumber ? (
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Phone number:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {this.state.crewmember.phoneNumber}
                    </span>
                  </div>
                </div>
              ) : null}
              {this.state.crewmember.certifications ? (
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Skills:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {this.state.crewmember.certifications}
                    </span>
                  </div>
                </div>
              ) : null}
              {this.getCalendar()}
              {this.state.reservedDates.length > 0  && (
                <span className="text-danger">
                  This crewmember is assigned to another job(s) in these periods: {this.state.reservedDates.join(", ")}
                </span>
              )}
            </div>
            <div className="card-footer">
              {this.canEdit && (
                <>
                  {this.state.appointment ? (
                    <>
                      <a
                        onClick={this.updateReservation}
                        className={
                          this.state.updating
                            ? "btn btn-success btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                            : "btn btn-success btn-sm font-weight-bold mr-4"
                        }
                      >
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          <UpdateIcon />
                        </span>
                        Update
                      </a>
                      <a
                        onClick={() => {
                          this.setState({ deleteCrewModalActive: true });
                        }}
                        className={
                          this.state.removing
                            ? "btn btn-danger btn-sm font-weight-bold spinner spinner-white spinner-right"
                            : "btn btn-danger btn-sm font-weight-bold"
                        }
                      >
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          <Trash />
                        </span>
                        Remove
                      </a>
                    </>
                  ) : (
                    this.getReserveIconOrMessage()
                  )}
                </>
              )}
            </div>
          </div>
          <ConfirmModal
            show={this.state.deleteCrewModalActive}
            title="Remove crewmember"
            body={getMessages("remove_crewmember")}
            yesButton={{
              title: "Yes",
              onClick: () => {
                this.deleteCrewmember();
              },
            }}
            noButton={{
              title: "Cancel",
              onClick: () => {
                this.setState({ deleteCrewModalActive: false });
              },
            }}
          />
        </React.Fragment>
      );
    } else {
      return <Loader />;
    }
  }
}

export default JobCrewDetails;