import React, { Component } from "react";
import app, { db } from "../../../firebase";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Layout/Loader/Loader";

import { AuthContext } from "../../../shared/Auth";
import { getMessages } from "../../../components/Messages/GetMessages";

class AcceptInvite extends Component {
  static contextType = AuthContext;

  componentDidMount = async () => {
    const url = new URL(decodeURI(document.URL));
    const decoded = decodeURI(url);

    const splits = decoded.split("&");
    const split = splits.find((x) => x.includes("invitedUserEmail"));

    const crewmemberId = new URLSearchParams(this.props.location.search).get(
      "invitedUserID"
    );
    const companyId = new URLSearchParams(this.props.location.search).get(
      "companyID"
    );
    const inviteCode = new URLSearchParams(this.props.location.search).get(
      "inviteCode"
    );

    let invitation = await db
      .collection("invitations")
      .where("inviteCode", "==", parseInt(inviteCode))
      .get();

    const data = invitation.docs.map((x) => {
      return { ...x.data(), id: x.id };
    });
    let userInvite = null;

    if (data.length > 0) {
      userInvite = data[0];
    }

    let email = ""; //new URLSearchParams(props.location.search).get('invitedUserEmail');

    if (!userInvite) {
      this.setState({ inviteCodeValid: false });
      return;
    }

    if (split) {
      const splitAgain = split.split("=");
      email = splitAgain[1];
    }

    if (crewmemberId && companyId && email) {
      localStorage.setItem(
        "crewmemberInviteState",
        JSON.stringify({
          email: email,
          crewmemberId: crewmemberId,
          companyId: companyId,
          inviteCode: inviteCode,
        })
      );

      localStorage.setItem(
        "crewmemberInviteStateEmail",
        encodeURIComponent(email)
      );

      try {
        app.auth.signOut();
      } catch (error) {}

      this.props.history.push("/signup?state=acceptinvite");

      // app.auth().signInWithEmailLink(email, window.location.href)
      // .then((result) => {

      // })
      // .catch((error) => {
      //     props.history.push('/signin');
      // });
    } else {
    }
  };

  state = {
    inviteCodeValid: true,
  };

  render() {
    if (this.state.inviteCodeValid) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="d-flex flex-column flex-root">
            <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
              <div className="login-content d-flex flex-column pt-md-32 pt-12">
                <div className="pb-5 pb-lg-15">
                  <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                    Invalid or expired invitation link.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withRouter(AcceptInvite);
