import React, { Component } from 'react';

import QuickUser from "../../components/Layout/QuickUser/QuickUser";
import ScrollTop from "../../components/Layout/ScrollTop/ScrollTop";
import HeaderMobile from "../../components/Layout/HeaderMobile/HeaderMobile";
import Sidebar from "../../components/Layout/Sidebar/Sidebar";
import Header from "../../components/Layout/Header/Header";
import Subheader from "../../components/Layout/Subheader/Subheader";
import { updateUser } from '../../shared/Api';
import { AuthContext } from '../../shared/Auth';

class PrivateLayout extends Component {
    static contextType = AuthContext;

    componentDidMount() {
        if(window.KTApp) window.KTApp.init();
        if(window.KTLayoutQuickUser) window.KTLayoutQuickUser.init('kt_quick_user');
        if(window.KTLayoutHeader) window.KTLayoutHeader.init('kt_header', 'kt_header_mobile');
        if(window.KTLayoutHeaderMenu) window.KTLayoutHeaderMenu.init('kt_header_menu', 'kt_header_menu_wrapper');
        if(window.KTLayoutHeaderTopbar) window.KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
        if(window.KTLayoutBrand) window.KTLayoutBrand.init('kt_brand');
        if(window.KTLayoutAside) window.KTLayoutAside.init('kt_aside');
        if(window.KTLayoutAsideToggle) window.KTLayoutAsideToggle.init('kt_aside_toggle');
        if(window.KTLayoutAsideMenu) window.KTLayoutAsideMenu.init('kt_aside_menu');
        if(window.KTLayoutSubheader) window.KTLayoutSubheader.init('kt_subheader');
        if(window.KTLayoutContent) window.KTLayoutContent.init('kt_content');
        if(window.KTLayoutFooter) window.KTLayoutFooter.init('kt_footer');
        if(window.KTLayoutStretchedCard) window.KTLayoutStretchedCard.init('kt_page_stretched_card');
    }

    skipTutorial = () => {
        const currentUser = this.context.currentUser;
        updateUser(currentUser.uid, { webTutorialPending: false });
        
        const setIsTutorial = this.context.setIsTutorial;
        const setCurrentUser = this.context.setCurrentUser;
        
        setIsTutorial(false);

        const userProfile = {...currentUser.userProfile, webTutorialPending: false};
        setCurrentUser({...currentUser, userProfile: userProfile});

        document.getElementById("skip-tutorial-button").style.display = "none";
        document.getElementById("black-layer-container").style.display = "none";
        window.$('[data-toggle="tooltip"]').tooltip('hide');
    }

    render() {       
        return (
            <React.Fragment>
            <div id="black-layer-container" style={{ position: 'fixed', 
                width: '100%', 
                height: '100%', 
                backgroundColor: 'black',
                opacity: '0.4',
                display: 'none',
                zIndex: '1000' }}></div>
            <QuickUser />
            <ScrollTop />
            <HeaderMobile />
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                    <Sidebar />
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <Subheader />
                            <div className="d-flex flex-column-fluid">
                                <div className="container">
                                    <a className="btn btn-light-primary font-weight-bolder" id="skip-tutorial-button"
                                    onClick={this.skipTutorial}
                                    style={{ position: 'fixed', top: '12px', left: '50%', transform: 'translateX(-50%)',
                                    zIndex: '9999999', display: 'none' }}>Skip tutorial</a>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default PrivateLayout;