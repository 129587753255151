import React, { Component } from 'react';
import { Redirect } from 'react-router';
import BasicForm from '../../components/Form/BasicForm';
import loader from '../../components/Layout/Loader/Loader';
import { getMessages } from '../../components/Messages/GetMessages';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { deleteNotification, getNotification, updateNotification } from '../../shared/Api';
import { AuthContext } from '../../shared/Auth';
import { fieldsToObject, objectToFields, touchField, updateFormOnChange } from '../../shared/FormHelper';

class NotificationsEdit extends Component {
    
    static contextType = AuthContext;
    loadNotifications = this.context.loadNotifications;
    state = {
        notification: null,
        deleteNotificationSelected: false,
        deleting: false,
        editNotificationForm: {
            name: 'edit-notification',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            saving: false,
            fields: [
                {
                    name: 'title',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Notification Title',
                    placeholder: 'Title',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('notification_title_required')
                        }
                    ]
                },
                {
                    name: 'description',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    label: 'Notification Message',
                    placeholder: 'Message',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('notification_msg_required')
                        }
                    ]
                },
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.editNotification(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Delete',
                    className: 'btn btn-light-danger font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.setState({deleteNotificationSelected: true}),
                    disabled: () => false
                }
            ]
        }
    }

    componentDidMount = async () => {
        this.loadNotification();
    }

    loadNotification = async () => {
        const id = this.props.match.params.id;
        const notificationResult = await getNotification(id);
        const form = { ...this.state.editNotificationForm };

        const fields = objectToFields(form.fields, notificationResult.data);
        this.setState({notification: notificationResult.data, editNotificationForm: form});

    }

    editNotification = async() =>{
        const currentUser = this.context.currentUser;
        const form = { ...this.state.editNotificationForm };
        form.saving = true;
        this.setState({ editNotificationForm: form });
        const id = this.props.match.params.id;

        const notificationObj = fieldsToObject(this.state.editNotificationForm.fields);
        const result = await updateNotification(id, notificationObj);

        form.saving= false;
        this.setState({ editNotificationForm: form });
        await this.loadNotifications(currentUser.uid);
        this.props.history.push('/notifications/');
    }

    delete = async () => {
        this.setState({deleting: true});
        const id = this.props.match.params.id;
        const currentUser = this.context.currentUser;
        await deleteNotification(id);
        await this.loadNotifications(currentUser.uid);
        this.setState({deleteNotificationSelected: false, deleting: false});
        this.props.history.push('/notifications/');
    }

    prepareEditNotificationForm = () => {
        let form = this.state.editNotificationForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.editNotificationForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.editNotificationForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    render() {
        if((this.context.currentUser.uid !== "zfnO1CRDeWetLPsqUB8a6lR8Wu32") && (this.context.currentUser.uid !== "CfaHqGxDiqhn0u0iI8HZM2q8cXC3"))
        return <Redirect to = "/equipment" />
        
        if(!this.state.notification)
            return loader
        const editNotificationForm = this.prepareEditNotificationForm();
        return(
            <>
            <div className="card">
                    <div className="card-body">
                        <h3 className="mb-10 font-weight-bold text-dark">Edit Notification</h3>
                        <div className="mb-20">
                            <BasicForm {...editNotificationForm} />
                        </div>
                    </div>
                </div>
                <ConfirmModal 
                show={this.state.deleteNotificationSelected}
                title="Delete Notification"
                body="Are you sure you want to delete this notification?"
                yesButton={{
                    title: "Yes",
                    spinner: this.state.deleting,
                    onClick: () => {this.delete();}
                }}
                noButton={{
                    title: "Cancel",
                    onClick: () => { this.setState({ deleteNotificationSelected: false }) }
                }}
                />
            </>
        )
    }
}

export default NotificationsEdit;