import React, { Component } from "react";
import moment from "moment";
import { saveAs } from "file-saver";

import JobDetailsContainer from "./JobDetailsInfo/JobDetailsContainer";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import Geosuggest from "react-geosuggest";
import Loader from "../../components/Layout/Loader/Loader";
import { ArrowLeft, Camera, Edit, Trash } from "../../components/Icons/Svg";
import { showToast } from "../../shared/Util";

import { functions, storage } from "../../firebase";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import { getMessages } from "../../components/Messages/GetMessages";

import { AuthContext } from "../../shared/Auth";
import Viewer from "react-viewer";
import UploadJobPhotoModal from "../../components/Modals/UploadJobPhotoModal";
import {
  addJobFolder,
  addJobPhoto,
  getJobPhoto,
  removeJobPhoto,
  removePhotoFolder,
  updateFile,
  updatePhoto,
  updatePhotoFolder,
  uploadFile,
} from "../../shared/Api";
import AddFolderModal from "../../components/Modals/AddFolderModal";

import Compressor from "compressorjs";
import { getAllCrewMembers } from "../../shared/api/CrewApi";
import JobClientsComponent from "./JobDetailsInfo/Clients/JobClientsComponent";
import JobKickoffReport from "../../components/Reports/JobKickoffReport";
import JobStatusReport from "../../components/Reports/JobStatusReport";
import { Link } from "react-router-dom";
import MoveFileModal from "../../components/Modals/MoveFileModal";

class JobDetailsInfo extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  canEdit = this.user.permissions !== "crewmember";
  isTutorial = this.context.isTutorial;
  tutorialStep = this.context.tutorialStep;

  state = {
    newFile: { categoryID: null, name: "", file: null },
    photoAdding: false,
    uploadPhotoModalShow: false,
    showingInfoWindow: false,
    showMoveFileModal: false,
    activeMarker: null,
    selectedPlace: null,
    editGoogleMap: false,
    locationLat: this.props.job.locationLat,
    locationLng: this.props.job.locationLong,
    locationName: this.props.job.locationName,
    dailyProductionReportSending: false,
    creatingZip: false,
    folderModalShow: false,
    folderName: "",
    folderAdding: false,
    editFolder: false,
    deleteFolderModalShow: false,
    deletingFolder: false,
    showDailyProduction: false,
    crewMembers: [],
    folderStructure: [],
  };

  componentDidMount() {
    this.props.setTabActive("details");
    this.props.checkIfZipExists();

    const self = this;
    this.fetchCrewMembers();

    document.addEventListener(
      "click",
      (event) => {
        if (
          event.target &&
          event.target.className &&
          (typeof event.target.className === "string" ||
            event.target.className instanceof String)
        ) {
          if (
            event.target.className.includes("react-viewer-icon-download") ||
            (event.target.className.includes("react-viewer-btn") &&
              event.target.getAttribute("data-key") === "download")
          ) {
            let images = self.currentImages();
            if (images) {
              images = images.filter((x) => x.type === "photo");
              const image = images[self.props.selectedImageIndex];
              self.downloadFile(image.fileLink, image.name);
            }
          }
        }
      },
      true
    );

    if (this.isTutorial && this.tutorialStep === "jobReport") {
      window.$('[id="kickoff-report-btn"]').tooltip({ trigger: "manual" });
      window.$('[id="kickoff-report-btn"]').tooltip("hide");

      window.$('[id="kickoff-report-btn"]').tooltip("show");
    }

    const folderStructure = this.buildFolderStructure(
      this.currentImages(),
      null
    );
    this.state.folderStructure = {
      id: "-1",
      name: "",
      children: folderStructure,
    };
  }

  componentWillUnmount() {
    if (this.props.isTutorial) {
      window.$('[id="kickoff-report-bt"]').tooltip("hide");
    }
  }

  capitalize = (str) => {
    if (!str) return str;

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  getDates = () => {
    return (
      moment(this.props.job.startDate.toDate()).format("MM/DD/YY") +
      " - " +
      moment(this.props.job.endDate.toDate()).format("MM/DD/YY")
    );
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  onSuggestSelect = (suggest) => {
    if (suggest && suggest.location) {
      this.setState({
        locationName: suggest.description,
        locationLat: suggest.location.lat,
        locationLng: suggest.location.lng,
      });
    }
  };

  locationSave = async () => {
    await this.props.saveLocation(
      this.state.locationLat,
      this.state.locationLng,
      this.state.locationName
    );
    this.setState({ editGoogleMap: false });
  };

  sendDailyProductionCheckIn = async (data) => {
    const dailyCheckInEntity = {
      ...data,
      jobID: this.props.job.id,
      userID: this.props.user.uid,
      saveFile: data.saveFile,
    };
    this.setState({ dailyProductionReportSending: true });
    let sendJobReportFunc = functions.httpsCallable("sendJobCheckInReport");
    let result = await sendJobReportFunc(dailyCheckInEntity);

    this.setState({ dailyProductionReportSending: false });
    showToast("success", "Report has been sent successfully.");
  };

  fetchCrewMembers = async () => {
    const inventoryId = this.context.currentUser.company.inventoryID;

    const crewMembers = await getAllCrewMembers(inventoryId);
    const filteredCrewMembers = crewMembers.data.filter(
      (crewMember) => crewMember.email !== this.user.email
    );
    this.setState({ crewMembers: filteredCrewMembers });
  };

  downloadURI(uri, name) {
    saveAs(uri, name);
  }

  handleFolderNameChange = (event) => {
    this.setState({ folderName: event.target.value });
  };

  downloadZip = async () => {
    this.setState({ creatingZip: true });

    const storageRef = storage.ref(
      this.props.user.userProfile.companyID + "/jobpackages/"
    );
    const url = await storageRef
      .child(this.props.job.id + "-v1")
      .getDownloadURL();

    this.downloadFile(url, "jobdata-" + this.props.job.id + ".zip");
    this.setState({ creatingZip: false });
  };

  reportBtnClass = (className, flag) => {
    if (flag) className += " spinner spinner-white spinner-right";

    return className;
  };

  getSelectedImage = () => {
    const images = this.props.selectedImageFolder
      ? this.props.selectedImageFolder.images
      : this.props.jobPhotos;

    return images[this.props.selectedImageIndex].fileLink;
  };

  openUploadPhotoModal = () => {
    this.setState({
      uploadPhotoModalShow: true,
      newFile: {
        name: "",
        file: null,
        categoryID: this.props.selectedImageFolder
          ? this.props.selectedImageFolder.id
          : null,
      },
    });
  };

  openAddFolderModal = () => {
    this.setState({ folderModalShow: true, folderName: "", editFolder: false });
  };

  openRenameFolderModal = () => {
    this.setState({
      folderModalShow: true,
      folderName: this.props.selectedImageFolder.name,
      editFolder: true,
    });
  };

  openDeleteFolderModal = () => {
    this.setState({ deleteFolderModalShow: true });
  };

  onImageDrop = (acceptedFiles) => {
    let image = acceptedFiles[0];
    const newFile = { ...this.state.newFile };
    newFile.file = image;

    this.setState({ newFile: newFile });
  };

  onImageRemove = () => {
    const newFile = { ...this.state.newFile };
    newFile.file = null;

    this.setState({ newFile: newFile });
  };

  handleFileNameChange = (event) => {
    const newFile = { ...this.state.newFile };
    newFile.name = event.target.value;

    this.setState({ newFile: newFile });
  };

  addPhoto = async () => {
    this.setState({ photoAdding: true });
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;

    const photo = {
      name: this.state.newFile.name,
      categoryID: this.state.newFile.categoryID,
      createdAt: new Date(),
    };

    let photoAdded = await addJobPhoto(inventoryId, id, photo);
    photoAdded = await getJobPhoto(inventoryId, id, photoAdded.data);

    const self = this;

    new Compressor(this.state.newFile.file, {
      drew(context, canvas) {
        context.fillStyle = "rgba(0, 0, 0, 0.6)";
        context.fillRect(
          0,
          canvas.height - 72,
          canvas.width,
          canvas.height - 6
        );

        context.fillStyle = "#FFF";
        context.font = "1rem serif";
        if (self.state.newFile.name) {
          context.fillText(
            self.props.job.title + " - " + self.state.newFile.name,
            20,
            canvas.height - 50
          );
        } else {
          context.fillText(self.props.job.title, 20, canvas.height - 50);
        }

        context.fillText(
          moment().format("MM/DD/YY, hh:mma"),
          20,
          canvas.height - 32
        );
        if (self.props.job.locationLat && self.props.job.locationLong) {
          context.fillText(
            "Location(x: " +
              self.props.job.locationLat +
              ", y: " +
              self.props.job.locationLong +
              ")",
            20,
            canvas.height - 14
          );
        }
      },
      width: 400,
      success: async (result) => {
        await uploadFile(
          companyId,
          "job",
          this.props.job.id,
          photoAdded.data.createdAt,
          "med",
          result
        );

        await self.props.loadJobPhotos(id, inventoryId, companyId);
        self.setState({
          uploadPhotoModalShow: false,
          photoAdding: false,
          newFile: { categoryID: null, name: "", file: null },
        });
      },
    });
  };

  addFolder = async () => {
    this.setState({ folderAdding: true });
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;

    if (this.state.editFolder) {
      await updatePhotoFolder(
        inventoryId,
        id,
        this.props.selectedImageFolder.id,
        this.state.folderName
      );
    } else {
      await addJobFolder(inventoryId, id, { name: this.state.folderName });
    }

    await this.props.loadJobPhotos(id, inventoryId, companyId);
    this.setState({
      folderName: "",
      folderModalShow: false,
      folderAdding: false,
    });
  };

  deleteFolder = async () => {
    this.setState({ deletingFolder: true });
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = this.context.currentUser.userProfile.companyID;

    await removePhotoFolder(inventoryId, id, this.props.selectedImageFolder.id);
    await this.props.loadJobPhotos(id, inventoryId, companyId);
    this.props.toRootFolder();
    this.setState({ deletingFolder: false, deleteFolderModalShow: false });
  };

  currentImages = () => {
    let images = [];

    if (!this.props.selectedImageFolder) {
      images = this.props.jobPhotos;
    } else {
      const selected = this.getSelectedFolder();
      if (!selected) {
        images = this.props.jobPhotos;
      } else {
        images = selected.images;
      }
    }

    if (images) {
      for (let i = 0; i < images.length; i++) {
        images[i].index = i;
      }
    }

    return images;
  };

  getSelectedFolder = () => {
    if (!this.props.images) return null;

    if (!this.props.selectedImageFolder) return null;

    return this.props.selectedImageFolder;
  };

  getViewerImages = () => {
    if (!this.props.selectedImageFolder) return this.props.viewerImages;

    const selected = this.getSelectedFolder();
    if (!selected) return this.props.viewerImages;

    return this.getSelectedFolder()?.viewerImages ?? [];
  };

  removePhoto = async (photoId) => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;

    await removeJobPhoto(inventoryId, id, photoId);
    this.props.loadJobPhotos(id, inventoryId, companyId);
    this.props.removePhotoFromState(photoId);
  };

  downloadFile = async (url, fileName) => {
    const lastIndex = fileName.lastIndexOf(".");

    const extension = lastIndex !== -1 ? fileName.substring(lastIndex + 1) : "";

    const finalFileName = /\.\w+$/.test(fileName)
      ? fileName
      : fileName
      ? fileName + extension
      : "file_name" + extension;

    fetch(url)
      .then((response) => response.blob())
      .then((imageBlob) => {
        saveAs(imageBlob, finalFileName);
      });
  };

  getBase64Image = (img) => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    img.crossOrigin = "anonymous";
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  };

  buildFolderStructure = (rawData, parentId) => {
    return rawData?.map((rawEntity) => {
      if (rawEntity.images) {
        return {
          id: rawEntity.id,
          name: rawEntity.name,
          readOnly: false,
          parentId: parentId,
          createdBy: null,
          createdAt: null,
          children: this.buildFolderStructure(rawEntity.images, rawEntity.id),
        };
      } else {
        const fileType = rawEntity.type == "photo" ? "photo" : "document";
        return {
          id: rawEntity.id,
          name: rawEntity.name,
          readOnly: false,
          parentId: parentId,
          createdBy: null,
          createdAt: rawEntity.createdAt,
          url: rawEntity.fileLink,
          fileType: fileType,
        };
      }
    });
  };

  getFolders = () => {
    if (!this.state.selectedModalFolder) {
      const folders =  
        this.props.jobPhotos?.filter(
          (photo) => !photo?.isBore && photo?.type == "folder"
        )?.map((folder)=>({...folder, categoryID: this.state.selectedModalFolder?.id})) ?? []
      ;
      return folders
    }

    const folder = this.props.jobPhotos?.find(
      (photo) =>
        !photo?.isBore &&
        photo?.type == "folder" &&
        photo?.id == this.state.selectedModalFolder.id
    );
    if (!folder) return [];

    return folder?.images.filter((image) => image.type == "folder")?.map((folder)=>({...folder, categoryID: folder.categoryID})) ?? [];
  };

  getFolderPath = () => {
    const folder = this.props.selectedImageFolder;

    if (!folder) return "/";

    if (!folder.id) return "/" + folder.name;

    return "/";
  };

  setSelectedFolder = (folder) => {
    this.setState({
      selectedModalFolder: folder,
    });
  };

  handleModalBack = () => {
    if (
      this.state.selectedModalFolder &&
      this.state.selectedModalFolder.id
    ) {
      const folder = this.props.jobPhotos.find(
        (x) => x.id === this.state.selectedModalFolder.categoryID
      );
      if (folder) {
        this.setState({ selectedModalFolder: folder });
        return;
      }
    }

    this.setState({ selectedModalFolder: null });
  };

  moveFileToFolder = async () => {
    this.setState({ movingFile: true });


    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;

    const photo = {}

    await updatePhoto(inventoryId, this.props.job.id, this.state.moveFile.id, 
      this.state.selectedModalFolder
        ? this.state.selectedModalFolder.id
        : null,
    );

    await this.props.loadJobPhotos(id, inventoryId, companyId);

    this.setState({
      movingFile: false,
      moveFile: null,
      showMoveFileModal: false,
      selectedModalFolder: null,
    });
  };

  render() {
    const imgs = this.currentImages();
    const selectedFolder = this.getSelectedFolder();

    return (
      <React.Fragment>
        <div style={{ position: "relative" }}>
          <div className="row">
            <div className="col-xl-12">
              {this.canEdit && (
                <div className="form-group float-right ml-2">
                  <a
                    onClick={this.props.enableConfirmModal}
                    className="btn btn-danger btn-sm font-weight-bold"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Trash />
                    </span>
                    Delete Job
                  </a>
                </div>
              )}
              {this.props.job.status === "completed" &&
                this.props.zipChecked && (
                  <div className="form-group float-right ml-2">
                    {this.props.zipExists ? (
                      <button
                        onClick={this.downloadZip}
                        className={this.reportBtnClass(
                          "btn btn-primary font-weight-bold mr-4 w-100",
                          this.state.creatingZip
                        )}
                      >
                        Download data
                      </button>
                    ) : (
                      <button
                        disabled={this.props.preparingZipData}
                        onClick={this.props.createZip}
                        className="btn btn-primary font-weight-bold mr-4 w-100"
                      >
                        {this.props.preparingZipData ? (
                          <div>Preparing data...</div>
                        ) : (
                          <div>Prepare data</div>
                        )}
                      </button>
                    )}
                  </div>
                )}
              <div className="float-right ml-2">
                <JobStatusReport
                  jobId={this.props.job.id}
                  crewMembers={this.state.crewMembers}
                  startDate={this.props.job.startDate}
                  endDate={this.props.job.endDate}
                />
              </div>

              <Link
                className="btn btn-primary font-weight-bolder ml-2 float-right"
                to={{
                  pathname: `/jobs/${this.props.job.id}/report/dailyproduction`,
                }}
                href="#"
              >
                Daily Production Check-In
              </Link>
              <JobKickoffReport
                jobId={this.props.job.id}
                crewMembers={this.state.crewMembers}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <JobDetailsContainer
                job={this.props.job}
                form={this.props.jobDetailsForm}
                touchField={this.props.touchJobDetailsField}
                handleSubmit={this.props.submitJobDetails}
                handleChange={this.props.handleJobDetailsChange}
                resetForm={this.props.resetJobDetailsForm}
                canEdit={this.props.canEdit}
              />
            </div>

            <div className="col-xl-6">
              <JobClientsComponent
                job={this.props.job}
                clients={this.props.clients}
                refreshData={this.props.refreshData}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="card card-custom gutter-b card-stretch">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column mr-auto">
                      <a
                        href="#"
                        className="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1"
                      >
                        Job Location
                      </a>
                      <span className="text-dark">
                        {this.state.locationName}
                      </span>
                      <span className="text-dark text-muted">
                        Latitude:{" "}
                        {parseFloat(this.state.locationLat).toFixed(3)}
                      </span>
                      <span className="text-dark text-muted">
                        Longitude:{" "}
                        {parseFloat(this.state.locationLng).toFixed(3)}
                      </span>
                    </div>
                    {this.props.canEdit && (
                      <>
                        {!this.state.editGoogleMap ? (
                          <div className="card-toolbar mb-auto">
                            <div
                              className="dropdown dropdown-inline"
                              data-toggle="tooltip"
                              title="Quick actions"
                              data-placement="left"
                            >
                              <a
                                onClick={() => {
                                  this.setState({ editGoogleMap: true });
                                }}
                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="svg-icon svg-icon-primary svg-icon-2x">
                                  <Edit />
                                </span>
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <button
                              onClick={this.locationSave}
                              className="btn btn-primary font-weight-bold"
                            >
                              Save
                            </button>
                            <button
                              onClick={() => {
                                this.setState({ editGoogleMap: false });
                              }}
                              className="btn btn-secondary font-weight-bold ml-2"
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div
                    className="mt-10"
                    style={{
                      height: "300px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    {this.state.editGoogleMap ? (
                      <>
                        <Geosuggest
                          className="mb-2"
                          autoActivateFirstSuggest={true}
                          inputClassName="form-control"
                          suggestsClassName="sug-container"
                          suggestItemClassName="dropdown-item"
                          suggestItemActiveClassName="sug-item-active"
                          onSuggestSelect={this.onSuggestSelect}
                          autoComplete="off"
                          initialValue={this.state.locationName}
                        />
                        <GoogleMap
                          lat={this.state.locationLat}
                          lng={this.state.locationLng}
                          onClick={(coords) => {
                            this.setState({
                              locationLat: coords.lat,
                              locationLng: coords.lng,
                            });
                          }}
                        />
                      </>
                    ) : (
                      <GoogleMap
                        lat={this.props.job.locationLat}
                        lng={this.props.job.locationLong}
                        onClick={(coords) => {}}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card card-custom gutter-b card-stretch">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    {(!selectedFolder || !selectedFolder.isBore) && (
                      <>
                        <div className="d-flex flex-column mr-auto">
                          <a
                            href="#"
                            className="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1"
                          >
                            Job Photos
                          </a>
                        </div>
                        <div className="card-toolbar mb-auto">
                          <>
                            <a
                              href="#"
                              className="btn btn-hover-light-primary btn-sm btn-icon"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="ki ki-bold-more-hor" />
                            </a>
                            <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                              <ul className="navi navi-hover">
                                {!selectedFolder && (
                                  <li className="navi-item">
                                    <a
                                      role="button"
                                      onClick={this.openAddFolderModal}
                                      className="navi-link"
                                    >
                                      <span className="navi-text">
                                        Add Folder
                                      </span>
                                    </a>
                                  </li>
                                )}
                                {selectedFolder && (
                                  <li className="navi-item">
                                    <a
                                      role="button"
                                      onClick={this.openRenameFolderModal}
                                      className="navi-link"
                                    >
                                      <span className="navi-text">
                                        Rename Folder
                                      </span>
                                    </a>
                                  </li>
                                )}
                                {selectedFolder && (
                                  <li className="navi-item">
                                    <a
                                      role="button"
                                      onClick={this.openDeleteFolderModal}
                                      className="navi-link"
                                    >
                                      <span className="navi-text">
                                        Delete Folder
                                      </span>
                                    </a>
                                  </li>
                                )}
                                <li className="navi-item">
                                  <a
                                    role="button"
                                    onClick={this.openUploadPhotoModal}
                                    className="navi-link"
                                  >
                                    <span className="navi-text">
                                      Upload Photo
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </>
                        </div>
                      </>
                    )}
                  </div>

                  {selectedFolder && (
                    <div className="mb-4">
                      <span
                        onClick={this.props.toRootFolder}
                        style={{ cursor: "pointer" }}
                      >
                        <ArrowLeft />
                      </span>{" "}
                      {selectedFolder.name}
                    </div>
                  )}

                  {imgs ? (
                    imgs.length > 0 ? (
                      <>
                        <div className="mt-10">
                          <div className="mt-5" id={"img-container"}>
                            <div className="row">
                              {imgs.map((image, index) => {
                                return image.type === "photo" ||
                                  image.type === "document" ? (
                                  <div className="col-md-4">
                                    <div
                                      style={{
                                        marginBottom: "22px",
                                        marginRight: "13px",
                                        position: "relative",
                                        cursor: "pointer",
                                        width: "100%",
                                      }}
                                      className="symbol symbol-100"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      onClick={(event) => {
                                        if (
                                          event.target.className
                                            .split(" ")
                                            .includes("job-img-options") ||
                                          event.target.className
                                            .split(" ")
                                            .includes("navi-link") ||
                                          event.target.className
                                            .split(" ")
                                            .includes("navi-item") ||
                                          event.target.className
                                            .split(" ")
                                            .includes("navi-text")
                                        )
                                          return;

                                        if (
                                          this.props.setSelectedImage &&
                                          image.type == "photo"
                                        ) {
                                          this.props.setSelectedImage(0);
                                        } else {
                                          this.downloadFile(
                                            image.fileLink,
                                            image.name
                                          );
                                        }
                                      }}
                                    >
                                      {image.type == "photo" ? (
                                        <img
                                          id={"jimage-" + index}
                                          alt="Pic"
                                          src={image.fileLink}
                                        />
                                      ) : (
                                        <img
                                          alt="Pic"
                                          src={
                                            "/assets/media/equappment/ic_document.png"
                                          }
                                        />
                                      )}

                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "-6px",
                                          right: "4px",
                                        }}
                                      >
                                        <a
                                          role="button"
                                          className="btn btn-sm btn-icon job-img-options"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="ki ki-bold-more-hor job-img-options" />
                                        </a>
                                        <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                          <ul className="navi navi-hover">
                                            {this.props.removePhoto &&
                                              (!this.props
                                                .selectedImageFolder ||
                                                !this.props.selectedImageFolder
                                                  .isBore) &&
                                              this.canEdit && (
                                                <div>
                                                  <li className="navi-item">
                                                    <a
                                                      role="button"
                                                      onClick={(event) => {
                                                        if (
                                                          image.photoType ===
                                                          "old"
                                                        ) {
                                                          this.props.removePhoto(
                                                            image.oldPhotoIndex
                                                          );
                                                        } else {
                                                          this.removePhoto(
                                                            image.id
                                                          );
                                                        }
                                                      }}
                                                      className="navi-link"
                                                    >
                                                      <span className="navi-text">
                                                        Delete
                                                      </span>
                                                    </a>
                                                  </li>
                                                  <li className="navi-item">
                                                    <a
                                                      role="button"
                                                      onClick={() => {
                                                        this.setState({
                                                          showMoveFileModal: true,
                                                          moveFile: image,
                                                        });
                                                      }}
                                                      className="navi-link"
                                                    >
                                                      <span className="navi-text">
                                                        Move
                                                      </span>
                                                    </a>
                                                  </li>
                                                </div>
                                              )}

                                            <li className="navi-item">
                                              <a
                                                role="button"
                                                onClick={() =>
                                                  this.downloadFile(
                                                    image.fileLink,
                                                    image.name
                                                  )
                                                }
                                                className="navi-link"
                                              >
                                                <span className="navi-text">
                                                  Download
                                                </span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      {/* {this.props.removePhoto && (!this.props.selectedImageFolder || !this.props.selectedImageFolder.isBore) && <span style={{ position: 'absolute', top: '-2px', right: '-6px' }} onClick={(event)=>{  event.stopPropagation(); if(image.photoType === "old")  { this.props.removePhoto(image.oldPhotoIndex); } else { this.removePhoto(image.id); } }} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove image">
                                                                <i className="ki ki-bold-close icon-xs text-muted" />
                                                            </span>  } */}
                                      {image.photoType !== "old" ? (
                                        <>
                                          <p
                                            style={{ margin: "0" }}
                                            title={image.name}
                                            className="text-truncate"
                                          >
                                            {image.name}
                                          </p>
                                          <p className="text-muted">
                                            {moment(
                                              image.createdAt.toDate()
                                            ).format("MM/DD/YY, hh:mma")}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-muted">
                                            {moment(
                                              image.createdAt.toDate()
                                            ).format("MM/DD/YY, hh:mma")}
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="col-4 text-truncate mb-6"
                                    style={{ cursor: "pointer" }}
                                    key={"crsl-cmpnt" + index}
                                    onClick={() => this.props.openFolder(image)}
                                  >
                                    <i
                                      className="flaticon2-folder text-warning"
                                      style={{ fontSize: "84px" }}
                                    ></i>
                                    <span
                                      style={{
                                        display: "block",
                                        marginTop: "-14px",
                                      }}
                                    >
                                      {image.name}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <Viewer
                          visible={this.props.imageViewerActive}
                          downloadable={true}
                          downloadInNewWindow={true}
                          onClose={() => {
                            this.props.disableImageViewer();
                          }}
                          images={this.getViewerImages()}
                          // activeIndex={this.props.selectedImageIndex}
                          onChange={(image, index) => {
                            this.props.setSelectedImage(index);
                          }}
                        />
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                          <div className="mainpage-graphic">
                            <Camera
                              width={"200px"}
                              height={"200px"}
                              opacity={"0.3"}
                            />
                          </div>
                          <span className="font-italic mb-7">
                            You haven't added any photos for this job.
                          </span>
                          <a
                            role="button"
                            onClick={this.openUploadPhotoModal}
                            className="navi-link"
                          >
                            Click here
                          </a>
                          <span className="font-italic mb-7">
                            to start adding.
                          </span>
                        </div>
                      </div>
                    )
                  ) : (
                    <Loader height={"320px"} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            show={this.props.deleteJobModalActive}
            title="Delete job"
            body={getMessages("delete_job")}
            yesButton={{
              title: "Yes",
              onClick: () => {
                this.props.deleteJob();
              },
            }}
            noButton={{
              title: "Cancel",
              onClick: () => {
                this.props.disableConfirmModal();
              },
            }}
          />
          <MoveFileModal
            show={this.state.showMoveFileModal}
            toggle={() => { this.setState({ showMoveFileModal: false }); }}
            getFolders={this.getFolders}
            getFolderPath={this.getFolderPath}
            setSelectedFolder={this.setSelectedFolder}
            selectedFolder={this.state.selectedModalFolder}
            moveFile={this.state.moveFile}
            handleBack={this.handleModalBack}
            save={this.moveFileToFolder}
            moveFileToFolder={this.moveFileToFolder}
            saving={this.state.movingFile}
          />
          <UploadJobPhotoModal
            show={this.state.uploadPhotoModalShow}
            toggle={() => {
              this.setState({ uploadPhotoModalShow: false });
            }}
            name={this.state.newFile.name}
            file={this.state.newFile.file}
            handleNameChange={this.handleFileNameChange}
            save={this.addPhoto}
            saving={this.state.photoAdding}
            onImageDrop={this.onImageDrop}
            onRemove={this.onImageRemove}
          />
          <AddFolderModal
            show={this.state.folderModalShow}
            toggle={() => {
              this.setState({ folderModalShow: false });
            }}
            folderName={this.state.folderName}
            handleChange={this.handleFolderNameChange}
            save={this.addFolder}
            saving={this.state.folderAdding}
          />
          <ConfirmModal
            id={"delete_folder"}
            show={this.state.deleteFolderModalShow}
            title="Delete folder"
            body={getMessages("delete_folder")}
            yesButton={{
              title: "Yes",
              onClick: () => {
                this.deleteFolder();
              },
              spinner: this.state.deletingFolder,
            }}
            noButton={{
              title: "Cancel",
              onClick: () => {
                this.setState({ deleteFolderModalShow: false });
              },
            }}
          />
          {/* <DailyCheckInModal
            show={this.state.showDailyProduction}
            crewMembers={this.state.crewMembers}
            onCancel={() => {
              this.setState({ showDailyProduction: false });
            }}
            onConfirm={(result) => {
              this.sendDailyProductionCheckIn(result);
              this.setState({ showDailyProduction: false });
            }}
          /> */}
        </div>
      </React.Fragment>
    );
  }
}

export default JobDetailsInfo;
