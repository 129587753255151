import React, { Component } from 'react';

import { AuthContext } from "../../../shared/Auth";
import Loader from '../../../components/Layout/Loader/Loader';
import { getBoreLog } from "../../../shared/Api";
import { getBore } from "../../../shared/Api";
import * as moment from 'moment';

class BoreLogsDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  canEdit = this.user.permissions !== 'crewmember';

  state = {
    boreLog: null
  }

  componentDidMount = async () => {
    await this.loadBoreLog();
  }

  loadBoreLog = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const boreId = this.props.match.params.boreId;
    const boreLogId = this.props.match.params.boreLogId;

    const boreLogResult = await getBoreLog(inventoryId, boreId, boreLogId, currentUser.userProfile.companyID, true);
    const boreResult = await getBore(inventoryId, boreId);
    this.setState({ boreLog: boreLogResult.data, bore: boreResult.data });
  }

  render() {
    if (this.state.boreLog) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">Bore Log details</h3>
              </div>
              <div className="card-toolbar">
                {this.canEdit &&
                <a onClick={() => { this.props.history.push('/jobs/' + this.props.match.params.id + '/bores/' + this.props.match.params.boreId + '/borelogs/' + this.props.match.params.date + "/" + this.props.match.params.boreLogId + '/edit') }} className="btn btn-success btn-sm font-weight-bold mr-4">
                  <i className="flaticon-edit" />Edit</a>}
              </div>
            </div>
            <div className="card-body py-4">
            {this.state.boreLog.edited ? 
              <div className="form-group row my-2a">
                <label className="col-4 col-form-label">edited entry</label>
              </div>
               : ""}
            {this.state.boreLog.image ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label"></label>
                  <div className="col-8">
                    <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}>
                      <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + this.state.boreLog.image + ')', backgroundSize: 'contain, cover', backgroundPosition: 'center', width: '160px', height: '160px' }} />
                    </div>
                  </div>
                </div> : null}
            <div className="form-group row my-2">
                <label className="col-4 col-form-label">Entry No:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">#{('000' + this.state.boreLog.boreLogNumber).substr(-3)}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Entry Date:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{moment(this.state.boreLog.date.toDate()).format("M/DD/YY")}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Entry Time:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{moment(this.state.boreLog.date.toDate()).format("hh:mma")}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Created By:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.createdBy}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Distance Drilled:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.distance} ft</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Total Distance Drilled:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.bore.boreProgress} ft</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Soil conditions:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.soilConditions}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Bore Location:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.lat}, {this.state.boreLog.long}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Depth:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.depth} in</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Pitch:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.pitch} %</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Notes:</label>
                <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{this.state.boreLog.notes}</span>
                </div>
              </div>
            </div>
            <div className="card-footer">
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <Loader />
    }
  }
}

export default BoreLogsDetails;