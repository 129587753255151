import React, { Component } from 'react';
import SimpleForm from '../../components/Form/SimpleForm';
import { validateField } from '../../shared/Validation';
import Loader from '../../components/Layout/Loader/Loader';

import app, { db, storage } from '../../firebase';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';
import { normalizeInput } from '../../shared/FormHelper';
import { CrewMemberPosition } from '../../shared/data/Crew';

class CrewmemberEdit extends Component {
  static contextType = AuthContext;

  loadPositions = () => {
    const crewMemberPositions = Object.values(CrewMemberPosition)
      .map((crewMemberPosition) => {
        return { value: crewMemberPosition, display: crewMemberPosition };
      })
      .sort((a, b) => {
        return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
      });

    crewMemberPositions.unshift({ value: "None", display: "None" });
    crewMemberPositions.push({ value: "Other", display: "Other" })
    return crewMemberPositions;
  };

  state = {
    crewmember: null,
    fields: [
      {
        name: "firstName",
        value: "",
        defaultValue: "",
        type: "text",
        label: "First name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: null,
        required: true,
        validators: [
          {
            type: "required",
            message: getMessages("firstname_required"),
          },
        ],
      },
      {
        name: "lastName",
        value: "",
        defaultValue: "",
        type: "text",
        label: "Last name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: null,
        required: true,
        validators: [
          {
            type: "required",
            message: getMessages("lastname_required"),
          },
        ],
      },
      {
        name: "email",
        value: "",
        defaultValue: "",
        type: "text",
        label: "Email",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: null,
        required: false,
        // validators: [
        //   {
        //     type: "required",
        //     message: getMessages("email_required"),
        //   },
        //   {
        //     type: "email",
        //     message: getMessages("invalid_email"),
        //   },
        // ],
      },
      {
        name: "phoneNumber",
        value: "",
        defaultValue: "",
        type: "text",
        subtype: "phone",
        label: "Phone number",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: null,
        required: false,
      },
      {
        name: "positionSelection",
        value: "",
        defaultValue: "",
        placeholder: "Position",
        type: "select",
        label: "Position",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        required: false,
        options: this.loadPositions(),
      },
      {
        name: "certifications",
        value: "",
        defaultValue: "",
        type: "textarea",
        label: "Skills",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: null,
        required: false,
        rows: 4,
      },
      {
        name: "image",
        value: "",
        defaultValue: "",
        type: "dropzone",
        label: "Crewmember Photo",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: null,
        required: false,
        localSrc: "",
        onDrop: (acceptedFiles) => {
          this.onImageDrop(acceptedFiles);
        },
        onRemove: () => {
          this.onImageRemove();
        },
      },
    ],
    isValid: true,
    submitError: false,
    submitErrorMessage: "",
    loading: false,
    accountCreated: false,
  };

  componentDidMount() {
    this.loadCrewmember();
  }

  loadCrewmember = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    db.collection("inventories")
      .doc(inventoryId)
      .collection("crew")
      .doc(id)
      .get()
      .then(async (doc) => {
        let crewmemberData = doc.data();
        crewmemberData.id = doc.id;

        let fields = [...this.state.fields];
        await Promise.all(
          fields.map(async (x) => {
            if (x.name === "image") {
              if (
                crewmemberData.hasProfileImage &&
                crewmemberData.imageTimeStamp
              ) {
                try {
                  const storageRef = storage.ref(
                    "/" +
                      currentUser.userProfile.companyID +
                      "/crewMember/" +
                      doc.id +
                      "/" +
                      crewmemberData.imageTimeStamp.seconds +
                      "/"
                  );
                  const url = await storageRef.child("med").getDownloadURL();
                  x.localSrc = url;
                  x.value = url;
                  x.defaultValue = url;
                } catch (err) {}
              }
            } else if (
              x.name === "positionSelection" &&
              crewmemberData["position"]
            ) {
              const position = Object.values(CrewMemberPosition).find(
                (position) => position === crewmemberData["position"]
              );
              x.value = position ?? "Other";
            } else {
              if (x.subtype === "phone") {
                x.value = crewmemberData[x.name]
                  ? normalizeInput(crewmemberData[x.name], "")
                  : x.defaultValue;
                x.defaultValue = normalizeInput(crewmemberData[x.name], "");
              } else {
                x.value = crewmemberData[x.name]
                  ? crewmemberData[x.name]
                  : x.defaultValue;
                x.defaultValue = crewmemberData[x.name];
              }
            }
          })
        );

        this.handlePositionSelection(
          fields,
          fields.find((field) => field.name == "positionSelection")?.value ??
            null,
          crewmemberData["position"]
        );
        this.setState({ crewmember: crewmemberData, fields: fields });
      });
  };

  validate = () => {
    this.state.fields.forEach((field) => {
      validateField(field);
    });
  };

  onImageDrop = (acceptedFiles) => {
    let fields = [...this.state.fields];
    let imageField = fields.find((x) => x.name === "image");

    const reader = new FileReader();
    const loc = window.URL.createObjectURL(acceptedFiles[0]);

    imageField.value = acceptedFiles[0];
    imageField.localSrc = loc;
    this.setState({ fields: fields });
  };

  onImageRemove = () => {
    let fields = [...this.state.fields];
    let imageField = fields.find((x) => x.name === "image");

    imageField.value = "";
    imageField.localSrc = "";
    this.setState({ fields: fields });
  };

  clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach((element) => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach((element) => {
      if ((element.required && !element.value) || !element.isValid)
        isValid = false;
    });

    return isValid;
  };

  touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find((x) => x.name === field.name);
    if (!updatedField) return;

    updatedField.isTouched = field.value !== field.defaultValue;
    this.setState({ fields: fields });
  };

  addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + " is-valid";
    if (!field.isValid && field.value) return cl + " is-invalid";

    return cl;
  }

  handlePositionSelection = (fields, selectedPosition, otherValue) => {
    if (selectedPosition === "Other") {
      const index = fields.findIndex(
        (field) => field.name === "positionSelection"
      );
      fields.splice(index + 1, 0, {
        name: "position",
        value: otherValue,
        defaultValue: "",
        placeholder: "Other Position",
        type: "text",
        label: "Other Position",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        required: false,
      });
    } else {
      const index = fields.findIndex((field) => field.name === "position");
      if (index >= 0) fields.splice(index, 1);
    }
  };

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find((x) => x.name === event.target.name);

    if (!field) return;

    if (field.subtype === "phone") {
      field.value = normalizeInput(event.target.value, field.value);
    } else if (field.name === "positionSelection") {
      if (event.target.value === "None") field.value = null;
      else field.value = event.target.value;
      this.handlePositionSelection(fields, event.target.value);
    } else {
      field.value = event.target.value;
    }

    this.validate();

    if (!field.value) field.isTouched = false;

    const globalValid = this.getGlobalValid(fields);
    this.setState({ fields: fields, isValid: globalValid });
  };

  getObjectFromFields = () => {
    let obj = {};
    this.state.fields.forEach((item) => {
      if (item.name !== "image") obj[item.name] = item.value;
    });

    return obj;
  };

  handelSubmit = (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const crewMemberObject = this.getObjectFromFields();
    const { positionSelection, ...obj } = {
      ...crewMemberObject,
      position: crewMemberObject.position ?? crewMemberObject.positionSelection ?? null,
    };
    const imageField = this.state.fields.find((x) => x.name === "image");
    if (imageField.value && imageField.value !== imageField.defaultValue) {
      obj.imageTimeStamp = new Date();
    }
    if (!imageField.value && imageField.defaultValue) {
      obj.imageTimeStamp = null;
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    db.collection('inventories')
      .doc(inventoryId)
      .collection('crew')
      .doc(this.state.crewmember.id)
      .update(obj)
      .then(doc => {
        if(imageField.value && imageField.value !== imageField.defaultValue) {
          db.collection('inventories')
          .doc(inventoryId)
          .collection('crew')
          .doc(this.state.crewmember.id)
          .get()
          .then(x => {
            const storageRef = storage.ref(currentUser.userProfile.companyID + '/crewMember/' + this.state.crewmember.id + '/' + x.data().imageTimeStamp.seconds + '/med');
            storageRef.put(imageField.value).then((snapshot) => {
              db.collection("inventories").doc(inventoryId)
                .collection("crew").doc(this.state.crewmember.id).update({
                  hasProfileImage: true
                }).then(document => {
                  this.props.history.push("/crewmembers/" + this.state.crewmember.id);
                }).catch(err => {
                    console.log(err);
                });
            });
          });
        } else {
          this.props.history.push("/crewmembers/" + this.state.crewmember.id);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  submitButton = () => {
    const submitBtnClasses = "btn btn-primary mr-2";
    const submitBtnClassesWithError = this.state.submitError
      ? submitBtnClasses + " is-invalid"
      : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading
      ? submitBtnClassesWithError + " spinner spinner-white spinner-right"
      : submitBtnClassesWithError;

    return {
      title: "Save",
      className: submitBtnClassesFinal,
      onClick: this.handelSubmit,
      disabled: !this.state.isValid || this.state.loading,
    };
  };

  cancelButton = () => {
    return {
      title: "Cancel",
      className: "btn btn-secondary mr-2",
      onClick: () => {
        this.props.history.push("/crewmembers");
      },
    };
  };

  render() {
    if (!this.state.crewmember) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">Edit crewmember</h3>
            <div className="card-toolbar">
              <div className="example-tools justify-content-center">
                <span
                  className="example-toggle"
                  data-toggle="tooltip"
                  title="View code"
                />
                <span
                  className="example-copy"
                  data-toggle="tooltip"
                  title="Copy code"
                />
              </div>
            </div>
          </div>
          <SimpleForm
            formUniqueName="edit-crewmember"
            fields={this.state.fields}
            touchField={this.touchField}
            submitButton={this.submitButton()}
            cancelButton={this.cancelButton()}
            handleInputChange={this.handleChange}
            onSubmit={this.handelSubmit}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CrewmemberEdit;