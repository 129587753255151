import React, { Component } from 'react';
import Dropzone from '../Dropzone/Dropzone';
import { File, Close } from '../Icons/Svg';

class UploadJobPhotoModal extends Component {
    constructor(props) {
        super(props);

        this.hiddenBtnRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show && !prevProps.show) {
            this.hiddenBtnRef.current.click();
        }

        if (!this.props.show && prevProps.show) {
            this.hiddenBtnRef.current.click();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="addFileModal" 
                    data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Upload File</h5>
                                <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i aria-hidden="true" className="ki ki-close" />
                                </button>
                            </div>
                            <div className="modal-body text-center">
                                <input
                                    type="text"
                                    className="form-control mb-4"
                                    value={this.props.name}
                                    placeholder="Photo Name"
                                    name="name"
                                    onChange={this.props.handleNameChange}
                                />
                                {this.props.file ? 
                                <>
                                    <span className="svg-icon svg-icon-10x"><File /></span><br/>
                                    <span>{this.props.file.name} <span role="button" onClick={this.props.onRemove} 
                                        className="svg-icon svg-icon-3x"><Close /></span></span>
                                </>
                                :
                                <Dropzone field={{ onDrop: this.props.onImageDrop }}
                                    supportedTypes="JPEG, PNG, SVG" /> }
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.props.save} type="button" disabled={!this.props.name || !this.props.file}
                                    className={this.props.saving ? "btn btn-primary font-weight-bold spinner spinner-white spinner-right" : "btn btn-primary font-weight-bold"}>Save</button>
                                <button onClick={this.props.toggle} type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} ref={this.hiddenBtnRef} type="button" className="btn btn-primary" data-toggle="modal" data-target="#addFileModal">
                </button>
            </React.Fragment>
        );
    }
}

export default UploadJobPhotoModal;