import React, { Component } from 'react';
import SimpleForm from '../../components/Form/SimpleForm';
import { validateField } from '../../shared/Validation';

import app, { db, storage } from '../../firebase';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';

import { addEquipment, getCategories, getEquipment, getSingleEquipment, uploadImage } from '../../shared/Api';
import { showToast, sortAlphabetically } from '../../shared/Util';
import Loader from '../../components/Layout/Loader/Loader';

class EquipmentNew extends Component {
  static contextType = AuthContext;
  isTutorial = this.context.isTutorial;
  tutorialStep = this.context.tutorialStep;

  state = {
    equipment: [],
    categories: [],
    duplicateObj: null,
    fields: [
      {
        name: 'equipmentID',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Equipment Id',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('equipment_id_required')
          }
        ]
      },
      {
        name: 'make',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Make',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        dataToggle: "tooltip", 
        title: "These fields are required to make it easier to organize and find the right type of equipment or tools",
        dataPlacement: "left",
        validators: [
          {
            type: 'required',
            message: getMessages('equipment_make_required')
          }
        ]
      },
      {
        name: 'model',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Model',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('equipment_model_required')
          }
        ]
      },
      {
        name: 'year',
        value: '',
        defaultValue: '',
        type: 'number',
        label: 'Year (optional)',
        min: 0,
        max: 2050,
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        validators: [
          // {
          //   type: 'numeric',
          //   message: getMessages('year_number')
          // },
          {
            type: 'min',
            value: 0,
            message: getMessages('year_span')
          },
          {
            type: 'max',
            value: 2050,
            message: getMessages('year_span')
          }
        ]
      },
      {
        name: 'serialNumber',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Serial Number (optional)',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false
      },
      // {
      //   name: 'tag',
      //   value: '',
      //   defaultValue: '',
      //   type: 'text',
      //   label: 'Tag (optional)',
      //   isValid: true,
      //   validationMessage: '',
      //   isTouched: false,
      //   additionalMessage: null,
      //   required: false
      // },
      {
        name: 'category',
        value: '',
        defaultValue: '',
        type: 'select',
        options: [],
        label: 'Category',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        dataToggle: "tooltip", 
        title: "After selecting either Equipment or Tool, pick the appropriate Sub-Category. Depending on your selection, there may be some extra fields to enter helpful details.",
        dataPlacement: "left",
      },
      {
        name: 'subCategory',
        value: '',
        defaultValue: '',
        type: 'select',
        options: [],
        label: 'Sub Category',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false
      },
      {
        name: 'description',
        value: '',
        defaultValue: '',
        type: 'textarea',
        label: 'Description',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        rows: 4
      },
      {
        name: 'image',
        value: [],
        defaultValue: '',
        type: 'multiimage',
        label: 'Image',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        localSrc: '',
        onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
        onRemove: (index) => { this.onImageRemove(index); },
        dataToggle: "tooltip", 
        title: "Lets not forget the pretty pictures. Add in as many as you want. Take a pic of the service truck setup. Or the S/N plate on the rig.",
        dataPlacement: "left"
      }
      // {
      //   name: 'image',
      //   value: '',
      //   defaultValue: '',
      //   type: 'dropzone',
      //   label: 'Image',
      //   isValid: true,
      //   validationMessage: '',
      //   isTouched: false,
      //   additionalMessage: null,
      //   required: false,
      //   localSrc: '',
      //   onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
      //   onRemove: () => { this.onImageRemove(); }
      // }
    ],
    isValid: this.props.match.params.id ? true : false,
    submitError: false,
    submitErrorMessage: '',
    loading: false,
    accountCreated: false
  };

  componentDidMount = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    if(this.isTutorial && this.tutorialStep === 'addingEquipmentRequired') {
      this.setSection1Active(true);
    }

    if(this.isTutorial && this.tutorialStep === 'addingEquipmentCategory') {
      this.setSection2Active(true);
    }

    if(this.isTutorial) {
      document.getElementById("skip-tutorial-button").style.display = "block";
    }

    const equipment = await getEquipment(inventoryId, companyId);
    this.setState({ equipment: equipment.data });

    this.tyrLoadEquipment();
    //this.loadCategories();    
  }

  componentWillUnmount = () => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    document.getElementById("skip-tutorial-button").style.display = "none";
  }

  setSection1Active = (active) => {
    window.$('[id="add-equipment-input-make"]').tooltip({ trigger: 'manual' });
    window.$('[id="add-equipment-input-make"]').tooltip('hide');

    if(active) window.$('[id="add-equipment-input-make"]').tooltip('show');

    window.$('[id="add-equipment-input-equipmentID"]').css("border", active ? "1px solid" : "1px solid #E4E6EF");
    window.$('[id="add-equipment-input-make"]').css("border", active ? "1px solid" : "1px solid #E4E6EF");
    window.$('[id="add-equipment-input-model"]').css("border", active ? "1px solid" : "1px solid #E4E6EF");
  }

  setSection2Active = (active) => {
    window.$('[id="add-equipment-input-category"]').tooltip({ trigger: 'manual' });
    window.$('[id="add-equipment-input-category"]').tooltip('hide');

    if(active) window.$('[id="add-equipment-input-category"]').tooltip('show');

    window.$('[id="add-equipment-input-category"]').css("border", active ? "1px solid" : "1px solid #E4E6EF");
  }

  setSection3Active = (active) => {
    window.$('[id="add-equipment-input-image"]').tooltip({ trigger: 'manual' });
    window.$('[id="add-equipment-input-image"]').tooltip('hide');

    setTimeout(() => {
      if(active) window.$('[id="add-equipment-input-image"]').tooltip('show');
    }, 300);
  }

  changeTutorialSection = () => {    
    if(this.isTutorial && this.tutorialStep === 'addingEquipmentRequired' &&
     (this.state.fields[0].value && this.state.fields[1].value && this.state.fields[2].value)) {
      const nextTutorialStep = this.context.nextTutorialStep; 
      nextTutorialStep();
      this.setSection1Active(false);
      this.setSection2Active(true);
    }

    if(this.isTutorial && this.tutorialStep === 'addingEquipmentCategory') {
      const nextTutorialStep = this.context.nextTutorialStep; 
      nextTutorialStep();
      this.setSection2Active(false);
      this.setSection3Active(true);
    }
  }
 
  tyrLoadEquipment = async () => {
    const id = this.props.match.params.id;

    if (!id) {
      this.loadCategories();
      return;
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const equipmentResult = await getSingleEquipment(
      inventoryId,
      id,
      companyId,
      true
    );

    const fields = [...this.state.fields];
    fields.forEach((x) => {
      if (x.name === "image") {
      } else {
        x.value = equipmentResult.data[x.name];
        x.defaultValue = equipmentResult.data[x.name];
      }
    });

    if (equipmentResult.data.subCategoryTypes) {
      const result = this.getCategoryTypes({
        name: equipmentResult.data.subCategory,
        types: equipmentResult.data.subCategoryTypes,
      });
      fields.splice(7, 0, ...result);
    }

    this.setState({ duplicateObj: equipmentResult.data, fields: fields });
    this.loadCategories();
  }

  loadCategories = async () => {
    const categories = await getCategories();

    const fields = [...this.state.fields];
    const categoryField = fields.find(x => x.name === 'category');

    categoryField.options = categories.data.map(x => { return { display: x.name, value: x.name } });
    if(categoryField.value?.length<=0)
      categoryField.value = categories.data[0].name 
    const selectedCategory = categories.data.find((category)=>category.name==categoryField.value)??categories.data[0]

    this.populateSubCategories(selectedCategory);

    this.setState({ categories: categories.data, fields: fields });
  }

  populateSubCategories = async (category) => {
    const fields = [...this.state.fields];
    const subCategoryField = fields.find(x => x.name === 'subCategory');

    subCategoryField.options = category.subCategories.map(x => { return { display: x.name, value: x.name } });
    sortAlphabetically(subCategoryField.options);
    
    subCategoryField.options.unshift({ display: 'None', value: null });
  }

  getCategoryTypes = (subCategory) => {
    if(!subCategory)
      return [];

    const typeFields = subCategory.types.map(x => {
      const obj = {
        name: x.name,
        value: x.value??'',
        defaultValue: '',
        type: this.getTypeForSubcategoryType(x.type),
        label: x.unitI ? x.name + "("+ x.unitI +")" : x.name,
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        categoryTypeField: true
      };
  
      if(obj.type === 'select') {
        obj.options = x.value.split(',').map(x => { return { display: x, value: x } });
        sortAlphabetically(obj.options);
      }
  
      return obj;
    });

    return typeFields;
  }

  populateSubcategoryTypes = async (subCategory) => {
    const pureFields = this.state.fields.filter(x => !x.categoryTypeField);

    if(!subCategory) {
      const cleared = pureFields.map((field) => {
        if (field.name == "subCategory") return { ...field, value: '', defaultValue: '' };
        else return field;
      });
      this.setState({ fields: cleared });
      return;
    }

    const typeFields = this.getCategoryTypes(subCategory);

    let newFields = pureFields.slice(0, 7);
    newFields = newFields.concat(typeFields);
    newFields = newFields.concat(pureFields.slice(7, pureFields.length));

    this.setState({ fields: newFields });
  }  

  getTypeForSubcategoryType = (type) => {
    switch(type) {
      case 'string':
        return 'text';
      case 'number':
        return 'number';
      case 'singleSelect':
        return 'select';
      default:
        return type;
    }
  }

  validate = () => {
    this.state.fields.forEach(field => {
      validateField(field);
    });
  }

  onImageDrop = (acceptedFiles) => {
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');

    acceptedFiles.forEach(element => {
      const reader = new FileReader();
      const loc = window.URL.createObjectURL(element);

      imageField.value.push({
        value: element,
        localSrc: loc
      });
    });

    this.setState({ fields: fields });
  }

  onImageRemove = (index) => {
    
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');

    imageField.value.splice(index, 1);

    this.setState({ fields: fields });
  }

  clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach(element => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach(element => {
      if ((element.required && !element.value) || !element.isValid) isValid = false;
    });

    return isValid;
  }

  touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find(x => x.name === field.name);
    if (!updatedField)
      return;

    updatedField.isTouched = true;
    this.setState({ fields: fields });
  }

  addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + ' is-valid';
    if (!field.isValid && field.value) return cl + ' is-invalid';

    return cl;
  }

  gnerateInputId(field) {
    return "ca-input-" + field.name;
  }

  onBlur = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
      return;

    if(field.name === 'equipmentID' || field.name === 'make' || field.name === 'model') {
      this.changeTutorialSection();
    }
  }

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
      return;

    field.value = event.target.value;
    this.validate();

    if (!field.value)
      field.isTouched = false;

    const globalValid = this.getGlobalValid(fields);
    this.setState({ fields: fields, isValid: globalValid });

    if(field.name === 'category') {
      const category = this.state.categories.find(x => x.name === event.target.value);
      if(category) {
        this.populateSubCategories(category);
        this.populateSubcategoryTypes(null);
      }
    }

    if(field.name === 'subCategory') {
      const categoryName = this.state.fields.find(x => x.name === 'category').value;
      const category = this.state.categories.find(x => x.name === categoryName);
      if(category) {
        const subCategory = category.subCategories.find(x => x.name === event.target.value);
        this.populateSubcategoryTypes(subCategory);
      }

      if(this.isTutorial && (this.tutorialStep === 'addingEquipmentRequired' 
        || this.tutorialStep === 'addingEquipmentCategory')) {
        const setTutorialStep = this.context.setTutorialStep; 
        setTutorialStep("addingEquipmentImage");

        this.setSection2Active(false);
        this.setSection3Active(true);
      }
    }
  }

  getObjectFromFields = () => {
    let obj = {};
    this.state.fields.filter(x => !x.categoryTypeField).forEach(item => {
      if(item.name !== 'image')
        obj[item.name] = item.value;
    });

    const categoryField = this.state.fields.find(x => x.name === 'category');
    const subCategoryField = this.state.fields.find(x => x.name === 'subCategory');
    const category = this.state.categories.find(x => x.name === categoryField.value);
    const subCategory = category.subCategories.find(x => x.name === subCategoryField.value);

    if(subCategory) {
      const categoryTypes = subCategory.types;
      const categoryTypeFields = this.state.fields.filter(x => x.categoryTypeField);

      obj['subCategoryTypes'] = categoryTypes.map(x => {
        const ctf = categoryTypeFields.find(y => y.name === x.name);

        return {
          ...x,
          value: ctf.value
        };
      });
    }

    return obj;
  }

  checkDuplicateEquipmentID = (equipmentObj) => {
    return this.state.equipment.some(x => x.equipmentID === equipmentObj.equipmentID);
  }

  handleSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const obj = this.getObjectFromFields();
    obj.hasProfileImage = false;

    if(this.checkDuplicateEquipmentID(obj)) {
      showToast('danger', 'Equipment ID: ' + obj.equipmentID + ' has already been taken.');
      this.setState({ loading: false });
      return;
    }

    const imageField = this.state.fields.find(x => x.name === 'image');
    if(imageField.value.length > 0) {
      obj.imageTimeStamp = new Date();
      obj.hasProfileImage = true;

      const photos = [];
      imageField.value.forEach((element, index) => {
        if(index != 0) {
          let date = new Date();
          date.setSeconds(date.getSeconds() + 100 * index);
          photos.push(date);
        }
      });

      obj.photos = photos;
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const equipmentAddResult = await addEquipment(inventoryId, companyId, obj);
    const equipment = await getSingleEquipment(inventoryId, equipmentAddResult.data, companyId, false);

    const addPhotosArray = equipment.data.photos;
    if(equipment.data.imageTimeStamp) {
      addPhotosArray.unshift(equipment.data.imageTimeStamp);
    }  

    let index = 0;
    await Promise.all(imageField.value.map(async (x) => {            
      const timestamp = addPhotosArray[index];
      index++;
      await uploadImage(companyId, 'equipment', equipment.data.id, timestamp, x.value);
    })); 

    if(this.isTutorial) {
      this.setSection3Active(false);
      const setTutorialStep = this.context.setTutorialStep; 
      setTutorialStep("addCrewmember");
      this.props.history.push("/crewmembers");
    } else {
      this.props.history.goBack()// push("/equipment/" + equipment.data.id);
    }
  }

  submitButton = () => {
    const submitBtnClasses = 'btn btn-primary mr-2';
    const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

    return {
      title: "Save",
      className: submitBtnClassesFinal,
      onClick: this.handleSubmit,
      disabled: !this.state.isValid || this.state.loading
    };
  }
  

  cancelButton = () => {
    return {
      title: "Cancel",
      className: "btn btn-secondary mr-2",
      onClick: () => { this.props.history.push('/equipment'); }
    };
  }

  render() {
    if(!this.state.duplicateObj && this.props.match.params.id) {
      return <Loader/>
    }

    return (
      <React.Fragment>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">
              Add new equipment
                        </h3>
          </div>
          {/* <span id="tutorial-message-pointer" style={{ 
            margin: "0.65rem 2rem", 
            backgroundColor: 'white',
            padding: "0.65rem 2rem" }}>
              These fields are required to make it easier to organize and find theright type of equipment or tools.</span> */}
          <SimpleForm
            formUniqueName="add-equipment"
            fields={this.state.fields}
            touchField={this.touchField}
            submitButton={this.submitButton()}
            cancelButton={this.cancelButton()}
            handleInputChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onBlur={this.onBlur}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default EquipmentNew;