import React, { Component } from "react";
import app from "../../../firebase";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import { Link } from "react-router-dom";

import { AuthContext } from "../../../shared/Auth";
import { getMessages } from "../../../components/Messages/GetMessages";
import { getInvite } from "../../../shared/Api";
import LandingScreenInfo from "../Landing/LandingInfo";

class JoinCompany extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      inviteCode: "",
      submitError: false,
      submitErrorMessage: "",
      loading: false,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    const invite = await getInvite(parseInt(this.state.inviteCode));
    if (!invite) {
      this.setState({
        submitErrorMessage: "Invite code is incorrect.",
        submitError: true,
      });
      this.setState({ loading: false });
      return;
    }

    localStorage.setItem(
      "crewmemberInviteState",
      JSON.stringify({
        crewmemberId: invite.data.invitedUserID,
        companyId: invite.data.companyID,
        email: invite.data.email,
      })
    );

    this.props.history.push("/signup?state=acceptinvite");
  };

  handleChange = (event) => {
    let updatedState = { ...this.state };
    updatedState[event.target.name] = event.target.value;

    this.setState(updatedState);
  };

  render() {
    const submitBtnClasses =
      "btn btn-block btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3 rounded-0";
    const submitBtnClassesWithError = this.state.submitError
      ? submitBtnClasses + " is-invalid"
      : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading
      ? submitBtnClassesWithError + " spinner spinner-white spinner-right"
      : submitBtnClassesWithError;

    return (
      <div
        className="d-flex flex-column flex-root"
      >
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-content d-flex flex-column pt-md-32 pt-12">
            <div className="pb-5 pb-lg-15 mb-md-2 mb-6">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                Join your team on Equappment
              </h3>
              <div className="text-muted font-weight-bold font-size-h4">
                or
                <Link to="/signin" className="text-primary font-weight-bolder">
                  {" "}
                  login to your account
                </Link>
              </div>
            </div>
            <div className="login-form">
              <form
                onSubmit={this.handleSubmit}
                className="form"
                id="kt_login_singin_form"
                noValidate="novalidate"
              >
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">
                    Invitation code
                  </label>
                  <input
                    value={this.state.inviteCode}
                    className="form-control form-control-solid py-7 px-6 rounded-0 border-0 input-h-over"
                    type="inviteCode"
                    name="inviteCode"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="card card-custom bg-primary mb-8">
                  <div class="card-body text-white">
                    Open the invitation email recieved from your company owner.
                    Enter invitation code here and you will be guided through
                    sing up process to join your team.
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      id="kt_login_singin_form_submit_button"
                      className={submitBtnClassesFinal}
                      disabled={!this.state.inviteCode}
                    >
                      Join
                    </button>
                    <div className="invalid-feedback justify-content-center">
                      {this.state.submitErrorMessage}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(JoinCompany);
