import React, { Component } from 'react';
import { AuthContext } from "../../shared/Auth";
import { touchField, updateFormOnChange, fieldsToObject } from '../../shared/FormHelper';
import { getMessages } from '../../components/Messages/GetMessages';
import BasicForm from '../../components/Form/BasicForm';
import { addCrew } from '../../shared/Api';

class CrewsNew extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;

    state = {
        addCrewForm: {
            name: 'add-new-crew',
            isValid: false,
            submitErrorMessage: null,
            saving: false,
            fields: [
                {
                    name: 'crewID',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Crew ID #',
                    placeholder: 'Crew ID',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('form_field_description_message_crew_id'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Crew ID is required.'
                        }
                    ]
                },
                {
                    name: 'crewName',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Crew Name',
                    placeholder: 'Crew Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('form_field_description_message_crew_name'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Crew name is required.'
                        }
                    ]
                },
                {
                    name: 'crewColor',
                    value: {
                        red: 255,
                        green: 0,
                        blue: 0,
                        alpha: 0.3
                    },
                    defaultValue: '',
                    type: 'colorpicker',
                    label: 'Crew Color',
                    placeholder: 'Crew Color',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('form_field_description_message_crew_color'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Crew color is required.'
                        }
                    ]
                }
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase py-4 mr-2 ',
                    onClick: () => this.submit(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase py-4',
                    onClick: () => this.props.history.goBack(),
                    disabled: () => false
                }
            ]
        }
    };

    prepareAddCrewForm = () => {
        let form = this.state.addCrewForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.addCrewForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.addCrewForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    submit = async () => {
        const form = { ...this.state.addCrewForm };
        form.saving = true;
        this.setState({ addCrewForm: form });

        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        const crew = fieldsToObject(this.state.addCrewForm.fields);

        const addCrewResult = await addCrew(inventoryId, crew);
        form.saving = false;
        this.setState({ addCrewForm: form });
        this.props.history.push('/crews');
    }

    render() {
        const addCrewForm = this.prepareAddCrewForm();

        return (
            <div className="card">
                <div className="card-body">
                    <h3 className="mb-10 font-weight-bold text-dark">Add new crew</h3>
                    <div className="mb-20">
                        <BasicForm {...addCrewForm} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CrewsNew;