import React, { Component } from 'react';

class InputTextModal extends Component {

    state = {
        input:this.props.input
    }

    constructor(props) {
        super(props);

        this.hiddenBtnRef = React.createRef()
      }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.show && !prevProps.show) {
            this.hiddenBtnRef.current.click();
        }

        if(!this.props.show && prevProps.show) {
            this.hiddenBtnRef.current.click();
        }
    }

    render() {
        return (
            <React.Fragment>
            <div className="modal fade" id={"input_text_modal" + "_" + this.props.id} 
                data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true"
                >
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
                    <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>
                    </div>
                    <div className="modal-body">
                        {this.props.body}
                        <input
                                    type="text"
                                    className="form-control mt-3"
                                    value={this.state.input}
                                    placeholder="Folder Name"
                                    name="folder-name"
                                    onChange={(event)=>{ 
                                        this.setState({input:event.target.value})
                                    }}
                                />
                    </div>
                    <div className="modal-footer">
                    <button onClick={(e)=>{
                        this.props.yesButton.onClick(this.state.input)
                        this.setState({input:""})
                    }} type="button"  disabled={!this.state.input} className={this.props.yesButton.spinner ? "btn btn-danger font-weight-bold spinner spinner-white spinner-right" : "btn btn-danger font-weight-bold"}>{this.props.yesButton.title}</button>
                    <button onClick={()=>{
                        this.props.noButton.onClick()
                        this.setState({input:""})
                        }} type="button" className="btn btn-light-secondary font-weight-bold" data-dismiss="modal">{this.props.noButton.title}</button>
                    </div>
                </div>
                </div>
            </div>
            <button style={{ display: 'none' }} ref={this.hiddenBtnRef} type="button" className="btn btn-primary" data-toggle="modal" data-target={"#input_text_modal" + "_" + this.props.id} >
            </button>
            </React.Fragment>
        );
    }

}

export default InputTextModal;