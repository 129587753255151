import React, { Component } from 'react';
import SimpleForm from '../../components/Form/SimpleForm';
import { validateField } from '../../shared/Validation';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';
import { addClient, addContact, getClient, getContact, updateClient, updateContact } from '../../shared/Api';
import { normalizeInput, objectToFields } from '../../shared/FormHelper';

class ContactEdit extends Component {

  static contextType = AuthContext;

  state = {
    fields: [
      {
        name: 'firstName',
        value: '',
        defaultValue: '',
        placeholder: 'Contact First Name',
        type: 'text',
        label: 'Contact First Name',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_client_firstname'),
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('client_firstname_required')
          }
        ]
      },
      {
        name: 'lastName',
        value: '',
        defaultValue: '',
        placeholder: 'Contact Last Name',
        type: 'text',
        label: 'Contact Last Name',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_client_lastname'),
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('client_lastname_required')
          }
        ]
      },
      {
        name: 'position',
        value: '',
        defaultValue: '',
        placeholder: 'Position',
        type: 'text',
        label: 'Position',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_contact_position'),
        required: false
      },
      {
        name: 'email',
        value: '',
        defaultValue: '',
        placeholder: 'Contact Email',
        type: 'text',
        label: 'Contact Email',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_contact_email'),
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('client_email_required')
          },
          {
            type: 'email',
            message: getMessages('invalid_email')
          }
        ]
      },
      {
        name: 'phoneNumber',
        value: '',
        defaultValue: '',
        placeholder: 'Contact Phone',
        type: 'text',
        subtype: 'phone',
        label: 'Contact Phone',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_contact_phone'),
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('client_phone_required')
          },
          {
            type: 'phoneNumber',
            message: getMessages('invalid_phone')
          }
        ]
      },
      {
        name: 'phoneNumber2',
        value: '',
        defaultValue: '',
        placeholder: 'Contact Phone 2',
        type: 'text',
        subtype: 'phone',
        label: 'Contact Phone 2',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: getMessages('enter_contact_phone'),
        required: false,
        validators: [
          {
            type: 'phoneNumber',
            message: getMessages('invalid_phone')
          }
        ]
      }
    ],
    contact: null,
    isValid: false,
    submitError: false,
    submitErrorMessage: '',
    loading: false,
    accountCreated: false
  };

  componentDidMount = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const clientId = this.props.match.params.clientId;
    const contactId = this.props.match.params.id;

    if(clientId === contactId) {
      const getClientResult = await getClient(inventoryId, clientId);
      const stateFields = [...this.state.fields];
      const obj = { firstName: getClientResult.data.firstName, lastName: getClientResult.data.lastName }
      const fields = objectToFields(stateFields, obj);

      this.setState({ contact: obj, fields: fields });
    } else {
      const getContactResult = await getContact(inventoryId, clientId, contactId);
      const stateFields = [...this.state.fields];
      const fields = objectToFields(stateFields, getContactResult.data);

      this.setState({ contact: getContactResult.data, fields: fields });
    }
  }

  validate = () => {
    this.state.fields.forEach(field => {
      validateField(field);
    });
  }

  clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach(element => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach(element => {
      if ((element.required && !element.value) || !element.isValid) isValid = false;
    });

    return isValid;
  }

  touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find(x => x.name === field.name);
    if (!updatedField)
      return;

    updatedField.isTouched = true;
    this.setState({ fields: fields });
  }

  addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + ' is-valid';
    if (!field.isValid && field.value) return cl + ' is-invalid';

    return cl;
  }

  gnerateInputId(field) {
    return "ca-input-" + field.name;
  }

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
      return;

    if(field.subtype === 'phone') {
      field.value = normalizeInput(event.target.value, field.value);
    } else {
      field.value = event.target.value;
    }
    this.validate();

    if (!field.value)
      field.isTouched = false;

    const globalValid = this.getGlobalValid(fields);
    this.setState({ fields: fields, isValid: globalValid });
  }


  getObjectFromFields = () => {
    let obj = {};
    this.state.fields.forEach(item => {
      if (item.name !== 'image')
        obj[item.name] = item.value;
    });

    return obj;
  }

  handelSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const obj = this.getObjectFromFields();
    
    const clientId = this.props.match.params.clientId;
    const contactId = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    
    if(clientId === contactId) {
      await updateClient(inventoryId, this.props.match.params.clientId, { firstName: '', lastName: '' });
      const addedContact = await addContact(inventoryId, this.props.match.params.clientId, obj);
      this.props.history.push("/clients/" + this.props.match.params.clientId);
    } else {
      const addedContact = await updateContact(inventoryId, this.props.match.params.clientId, this.props.match.params.id, obj);
      this.props.history.push("/clients/" + this.props.match.params.clientId);
    }
  }

  submitButton = () => {
    const submitBtnClasses = 'btn btn-primary mr-2';
    const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

    return {
      title: "Save",
      className: submitBtnClassesFinal,
      onClick: this.handelSubmit,
      disabled: !this.state.isValid || this.state.loading
    };
  }

  cancelButton = () => {
    return {
      title: "Cancel",
      className: "btn btn-secondary mr-2",
      onClick: () => { this.props.history.push('/clients/' + this.props.match.params.clientId); }
    };
  }


  render() {
    return (
      <React.Fragment>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">
              Add new contact
            </h3>
          </div>
          {this.state.contact &&
          <SimpleForm
            formUniqueName="add-contact"
            fields={this.state.fields}
            touchField={this.touchField}
            submitButton={this.submitButton()}
            cancelButton={this.cancelButton()}
            handleInputChange={this.handleChange}
            onSubmit={this.handelSubmit}
          />}
        </div>
      </React.Fragment>


    );
  }

}

export default ContactEdit;