import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CheckOutInItem from '../../../components/Layout/CheckOutInItem/CheckOutInItem';
import { getMessages } from '../../../components/Messages/GetMessages';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import { deleteBore } from '../../../shared/Api';
import { AuthContext } from "../../../shared/Auth";
import Loader from '../../../components/Layout/Loader/Loader';
import { fromRGBValue } from '../../../shared/Util';
import $ from 'jquery';
import { BoreEntity } from '../../../shared/data/bore/BoreEntity';

class Bores extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;
    canEdit = this.user.permissions !== 'crewmember';

    state = {
        mode: "all",
        deleteBoreSelected: null,
        all: [],
        completed: [],
        current: [],
        upcoming: [],
        bores: [],
        view: 'month',
        calendar: null
    }

    componentDidMount() {
        this.props.setTabActive('bores');
        this.drawCalendar(this.props.bores);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.bores.length === 0 && (this.props.bores && this.props.bores.length !== 0)) {
            let bores = this.props.bores.sort(function (a, b) {
                return a.boreNumber - b.boreNumber;
            });

            const complete = bores.filter(x => x.finished).map(x => {
                const boreProgress = x.boreProgress ? x.boreProgress : 0;
                const percentage = boreProgress !== 0 ? (100 * parseFloat(x.boreProgress)) / parseFloat(x.boreLength) : 0;

                return { ...x, percentage: percentage.toFixed(2) }
            });

            const current = bores.filter(x => !x.finished && x.boreProgress && x.boreProgress !== 0).map(x => {
                const boreProgress = x.boreProgress ? x.boreProgress : 0;
                const percentage = boreProgress !== 0 ? (100 * parseFloat(x.boreProgress)) / parseFloat(x.boreLength) : 0;

                return { ...x, percentage: percentage.toFixed(2) }
            });

            const upcoming = bores.filter(x => !x.finished && (!x.boreProgress || x.boreProgress === 0)).map(x => {
                const boreProgress = x.boreProgress ? x.boreProgress : 0;
                const percentage = boreProgress !== 0 ? (100 * parseFloat(x.boreProgress)) / parseFloat(x.boreLength) : 0;

                return { ...x, percentage: percentage.toFixed(2) }
            });

            bores = bores.map(x => {
                const boreProgress = x.boreProgress ? x.boreProgress : 0;
                const percentage = boreProgress !== 0 ? (100 * parseFloat(x.boreProgress)) / parseFloat(x.boreLength) : 0;

                return { ...x, percentage: percentage.toFixed(2) }
            });

            this.setState({ all: bores, completed: complete, current: current, upcoming: upcoming, bores: this.props.bores });

            if(!this.state.calendar)
                this.drawCalendar(this.props.bores);
        }
    }

    getBores = () => {
        let bores = this.props.bores.sort(function (a, b) {
            return a.boreNumber - b.boreNumber;
        });;

        switch (this.state.mode) {
            case "complete":
                bores = bores.filter(x => x.finished);
                break;
            case "current":
                bores = bores.filter(x => !x.finished && x.boreProgress && x.boreProgress !== 0);
                break;
            case "upcoming":
                bores = bores.filter(x => !x.finished && (!x.boreProgress || x.boreProgress === 0));
                break;
        }

        return bores.map(x => {
            const boreProgress = x.boreProgress ? x.boreProgress : 0;
            const percentage = boreProgress !== 0 ? (100 * parseFloat(x.boreProgress)) / parseFloat(x.boreLength) : 0;

            return { ...x, percentage: percentage.toFixed(2) }
        });
    }

    getEmptyMessage = () => {
        let message = "There are no bores for this job at the moment."
        switch (this.state.mode) {
            case "complete":
                message = "There are no completed bores for this job.";
                break;
            case "current":
                message = "There are no current bores for this job."
                break;
            case "upcoming":
                message = "There are no upcoming bores for this job";
                break;
        }

        return message;
    }

    deleteBore = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const jobId = this.props.match.params.id;

        this.setState({ deleteBoreSelected: null });
        await deleteBore(inventoryId, this.state.deleteBoreSelected);
        this.props.reloadBores(jobId, inventoryId);
    }

    getCrewDot = (id, index) => {
        if (!this.props.addedCrews)
            return null;
        let crew = this.props.addedCrews.find(x => x.id === id);
        if (!crew)
            return null;
        return (
            <div style={{ position: "relative", display: 'inline-flex', }}>
                <span className="dot"
                    style={{
                        backgroundColor: "rgba(" + fromRGBValue(crew.red) + ", " + fromRGBValue(crew.green) + ", " + fromRGBValue(crew.blue) + ", " + crew.alpha + ")",
                    }}>
                </span>
                <h6 className="text-muted font-weight-bold ml-2">{crew.crewName}</h6>
            </div>
        );
    }

    drawCalendar = (schedules) => {
        if (schedules.length <= 0)
            return;

        const self = this;

        var todayDate = moment().startOf('day');
        var YM = todayDate.format('YYYY-MM');
        var YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
        var TODAY = todayDate.format('YYYY-MM-DD');
        var TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');

        var calendarEl = document.getElementById('kt_calendar');

        var calendar = new window.FullCalendar.Calendar(calendarEl, {
            plugins: ['bootstrap', 'interaction', 'dayGrid', 'basicWeek', 'list'],
            themeSystem: 'bootstrap',

            //isRTL: KTUtil.isRTL(),

            header: {
                left: 'prev,next today',
                center: 'title'
            },

            height: 800,
            contentHeight: 780,
            aspectRatio: 3,  // see: https://fullcalendar.io/docs/aspectRatio

            nowIndicator: true,
            now: TODAY,

            views: {
                dayGridMonth: { buttonText: 'month' },
                dayGridWeek: { buttonText: 'week' }
            },

            defaultView: 'dayGridMonth',
            defaultDate: TODAY,

            editable: true,
            eventLimit: true, // allow "more" link when too many events
            navLinks: true,
            eventColor: '#378006',
            events: schedules.map(x => {
                return {
                    id: x.id,
                    title: x.boreName,
                    description: x.id,
                    equipmentId: x.equipmentID,
                    jobId: x.jobID,
                    start: x.startDate.toDate(),
                    end: x.endDate ? x.endDate.toDate() : this.props.job.endDate.toDate(),
                    type: x.type,
                    className: "fc-event-" + this.getClass(x.type)
                };
            }),

            eventRender: function (info) {
                var element = $(info.el);

                if (info.event.extendedProps && info.event.extendedProps.description) {
                    if (element.hasClass('fc-day-grid-event')) {
                        element.data('content', info.event.extendedProps.description);
                        element.data('placement', 'top');
                        //window.KTApp.initPopover(element);
                    } else if (element.hasClass('fc-time-grid-event')) {
                        element.find('.fc-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
                    } else if (element.find('.fc-list-item-title').length !== 0) {
                        element.find('.fc-list-item-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
                    }
                }

                if (info.event.extendedProps.type === 1) {
                    element.css('background-color', '#C9F7F5');
                } else if (info.event.extendedProps.type === 0) {
                    element.css('background-color', '#FFA800');
                }
            },
            eventClick: function (info) {
                self.props.history.push("/jobs/" + info.event.extendedProps.jobId + '/bores/' + info.event.id);
            }
        });

        this.setState({ calendar: calendar });

        try {
            calendar.render();
        } catch (err) { }
    }

    goTo = (view) => {
        switch (view) {
            case 'list':
                this.setState({ view: 'list' });
                break;
            case 'month':
                this.setState({ view: 'month' });
                this.state.calendar.changeView("dayGridMonth");
                break;
            case 'week':
                this.setState({ view: 'week' });
                this.state.calendar.changeView("dayGridWeek");
                break;
        }
    }

    getClass = (type) => {
        switch (type) {
            case 0:
                return "warning";
            case 1:
                return "primary";
            default:
                return "primary";
        }
    }

    render() {
        return (
            <>
                {this.canEdit &&
                    <div className="row">
                        <div className="col-xl-2">
                            <div className="form-group">
                                <Link
                                    to={'/jobs/' + this.props.job.id + '/bores/new'}
                                    className='btn btn-primary font-weight-bold mr-4 w-100'>Add new bore</Link>
                            </div>
                        </div>
                    </div>}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card card-custom card-transparent">
                            <div className="card-body p-0">
                                <div className="wizard wizard-4" id="kt_wizard" data-wizard-state="step-first" data-wizard-clickable="true">
                                    <div className="wizard-nav">
                                        <div className="wizard-steps">
                                            <div className="wizard-step" onClick={() => this.setState({ mode: "all" })} data-wizard-type="step" data-wizard-state={this.state.mode === "all" ? "current" : ""}>
                                                <div className="wizard-wrapper">
                                                    <div className="wizard-label">
                                                        <div className="wizard-title">All</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wizard-step" onClick={() => this.setState({ mode: "complete" })} data-wizard-type="step" data-wizard-state={this.state.mode === "complete" ? "current" : ""}>
                                                <div className="wizard-wrapper">
                                                    <div className="wizard-label">
                                                        <div className="wizard-title">Complete</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wizard-step" onClick={() => this.setState({ mode: "current" })} data-wizard-type="step" data-wizard-state={this.state.mode === "current" ? "current" : ""}>
                                                <div className="wizard-wrapper">
                                                    <div className="wizard-label">
                                                        <div className="wizard-title">Current</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wizard-step" onClick={() => this.setState({ mode: "upcoming" })} data-wizard-type="step" data-wizard-state={this.state.mode === "upcoming" ? "current" : ""}>
                                                <div className="wizard-wrapper">
                                                    <div className="wizard-label">
                                                        <div className="wizard-title">Upcoming</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wizard-step" onClick={() => this.setState({ mode: "calendar" })} data-wizard-type="step" data-wizard-state={this.state.mode === "calendar" ? "current" : ""}>
                                                <div className="wizard-wrapper">
                                                    <div className="wizard-label">
                                                        <div className="wizard-title">Calendar</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-custom card-shadowless rounded-top-0">
                                        <div className="card-body p-0">
                                            <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                                                <div className="col-xl-12 col-xxl-7">
                                                    <form className="form mt-0 mt-lg-10" id="kt_form">
                                                        {this.props.boresLoading ? <Loader height="100px" /> :
                                                            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                                                {this.state.mode === "all" &&
                                                                    <>
                                                                        {this.state.all.length > 0 ?
                                                                            <>
                                                                                {this.state.all.map((item, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={"brs-itm" + index}>
                                                                                            <div className="d-flex align-items-center mb-1">
                                                                                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                                                                    <CheckOutInItem id={item.id} percentage={item.percentage} />
                                                                                                </div>
                                                                                                <div className="d-flex flex-column flex-grow-1">
                                                                                                <h6 className="font-weight-bold text-dark">{item.boreName}</h6>

                                                                                                    <h6 className="font-weight-bold text-dark">Bore: #{('000' + item.boreNumber).substr(-3)}</h6>
                                                                                                    <h6 className="font-weight-bold text-dark">Start Date: {moment(item.startDate.toDate()).format("MM/DD/YY")}</h6>
                                                                                                    <h6 className="text-muted font-weight-bold">Progress: {item.boreProgress ? item.boreProgress : 0}' of {item.boreLength}' complete</h6>
                                                                                                    {this.getCrewDot(item.crewID, index)}
                                                                                                </div>
                                                                                                <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                                                                                    <a className="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                        <i className="ki ki-bold-more-hor" />
                                                                                                    </a>
                                                                                                    <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                                                                        <ul className="navi navi-hover">
                                                                                                            <li className="navi-item">
                                                                                                                <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + item.id} className="navi-link">
                                                                                                                    <span className="navi-text">
                                                                                                                        View Bore Log
                                                                                                                    </span>
                                                                                                                </Link>
                                                                                                            </li>
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <Link to={{pathname :"/jobs/" + this.props.match.params.id + "/bores/" + item.id + "/edit", state:{bore:item}}} href="#" className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Edit Bore
                                                                                                                        </span>
                                                                                                                    </Link>
                                                                                                                </li>}
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <a href="#" onClick={() => this.setState({ deleteBoreSelected: item.id })} className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Delete Bore
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="separator separator-dashed mt-3 mb-2"></div>
                                                                                        </React.Fragment>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                            :
                                                                            <div className="d-flex align-items-center mb-10">
                                                                                <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                                                                    <i className="flaticon2-exclamation step-graphic" />
                                                                                    <span className="font-italic">{this.getEmptyMessage()}</span>
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                    </>}

                                                                {this.state.mode === "complete" &&
                                                                    <>
                                                                        {this.state.completed.length > 0 ?
                                                                            <>
                                                                                {this.state.completed.map((item, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={"brs-itm" + index}>
                                                                                            <div className="d-flex align-items-center mb-1">
                                                                                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                                                                    <CheckOutInItem id={item.id} percentage={item.percentage} />
                                                                                                </div>
                                                                                                <div className="d-flex flex-column flex-grow-1">
                                                                                                <h6 className="font-weight-bold text-dark">{item.boreName}</h6>
                                                                                                    <h6 className="font-weight-bold text-dark">Bore: #{('000' + item.boreNumber).substr(-3)}</h6>
                                                                                                    <h6 className="font-weight-bold text-dark">Start Date: {moment(item.startDate.toDate()).format("MM/DD/YY")}</h6>
                                                                                                    <h6 className="text-muted font-weight-bold">Progress: {item.boreProgress ? item.boreProgress : 0}' of {item.boreLength}' complete</h6>
                                                                                                    {this.getCrewDot(item.crewID, index)}
                                                                                                </div>
                                                                                                <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                                                                                    <a className="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                        <i className="ki ki-bold-more-hor" />
                                                                                                    </a>
                                                                                                    <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                                                                        <ul className="navi navi-hover">
                                                                                                            <li className="navi-item">
                                                                                                                <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + item.id} className="navi-link">
                                                                                                                    <span className="navi-text">
                                                                                                                        View Bore Log
                                                                                                                    </span>
                                                                                                                </Link>
                                                                                                            </li>
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + item.id + "/edit"} href="#" className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Edit Bore
                                                                                                                        </span>
                                                                                                                    </Link>
                                                                                                                </li>}
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <a href="#" onClick={() => this.setState({ deleteBoreSelected: item.id })} className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Delete Bore
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="separator separator-dashed mt-3 mb-2"></div>
                                                                                        </React.Fragment>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                            :
                                                                            <div className="d-flex align-items-center mb-10">
                                                                                <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                                                                    <i className="flaticon2-exclamation step-graphic" />
                                                                                    <span className="font-italic">{this.getEmptyMessage()}</span>
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                    </>}

                                                                {this.state.mode === "current" &&
                                                                    <>
                                                                        {this.state.current.length > 0 ?
                                                                            <>
                                                                                {this.state.current.map((item, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={"brs-itm" + index}>
                                                                                            <div className="d-flex align-items-center mb-1">
                                                                                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                                                                    <CheckOutInItem id={item.id} percentage={item.percentage} />
                                                                                                </div>
                                                                                                <div className="d-flex flex-column flex-grow-1">
                                                                                                <h6 className="font-weight-bold text-dark">{item.boreName}</h6>
                                                                                                    <h6 className="font-weight-bold text-dark">Bore: #{('000' + item.boreNumber).substr(-3)}</h6>
                                                                                                    <h6 className="font-weight-bold text-dark">Start Date: {moment(item.startDate.toDate()).format("MM/DD/YY")}</h6>
                                                                                                    <h6 className="text-muted font-weight-bold">Progress: {item.boreProgress ? item.boreProgress : 0}' of {item.boreLength}' complete</h6>
                                                                                                    {this.getCrewDot(item.crewID, index)}
                                                                                                </div>
                                                                                                <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                                                                                    <a className="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                        <i className="ki ki-bold-more-hor" />
                                                                                                    </a>
                                                                                                    <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                                                                        <ul className="navi navi-hover">
                                                                                                            <li className="navi-item">
                                                                                                                <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + item.id} className="navi-link">
                                                                                                                    <span className="navi-text">
                                                                                                                        View Bore Log
                                                                                                                    </span>
                                                                                                                </Link>
                                                                                                            </li>
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + item.id + "/edit"} href="#" className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Edit Bore
                                                                                                                        </span>
                                                                                                                    </Link>
                                                                                                                </li>}
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <a href="#" onClick={() => this.setState({ deleteBoreSelected: item.id })} className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Delete Bore
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="separator separator-dashed mt-3 mb-2"></div>
                                                                                        </React.Fragment>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                            :
                                                                            <div className="d-flex align-items-center mb-10">
                                                                                <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                                                                    <i className="flaticon2-exclamation step-graphic" />
                                                                                    <span className="font-italic">{this.getEmptyMessage()}</span>
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                    </>}

                                                                {this.state.mode === "upcoming" &&
                                                                    <>
                                                                        {this.state.upcoming.length > 0 ?
                                                                            <>
                                                                                {this.state.upcoming.map((item, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={"brs-itm" + index}>
                                                                                            <div className="d-flex align-items-center mb-1">
                                                                                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                                                                    <CheckOutInItem id={item.id} percentage={item.percentage} />
                                                                                                </div>
                                                                                                <div className="d-flex flex-column flex-grow-1">
                                                                                                <h6 className="font-weight-bold text-dark">{item.boreName}</h6>
                                                                                                    <h6 className="font-weight-bold text-dark">Bore: #{('000' + item.boreNumber).substr(-3)}</h6>
                                                                                                    <h6 className="font-weight-bold text-dark">Start Date: {moment(item.startDate.toDate()).format("MM/DD/YY")}</h6>
                                                                                                    <h6 className="text-muted font-weight-bold">Progress: {item.boreProgress ? item.boreProgress : 0}' of {item.boreLength}' complete</h6>
                                                                                                    {this.getCrewDot(item.crewID, index)}
                                                                                                </div>
                                                                                                <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                                                                                    <a className="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                        <i className="ki ki-bold-more-hor" />
                                                                                                    </a>
                                                                                                    <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                                                                        <ul className="navi navi-hover">
                                                                                                            <li className="navi-item">
                                                                                                                <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + item.id} className="navi-link">
                                                                                                                    <span className="navi-text">
                                                                                                                        View Bore Log
                                                                                                                    </span>
                                                                                                                </Link>
                                                                                                            </li>
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <Link to={"/jobs/" + this.props.match.params.id + "/bores/" + item.id + "/edit"} href="#" className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Edit Bore
                                                                                                                        </span>
                                                                                                                    </Link>
                                                                                                                </li>}
                                                                                                            {this.canEdit &&
                                                                                                                <li className="navi-item">
                                                                                                                    <a href="#" onClick={() => this.setState({ deleteBoreSelected: item.id })} className="navi-link">
                                                                                                                        <span className="navi-text">
                                                                                                                            Delete Bore
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="separator separator-dashed mt-3 mb-2"></div>
                                                                                        </React.Fragment>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                            :
                                                                            <div className="d-flex align-items-center mb-10">
                                                                                <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                                                                    <i className="flaticon2-exclamation step-graphic" />
                                                                                    <span className="font-italic">{this.getEmptyMessage()}</span>
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                    </>}

                                                                <div style={{ display: this.state.mode === "calendar" ? 'block' : 'none' }}>
                                                                    <div style={{ float: 'right', marginBottom: '22px', marginRight: '0' }} className="card-toolbar">
                                                                        <>
                                                                            <a onClick={() => this.goTo("month")} className="btn btn-icon btn-light-secondary mr-2">
                                                                                <i className="flaticon2-calendar-2"></i>
                                                                            </a>
                                                                            <a onClick={() => this.goTo("week")} className="btn btn-icon btn-light-secondary mr-2">
                                                                                <i className="flaticon2-calendar-1"></i>
                                                                            </a>
                                                                            <a onClick={() => this.goTo("list")} className="btn btn-icon btn-light-secondary mr-2">
                                                                                <i className="flaticon-list-2"></i>
                                                                            </a>
                                                                        </>
                                                                    </div>
                                                                    <div className='clearfix'></div>
                                                                    <div className='timeline timeline-5' style={{ display: this.state.view === 'list' ? 'block' : 'none' }}>
                                                                        <div className="timeline-items">
                                                                            {this.props.bores.map((bore, index) => (
                                                                                <Link to={"/jobs/" + this.props.job.id + '/bores/' + bore.id} key={"eq-schdl-itm" + index} className="timeline-item">
                                                                                    <div className="timeline-media bg-light-primary">
                                                                                        <span className={"svg-icon svg-icon-success svg-icon-md"}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                    <path d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                                                    <path d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z" fill="#000000" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="timeline-desc timeline-desc-light-primary">
                                                                                    <span className="font-weight-bold text-dark">{bore.boreName}</span>
                                                                                        <span className="font-weight-bolder text-secondary">Bore: #{('000' + bore.boreNumber).substr(-3)}</span><br />
                                                                                        <span className="font-weight-bolder text-secondary">Start Date: {moment(bore.startDate.toDate()).format("MM/DD/YY")} <span className="font-weight-normal text-dark-50 pb-2">
                                                                                            </span></span>
                                                                                        <p className="font-weight-normal text-dark-50 pb-2">
                                                                                            Progress: {bore.boreProgress ? bore.boreProgress : 0}' of {bore.boreLength}' complete
                                                                                        </p>
                                                                                    </div>
                                                                                </Link>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: this.state.view !== 'list' ? 'block' : 'none' }} id="kt_calendar" />
                                                                </div>
                                                            </div>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    show={this.state.deleteBoreSelected}
                    title="Delete bore"
                    body={getMessages('delete_bore')}
                    yesButton={{
                        title: "Yes",
                        onClick: () => { this.deleteBore(); }
                    }}
                    noButton={{
                        title: "Cancel",
                        onClick: () => { this.setState({ deleteBoreSelected: null }) }
                    }}
                />
            </>
        );
    }
}

export default Bores;