import React, { useEffect, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";
import Geosuggest from "react-geosuggest";
import { Location } from "../../../shared/data/Location";
import EQMarker from "../../../components/GoogleMap/EQMarker";

interface LocateTicketNewMapProps {
  locateTicketCurrentLocation?: Location | null;
  onLocationAdded?: (location: Location) => void;
}

const LocateTicketNewMap = ({
  locateTicketCurrentLocation = null,
  onLocationAdded = () => {},
}: LocateTicketNewMapProps) => {
  const [locateTicketLocation, setLocateTicketLocation] = useState<Location | null>(
    locateTicketCurrentLocation
  );

  useEffect(() => {
    if (locateTicketLocation) onLocationAdded(locateTicketLocation);
  }, [locateTicketLocation]);

  return (
    <React.Fragment>
      <div className="form-group">
        <Geosuggest
        initialValue={locateTicketCurrentLocation?.address??""}
          autoActivateFirstSuggest={true}
          inputClassName="form-control"
          suggestsClassName="sug-container"
          suggestItemClassName="dropdown-item"
          suggestItemActiveClassName="sug-item-active"
          onSuggestSelect={(result: any) => {
            setLocateTicketLocation(() => {
              return new Location(
                result?.location?.lat,
                result?.location?.lng,
                result?.label
              );
            });
          }}
          autoComplete="off"
        />
      </div>
      <div className="mt-3 mb-3"><span className="font-weight-boldest">Drop a pin to map to add locate ticket location</span></div>
      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
        options={{
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          },
          mapTypeId: "satellite",
        }}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "" }}
          center={{
            lat: locateTicketLocation?.lat ?? 0,
            lng: locateTicketLocation?.lng ?? 0,
          }}
          zoom={10}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            map.addListener("click", (e: any) => {
              setLocateTicketLocation(() => {
                return new Location(e.latLng.lat(),e.latLng.lng());
              });
            })
          }}
        >
          {locateTicketLocation && (
            <EQMarker
              key={103}
              lat={locateTicketLocation?.lat}
              lng={locateTicketLocation?.lng}
              pinColor={"red"}
              />
          )}
        </GoogleMapReact>
      </div>
    </React.Fragment>
  );
};

export default LocateTicketNewMap;
