import React, { Component } from 'react';
import app from '../../../firebase';
import {withRouter} from 'react-router-dom';
import Loader from '../../../components/Layout/Loader/Loader';

import { AuthContext } from "../../../shared/Auth";

class Logout extends Component {
  static contextType = AuthContext;

  componentDidMount = async() => {
    await app.auth().signOut();
  }

  render() {
    return (
        <Loader />
    );
  }
}

export default withRouter(Logout);