import React, { useContext, useEffect, useState } from "react";
import { JobClient, JobEntity } from "../../../../shared/data/job/Job";
import { ClientEntity } from "../../../../shared/data/ClientEntity";
import { PlusIcon } from "../../../../components/Icons/Svg";
import { getJob, updateJob } from "../../../../shared/Api";
import { AuthContext } from "../../../../shared/Auth";
import { showToast } from "../../../../shared/Util";
import JobClientList from "./JobClientList";
import SelectClientList from "./SelectClientList";
import { useHistory } from "react-router";

interface JobClients {
  job: JobEntity;
  clients: ClientEntity[] | null;
  refreshData?: () => void;
}

const JobClientsComponent = ({
  job,
  clients,
  refreshData = () => {},
}: JobClients) => {


  const context = useContext(AuthContext);
  const history = useHistory();
  const [formState, setFormState] = useState<{
    clientToDelete?: ClientEntity | null;
    showClientSelection: boolean;
  }>({
    clientToDelete: null,
    showClientSelection: false,
  });

  const [jobState, setJobState] = useState<JobEntity>(job);
  
  useEffect(()=>{
    setJobState(()=>job)
  },[job])

  const getClients = () => {
    const jobClients = [...jobState.jobClients??[]]

    jobState?.clients?.forEach((client) => {
      if (!jobClients.some((jc) => jc.clientID == client))
        jobClients.push({ clientID: client, contactIDs: [] });
    });

    if (jobState?.clientID) jobClients.push({clientID: jobState?.clientID, contactIDs:[]});

    return (
      clients
        ?.filter((client) => jobClients.some((jc)=>jc.clientID==client.id) )
        .sort((a, b) => {
          return (a.companyName ?? "") > (b.companyName ?? "")
            ? 1
            : (b.companyName ?? "") > (a.companyName ?? "")
            ? -1
            : 0;
        }) ?? []
    );
  };

  const getAvailableClients = () => {
    const jobClients = jobState?.clients?.map((client) => client) ?? [];
    if (jobState?.clientID) jobClients.push(jobState?.clientID);

    return (
      clients
        ?.filter((client) => !jobClients.includes(client.id ?? ""))
        .sort((a, b) => {
          return (a.companyName ?? "") > (b.companyName ?? "")
            ? 1
            : (b.companyName ?? "") > (a.companyName ?? "")
            ? -1
            : 0;
        }) ?? []
    );
  };

  const removeClientFromJob = async (clientId?: string | null) => {
    const inventoryId = context.currentUser.company.inventoryID;

    //remove client from job
    const clients =
      jobState?.clients?.filter((client) => client != clientId) ?? null;
    const jobClients = jobState?.jobClients?.filter((jobClient) => jobClient.clientID != clientId) ?? null;
    var clientID: string | null = jobState.clientID ?? null;
    //keeping backward compatibility by removing value from clientID and adding first from client list to clientID field
    if (jobState.clientID == clientId) {
      clientID = clients?.[0] ?? null;
    }

    const updatedJob = { ...jobState, clientID: clientID, clients: clients, jobClients:jobClients };

    const result = await updateJob(inventoryId, jobState.id, updatedJob);
    if (result.status == "success") {
      setJobState(() => updatedJob);
    } else {
      showToast("danger", "Could not remove client from job");
    }
  };

  const addClientsToJob = async (jobClients?: JobClient[]) => {
    const inventoryId = context.currentUser.company.inventoryID;

    const clientsSet = new Set(jobState.clients);
    jobClients?.forEach((client) => {
      if (client.clientID) clientsSet.add(client.clientID);
    });

    const clientsPlainObject = jobState.jobClients?.filter((jc)=>jobClients?.some((newJc)=>newJc.clientID != jc.clientID)) ?? [];
   
    jobClients?.forEach((jobClient)=>{
      if (!clientsPlainObject.some((jc)=>jc.clientID==jobClient.clientID)){
        clientsPlainObject.push({clientID:jobClient.clientID, contactIDs: jobClient.contactIDs})
      }
    })

    const clientIds = Array.from(clientsSet);

    var clientID = jobState.clientID;
    //keep clientID field up to date form backward compatibility
    if (!clientID) clientID = clientIds?.[0] ?? null;

    const updatedJob = { ...jobState, clientID: clientID, clients: clientIds, jobClients:clientsPlainObject};

    const result = await updateJob(inventoryId, jobState.id, updatedJob);
    if (result.status == "success") {
      setJobState(() => updatedJob);
      refreshData();
    } else {
      showToast("danger", "Could not add client to the job");
    }
  };

  return (
    <React.Fragment>
      <div className="card card-custom gutter-b card-stretch">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column mr-auto mb-5">
              <a
                href="#"
                className="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1"
              >
                Job Clients
              </a>
            </div>

            <a
              onClick={() => {
                history.push("/clients/new");
              }}
              className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            >
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <PlusIcon />
              </span>
            </a>
          </div>

          {formState.showClientSelection ? (
            <SelectClientList
              clients={getAvailableClients()}
              onAddClients={(clients) => {
                setFormState((previousState) => {
                  return { ...previousState, showClientSelection: false };
                });
                addClientsToJob(clients);
              }}
              onCancel={() => {
                setFormState((previousState) => {
                  return { ...previousState, showClientSelection: false };
                });
              }}
            />
          ) : (
            <JobClientList
              clients={getClients()}
              jobClients={jobState.jobClients??[]}
              onRemoveClient={(clientId) => removeClientFromJob(clientId)}
              onSelectClient={() => {
                setFormState((previousState) => {
                  return { ...previousState, showClientSelection: true };
                });
                refreshData();
              }}
              onUpdateJobClient={(jobClient)=>{
                addClientsToJob([jobClient])
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobClientsComponent;
