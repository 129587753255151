import React, { useState } from "react";
import { ClientEntity } from "../../../../shared/data/ClientEntity";
import { useHistory } from "react-router";
import { SelectClient } from "../../../../components/Icons/Svg";
import { JobClient } from "../../../../shared/data/job/Job";
import SelectContactList from "./SelectContactList";

interface SelectClientListProp {
  clients?: ClientEntity[];
  onAddClients?: (jobClients: JobClient[]) => void;
  onCancel?: () => void;
}

const SelectClientList = ({
  clients,
  onAddClients = () => {},
  onCancel = () => {},
}: SelectClientListProp) => {
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState<JobClient[]>([]);

  const [formState, setFormState] = useState<{
    selectedClientForContacts?: ClientEntity | null;
    selectContact: boolean;
  }>({
    selectedClientForContacts: null,
    selectContact: false,
  });

  const getPreselectedContactForClient = () => {
    return (
      selectedItems.find(
        (jobContact) =>
          jobContact.clientID == formState.selectedClientForContacts?.id
      )?.contactIDs ?? []
    );
  };

  return (
    <React.Fragment>
      {!formState.selectContact && (
        <div>
          <div className="navbar-nav-scroll h-200px">
            {clients?.map((client) => {
              return (
                <div>
                  <div className="d-flex flex-row  align-items-center">
                    <div
                      className="d-flex flex-column flex-row-fluid mr-5"
                      onClick={() => {
                        history.push("/clients/" + client.id + "/edit");
                      }}
                    >
                      <h6>{client?.companyName ?? "No name"}</h6>
                      <span>{client?.phoneNumber ?? "No phone number"}</span>
                    </div>
                    <div className="d-flex flex-row ">
                      {selectedItems.some(
                        (item) => item.clientID == client.id
                      ) && (
                        <div
                          className="d-flex flex-column flex-row-auto mr-5"
                          onClick={() => {
                            setFormState((previousState) => {
                              return {
                                ...previousState,
                                selectContact: true,
                                selectedClientForContacts: client,
                              };
                            });
                          }}
                        >
                          <SelectClient />
                        </div>
                      )}
                      <input
                        type="checkbox"
                        className="mr-5"
                        onChange={(event) => {
                          const checked = event.currentTarget.checked;
                          const jobClients = checked
                            ? [
                                ...selectedItems,
                                new JobClient(client.id ?? "", []),
                              ]
                            : selectedItems.filter(
                                (c) => c.clientID !== client.id
                              );
                          setSelectedItems(jobClients);
                        }}
                        checked={selectedItems?.some(
                          (c) => c.clientID === client.id
                        )}
                      />
                    </div>
                  </div>
                  <div className="separator separator-solid my-1"></div>
                </div>
              );
            })}
          </div>
          <div className="mt-8">
            <button
              className="btn btn-primary text-uppercase float-right"
              onClick={() => {
                onAddClients(selectedItems);
              }}
            >
              Add
            </button>
            <button
              className="btn btn-danger text-uppercase float-right mr-5"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {formState.selectContact && (
        <SelectContactList
          contacts={formState?.selectedClientForContacts?.contacts ?? []}
          preSelectedContacts={getPreselectedContactForClient()}
          onContactsSelected={(contacts) => {
            const jobContacts = selectedItems.map((selectedItem) => {
              if (
                selectedItem.clientID == formState.selectedClientForContacts?.id
              ) {
                return {
                  ...selectedItem,
                  contactIDs:
                    contacts
                      ?.map((contact) => contact.id ?? "")
                      ?.filter((contactID) => contactID.length > 0) ?? [],
                };
              } else {
                return selectedItem;
              }
            });

            setSelectedItems(jobContacts);

            setFormState((previousState) => {
              return {
                ...previousState,
                selectContact: false,
                selectedClientForContacts: null,
              };
            });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SelectClientList;
