import React, { useContext, useState } from "react";
import { functions } from "../../firebase";
import { parseFromFirebaseDate, showToast } from "../../shared/Util";
import { AuthContext } from "../../shared/Auth";
import { CrewMemberEntity } from "../../shared/data/Crew";
import ReportComponent, { ReportRecipient } from "./ReportComponent";
import DatePickerModal from "../Modals/DatePickerModal";
import { Timestamp } from "firebase/firestore";

interface JobStatusReportProps {
  jobId: string;
  crewMembers: CrewMemberEntity[];
  reportStartDate?: Timestamp;
  reportEndDate?: Timestamp;
}

const JobStatusReport = ({
  jobId,
  crewMembers,
  reportStartDate = undefined,
  reportEndDate = undefined,
}: JobStatusReportProps) => {
  const authContext = useContext(AuthContext);

  const [sending, setSending] = useState(false);

  const [modalStates, setModalStates] = useState({
    showDateModal: false,
    showRecipientsModal: false,
  });

  const [selectedDates, setSelectedDates] = useState({
    startDate: reportStartDate,
    endDate: reportEndDate,
  });

  const sendJobStatusReport = async (emailsToSend: string[]) => {
    if (!sending) {
      setSending(true);
      let sendJobReportFunc = functions.httpsCallable("sendJobReport");

      await sendJobReportFunc({
        jobID: jobId,
        userID: authContext.currentUser.uid,
        recipients: emailsToSend.join(","),
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
      })
        .then((result) => {
          showToast("success", "Report has been sent successfully.");
        })
        .catch((exception) => {
          showToast("danger", "Error sending report.");
        });
      setSending(false);
    }
  };

  const getDialogItems = () => {
    const user = authContext.currentUser;

    const crewMemberItems: ReportRecipient[] =
      crewMembers
        ?.filter((crewMember) => crewMember.id != null)
        .map((crewMember) => {
          return new ReportRecipient(
            crewMember.id ?? "",
            `${crewMember.firstName} ${crewMember.lastName}`,
            crewMember.email ?? "Unknown email"
          );
        }) ?? [];

    const items: ReportRecipient[] = [
      new ReportRecipient(
        user.uid,
        user.displayName ?? "Unknown",
        user.email,
        true,
        true
      ),
    ].concat(crewMemberItems);

    return items;
  };

  return (
    <React.Fragment>
      <div className="form-group float-right">
        <button
          id="jobpstatus-report-btn"
          data-toggle="tooltip"
          title="Get everyone on the same page to kickoff the job. And keep everyone updated during the job. Choose a report and it will be emailed to you. Share with others as needed."
          onClick={() => {
            if (!sending)
              setModalStates({
                showDateModal: true,
                showRecipientsModal: false,
              });
          }}
          className={
            sending
              ? "btn btn-primary font-weight-bold mr-4 w-100 spinner spinner-white spinner-right"
              : "btn btn-primary font-weight-bold mr-4 w-100"
          }
        >
          Job Status Report
        </button>
      </div>
      <DatePickerModal
        show={modalStates.showDateModal}
        confirmationButtonText="Select recipient"
        startDate={reportStartDate}
        endDate={reportEndDate}
        onCancel={() => {
          setModalStates({ showDateModal: false, showRecipientsModal: false });
        }}
        onConfirm={(startDate, endDate) => {
          setSelectedDates({
            startDate: parseFromFirebaseDate(startDate),
            endDate: parseFromFirebaseDate(endDate),
          });
          setModalStates({ showDateModal: false, showRecipientsModal: true });
        }}
      />
      <ReportComponent
        id="job-status-reprot"
        title="Job Status Report"
        showDialog={modalStates.showRecipientsModal}
        reportRecipients={getDialogItems()}
        onDismiss={() => {
          setModalStates({ showDateModal: false, showRecipientsModal: false });
        }}
        sendReport={(recipients) => {
          setModalStates({ showDateModal: false, showRecipientsModal: false });
          setSending(true);
          sendJobStatusReport(recipients.map((recipients) => recipients.email));
        }}
      />
    </React.Fragment>
  );
};

export default JobStatusReport;
