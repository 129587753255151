import React, { Component } from 'react';
import { addClient, addCrew, getCrewAppointments, getCrews, updateCrew, updateCrewmember, updateEquipment } from '../../../../shared/Api';
import { resetFormValues, touchField, updateFormOnChange, getSubmitClass, fieldsToObject, objectToFields } from '../../../../shared/FormHelper';
import { AuthContext } from "../../../../shared/Auth";
import BasicForm from '../../../../components/Form/BasicForm';
import Loader from '../../../../components/Layout/Loader/Loader';
import { Link } from 'react-router-dom';
import { getMessages } from '../../../../components/Messages/GetMessages';
import { isOverlap, fromRGBValue } from '../../../../shared/Util';
import AddRemoveModal from '../../../../components/Modals/AddRemoveModal';
import ConfirmModal from '../../../../components/Modals/ConfirmModal';
import { ArrowLeft, CrewsIos, Edit } from '../../../../components/Icons/Svg';

class CrewStep extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        savingClient: false,
        crews: [],
        crew: null,
        addNewCrewShow: false,
        search: '',
        selectedCrewIds: [],
        edit: false,
        crewForm: {
            name: 'add-new-crew',
            isValid: false,
            submitErrorMessage: null,
            saving: false,
            fields: [
                {
                    name: 'crewID',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Crew ID #',
                    placeholder: 'Crew ID',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('form_field_description_message_crew_id'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Crew ID is required.'
                        }
                    ]
                },
                {
                    name: 'crewName',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Crew Name',
                    placeholder: 'Crew Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('form_field_description_message_crew_name'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Crew name is required.'
                        }
                    ]
                },
                {
                    name: 'crewColor',
                    value: {
                        red: 255,
                        green: 0,
                        blue: 0,
                        alpha: 0.3
                    },
                    defaultValue: '',
                    type: 'colorpicker',
                    label: 'Crew Color',
                    placeholder: 'Crew Color',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('form_field_description_message_crew_color'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Crew color is required.'
                        }
                    ]
                }
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase py-4 mr-2 ',
                    onClick: () => this.save(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase py-4',
                    onClick: () => this.setState({ addNewCrewShow: false, edit: false }),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        
        if (!this.props.obj || !this.props.obj.job) {
            this.props.history.push('/jobs/new');
            return;
        }

        this.props.setStep('crews');
        await this.loadCrews();
        this.loadSelectedCrewIds();

        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-crew-title"]').tooltip({ trigger: 'manual' });
            window.$('[id="job-crew-title"]').tooltip('hide');
            window.$('[id="job-crew-title"]').tooltip('show');
            document.getElementById("skip-tutorial-button").style.display = "block";
        }
    }

    componentWillUnmount() {
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-crew-title"]').tooltip('hide');
            document.getElementById("skip-tutorial-button").style.display = "none";
        }
    }

    next = () => {
        this.props.setCrews(this.state.selectedCrewIds);
        this.props.history.push('/jobs/new/equipment');
    }

    loadSelectedCrewIds = () => {
        this.setState({ selectedCrewIds: this.props.obj.selectedCrewIds ? this.props.obj.selectedCrewIds : [] });
    }

    setSelectedClient = (clientId) => {
        this.setState({ selectedClientId: clientId });
    }

    loadCrews = async () => {
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        const crews = await getCrews(inventoryId);
        const jobStartDate = this.props.obj.job.startDate;
        const jobEndDate = this.props.obj.job.endDate;

        const crewAppoitments = await getCrewAppointments(inventoryId);
        
        crews.data.forEach(crew => {
            const conflicts = crewAppoitments.data.filter(crewAp => crewAp.crewID === crew.id &&
                isOverlap(jobStartDate, jobEndDate, crewAp.startDate.toDate(), crewAp.endDate.toDate()));

            crew.available = conflicts.length === 0;
        });

        this.setState({ crews: crews.data, loading: false });
    }

    save = async () => {
        if(!this.state.edit) await this.addCrew();
        if(this.state.edit) await this.editCrew();
    }

    addCrew = async () => {
        this.setState({ savingClient: true })
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const client = fieldsToObject(this.state.crewForm.fields);

        await addCrew(inventoryId, client);
        this.closeAddNewClientForm();
        this.setState({ savingClient: false })
        this.loadCrews();
    }

    editCrew = async () => {
        this.setState({ savingClient: true })
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const client = fieldsToObject(this.state.crewForm.fields);

        await updateCrew(inventoryId, client);
        this.closeAddNewClientForm();
        this.setState({ savingClient: false, edit: false })
        this.loadCrews();
    }

    getClass = (className) => {
        if (this.props.saving)
            className += " spinner spinner-white spinner-right";

        return className;
    }

    onSelectCrewChange = (e, crewId) => {
        const selectedCrewIds = [...this.state.selectedCrewIds];
        const index = selectedCrewIds.indexOf(crewId);
        if (index > -1) {
            selectedCrewIds.splice(index, 1);
        } else {
            selectedCrewIds.push(crewId);
        }

        this.setState({ selectedCrewIds: selectedCrewIds });
    }

    closeAddNewClientForm = () => {
        let updatedForm = { ...this.state.crewForm };
        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            crewForm: updatedForm,
            addNewCrewShow: false,
            edit: false
        });
    }

    switchToEditForm = (crew) => {
        const form = { ...this.state.crewForm };
        const fields = objectToFields(form.fields, crew);

        form.fields = fields;
        this.setState({ form: form, edit: true });
    }

    prepareAddNewClientForm = () => {
        let form = { ...this.state.crewForm };

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.crewForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.crewForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    crews = () => {
        if (!this.state.search)
            return this.state.crews;

        return this.state.crews.filter(x => x.crewName.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    onChangeCrewmember = async (id) => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
    
        const crewmembers = this.props.crewmembers;
        const crewmember = crewmembers.find(x => x.id === id);
        const newCrewIdValue = !crewmember.crewID || crewmember.crewID !== this.state.crew.id ? this.state.crew.id : null;
    
        if (newCrewIdValue && crewmember.crewID) {
          this.setState({ overrideCrewmembersCrewModal: true, overrideCrewmembersCrew: id });
          return;
        }
    
        crewmember.crewID = newCrewIdValue;
        updateCrewmember(inventoryId, id, { crewID: newCrewIdValue });
    
        this.props.updateCrewmembers(crewmembers);
      }
    
      onChangeEquipment = async (id) => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
    
        const equipment = this.props.equipment;
        const pieceOfEquipment = equipment.find(x => x.id === id);
        const newCrewIdValue = !pieceOfEquipment.crewID || pieceOfEquipment.crewID !== this.state.crew.id ? this.state.crew.id : null;
    
        if (newCrewIdValue && pieceOfEquipment.crewID) {
          this.setState({ overrideEquipmentCrewModal: true, overrideEquipmentCrew: id });
          return;
        }
    
        pieceOfEquipment.crewID = newCrewIdValue;
        updateEquipment(inventoryId, id, { crewID: newCrewIdValue });
    
        this.props.updateEquipment(equipment);
      }
    
      overrideCrewmembersCrew = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const id = this.state.overrideCrewmembersCrew;
    
        if (!id)
          return;
    
        const crewmembers = this.props.crewmembers;
        const crewmember = crewmembers.find(x => x.id === id);
        const newCrewIdValue = this.state.crew.id;
    
        crewmember.crewID = newCrewIdValue;
        updateCrewmember(inventoryId, id, { crewID: newCrewIdValue });
    
        this.props.updateCrewmembers(crewmembers);
      }
    
      overrideEquipmentCrew = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const id = this.state.overrideEquipmentCrew;
    
        if (!id)
          return;
    
        const equipment = this.props.equipment;
        const pieceOfEquipment = equipment.find(x => x.id === id);
        const newCrewIdValue = this.state.crew.id;
    
        pieceOfEquipment.crewID = newCrewIdValue;
        updateEquipment(inventoryId, id, { crewID: newCrewIdValue });
    
        this.props.updateEquipment(equipment);
      }
    
      getAddedCrewmembers = () => {
        return this.props.crewmembers.filter(x => x.crewID === this.state.crew.id);
      }
    
      getAddedEquipment = () => {
        return this.props.equipment.filter(x => x.crewID === this.state.crew.id);
      }
    
      getInitials = (item) => {
        return (item.firstName + " " + item.lastName).split(" ").map((n) => n[0]).join("");
      }

    render() {
        const addNewClientForm = this.prepareAddNewClientForm();
        const crews = this.crews();

        return (
          <>
            {this.state.crew && !this.state.edit && (
              <div className="card card-custom">
                <div className="card-header h-auto py-4">
                  <div className="card-title">
                    <h3 className="card-label">
                      Crew details
                      <span className="d-block text-muted pt-2 font-size-sm">
                        {this.state.crew.crewName}
                      </span>
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <a
                      onClick={() => {
                        this.setState({ crew: null });
                      }}
                      className="btn btn-secondary-light btn-sm font-weight-bold mr-4"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <ArrowLeft />
                      </span>
                      Back to List
                    </a>
                    <a
                      onClick={() => {
                        this.switchToEditForm(this.state.crew);
                      }}
                      className="btn btn-success btn-sm font-weight-bold mr-4"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Edit />
                      </span>
                      Edit
                    </a>
                  </div>
                </div>

                <div className="card-body py-4">
                  <div className="form-group row my-2">
                    <label className="col-4 col-form-label">Crew Id:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext font-weight-bolder">
                        {this.state.crew.crewID}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row my-2">
                    <label className="col-4 col-form-label">Crew Name:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext font-weight-bolder">
                        {this.state.crew.crewName}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row my-2">
                    <label className="col-4 col-form-label">Color:</label>
                    <div className="col-8 pt-3">
                      <span
                        className="dot crew-dot"
                        style={{
                          backgroundColor:
                            "rgba(" +
                            fromRGBValue(this.state.crew.red) +
                            ", " +
                            fromRGBValue(this.state.crew.green) +
                            ", " +
                            fromRGBValue(this.state.crew.blue) +
                            ", " +
                            this.state.crew.alpha +
                            ")",
                        }}
                      ></span>{" "}
                    </div>
                  </div>
                </div>

                {this.props.crewmembers && this.props.equipment && (
                  <div className="row mt-6">
                    <>
                      <div className="col-xl-12 pt-10 pt-xl-0">
                        <div
                          className="card card-custom card-stretch"
                          id="kt_todo_view"
                        >
                          <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                            <div className="d-flex flex-column mr-2 py-2">
                              <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">
                                Crewmembers
                              </a>
                            </div>
                            <div className="card-toolbar">
                              <a
                                onClick={() => {
                                  this.setState({ crewmembersModalOpen: true });
                                }}
                                className="btn btn-primary btn-sm font-weight-bold mr-4"
                              >
                                Add/Remove
                              </a>
                            </div>
                          </div>
                          <div className="separator separator-dashed mt-3 mb-2"></div>
                          <div className="card-body pt-4 justify-content-between">
                            {this.getAddedCrewmembers().length > 0 ? (
                              <div className="navbar-nav-scroll">
                                {this.getAddedCrewmembers().map(
                                  (item, index) => {
                                    return (
                                      <div
                                        key={"jb-crw-gtadd" + index}
                                        className="d-flex align-items-center mb-7"
                                      >
                                        <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                          {item.image ? (
                                            <div className="symbol symbol-lg-50">
                                              <img alt="Pic" src={item.image} />
                                            </div>
                                          ) : (
                                            <div className="symbol symbol-lg-50 symbol-primary">
                                              <span className="symbol-label font-size-h3 font-weight-boldest">
                                                {this.getInitials(item)}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div className="d-flex flex-column">
                                          <a className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">
                                            {item.firstName +
                                              " " +
                                              item.lastName}
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <span className="font-italic">(No items)</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <AddRemoveModal
                        id="crewmembers"
                        title="Crewmembers"
                        items={this.props.crewmembers.map((x) => {
                          return {
                            id: x.id,
                            display: x.firstName + " " + x.lastName,
                            value: this.state.crew.id === x.crewID,
                          };
                        })}
                        show={this.state.crewmembersModalOpen}
                        toggle={() => {
                          this.setState({ crewmembersModalOpen: false });
                        }}
                        save={this.saveCrewmembers}
                        onChangeHandler={this.onChangeCrewmember}
                      />
                      <ConfirmModal
                        id="crewmembers"
                        show={this.state.overrideCrewmembersCrewModal}
                        title="Move crewmember"
                        body="This crew member is already associated with another Crew. Would you like to move him to this crew?"
                        yesButton={{
                          title: "Yes",
                          onClick: () => {
                            this.overrideCrewmembersCrew();
                            this.setState({
                              overrideCrewmembersCrewModal: false,
                            });
                          },
                        }}
                        noButton={{
                          title: "Cancel",
                          onClick: () => {
                            this.setState({
                              overrideCrewmembersCrewModal: false,
                            });
                          },
                        }}
                      />
                    </>
                    <>
                      <div className="col-xl-12 pt-10 pt-xl-0">
                        <div
                          className="card card-custom card-stretch"
                          id="kt_todo_view"
                        >
                          <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                            <div className="d-flex flex-column mr-2 py-2">
                              <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">
                                Equipment
                              </a>
                            </div>
                            <div className="card-toolbar">
                              <a
                                onClick={() => {
                                  this.setState({ equipmentModalOpen: true });
                                }}
                                className="btn btn-primary btn-sm font-weight-bold mr-4"
                              >
                                Add/Remove
                              </a>
                            </div>
                          </div>
                          <div className="separator separator-dashed mt-3 mb-2"></div>
                          <div className="card-body pt-4 justify-content-between">
                            {this.getAddedEquipment().length > 0 ? (
                              <div className="navbar-nav-scroll">
                                {this.getAddedEquipment().map((item, index) => {
                                  return (
                                    <div
                                      key={"jb-crw-gtadd" + index}
                                      className="d-flex align-items-center mb-7"
                                    >
                                      <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                        {item.image ? (
                                          <div className="symbol symbol-lg-50">
                                            <img alt="Pic" src={item.image} />
                                          </div>
                                        ) : (
                                          <div className="symbol symbol-lg-50 symbol-primary">
                                            <span className="symbol-label font-size-h3 font-weight-boldest"></span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column">
                                        <a className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">
                                          {item.make + "-" + item.model}
                                        </a>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <span className="font-italic">(No items)</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <AddRemoveModal
                        id="equipment"
                        title="Equipment"
                        items={this.props.equipment.map((x) => {
                          return {
                            id: x.id,
                            display:
                              x.equipmentID + ": " + x.make + "-" + x.model,
                            value: this.state.crew.id === x.crewID,
                          };
                        })}
                        show={this.state.equipmentModalOpen}
                        toggle={() => {
                          this.setState({ equipmentModalOpen: false });
                        }}
                        onChangeHandler={this.onChangeEquipment}
                      />
                      <ConfirmModal
                        id="equipment"
                        show={this.state.overrideEquipmentCrewModal}
                        title="Move equipment"
                        body="This equipment is already associated with another Crew. Would you like to move it to this crew?"
                        yesButton={{
                          title: "Yes",
                          onClick: () => {
                            this.overrideEquipmentCrew();
                            this.setState({
                              overrideEquipmentCrewModal: false,
                            });
                          },
                        }}
                        noButton={{
                          title: "Cancel",
                          onClick: () => {
                            this.setState({
                              overrideEquipmentCrewModal: false,
                            });
                          },
                        }}
                      />
                    </>
                  </div>
                )}
              </div>
            )}

            {this.state.crew && this.state.edit && (
              <>
                <h3 className="mb-10 font-weight-bold text-dark">Crew Edit</h3>

                <div className="mb-20">
                  <BasicForm
                    {...addNewClientForm}
                    saving={this.state.savingClient}
                  />
                </div>
              </>
            )}

            {!this.state.crew && (
              <>
                <a
                  id="job-crew-title"
                  data-toggle="tooltip"
                  title="Crews are made up from Crewmembers and Equipment/tools. You can create one now or do it later."
                  data-placement="left"
                >
                  <h3 className="mb-10 font-weight-bold text-dark">Crews</h3>
                </a>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    placeholder="Search"
                  />
                </div>

                <button
                  onClick={() => this.setState({ addNewCrewShow: true })}
                  className="btn btn-block btn-light-primary font-weight-bold mb-8"
                >
                  <i className="ki ki-plus icon-md mr-2" />
                  Add New Crew
                </button>

                {this.state.addNewCrewShow && (
                  <div className="mb-20">
                    <BasicForm
                      {...addNewClientForm}
                      saving={this.state.savingClient}
                    />
                  </div>
                )}

                {this.state.loading ? (
                  <Loader height="100px" />
                ) : (
                  <div>
                    {crews.length > 0 ? (
                      <div className="navbar-nav-scroll">
                        {crews.map((crew, index) => (
                          <div
                            key={"clnts-dtls-stp" + index}
                            className="d-flex align-items-center mb-10"
                          >
                            <div
                              role="button"
                              onClick={() => {
                                this.setState({ crew: crew });
                              }}
                              className="d-flex flex-column flex-grow-1 font-weight-bold"
                            >
                              <a className="text-dark text-hover-primary mb-1 font-size-lg">
                                {crew.crewName}
                              </a>
                              <span className="text-muted">
                                {crew.available ? "Available" : "Not Available"}
                              </span>
                            </div>
                            <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0 mx-4">
                              <input
                                disabled={!crew.available}
                                type="checkbox"
                                onChange={(e) =>
                                  this.onSelectCrewChange(e, crew.id)
                                }
                                checked={this.state.selectedCrewIds.includes(
                                  crew.id
                                )}
                              />
                              <span />
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center mb-10">
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                          <div className="mainpage-graphic">
                            <CrewsIos
                              width={"200px"}
                              height={"200px"}
                              opacity={"0.3"}
                            />
                          </div>
                          <span className="font-italic">
                            You don't have any crews added to the job at the
                            moment. Click the button above to start adding them.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="justify-content-between border-top mt-5 pt-10">
                  <button
                    className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                    onClick={this.next}
                  >
                    Next
                  </button>
                  <button
                    className={this.getClass(
                      "btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                    )}
                    onClick={this.props.save}
                  >
                    Skip and Save
                  </button>
                  <Link
                    className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                    to="/jobs/new/client"
                  >
                    Back
                  </Link>
                </div>
              </>
            )}
          </>
        );
    }
}

export default CrewStep;