import React, { Component } from 'react';
import BasicForm from '../../../components/Form/BasicForm';
import { touchField, updateFormOnChange, fieldsToObject, objectToFields } from '../../../shared/FormHelper';
import { AuthContext } from "../../../shared/Auth";
import * as moment from 'moment';
import { updateBoreLog, uploadImage, getBoreLog } from "../../../shared/Api";
import { getMessages } from '../../../components/Messages/GetMessages';
import Loader from '../../../components/Layout/Loader/Loader';

class BorLogsEdit extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        addBoreLogForm: {
            name: 'edit-bore-log',
            isValid: true,
            boreLogNumber: 1,
            submitErrorMessage: null,
            boreLog: null,
            loading: false,
            saving: false,
            fields: [
                {
                    name: 'date',
                    value: '',
                    defaultValue: '',
                    type: 'static',
                    label: 'Date and Time',
                    placeholder: 'Date and Time',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: false
                },
                {
                    name: 'boreLogNumber',
                    value: '',
                    defaultValue: '',
                    type: 'static',
                    label: 'Entry No.',
                    placeholder: 'Entry No.',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: false
                },
                {
                    name: 'distance',
                    value: '',
                    defaultValue: '',
                    type: 'number',
                    dataType: 'float',
                    label: 'Distance',
                    placeholder: 'Distance',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_distance'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Distance is required field.'
                        }
                    ]
                },
                // {
                //     name: 'totalDistance',
                //     value: "",
                //     defaultValue: '',
                //     type: 'static',
                //     label: 'Total Distance Drilled',
                //     placeholder: 'Total Distance Drilled',
                //     dataType: 'float',
                //     isValid: true,
                //     validationMessage: '',
                //     isTouched: false,
                //     additionalMessage: null,
                //     required: false
                // },
                {
                    name: 'depth',
                    value: '',
                    defaultValue: '',
                    type: 'number',
                    dataType: 'float',
                    label: 'Current depth reading',
                    placeholder: 'Current depth reading',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_depth'),
                    required: false
                },
                {
                    name: 'pitch',
                    value: '',
                    defaultValue: '',
                    type: 'number',
                    dataType: 'float',
                    label: 'Current pitch reading',
                    placeholder: 'Current pitch reading',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_pitch'),
                    required: false
                },
                {
                    name: 'notes',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    rows: 4,
                    label: 'Notes (optional)',
                    placeholder: 'Notes',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_details'),
                    required: false
                },
                {
                    name: 'image',
                    value: '',
                    defaultValue: '',
                    type: 'dropzone',
                    label: 'Bore Photo',
                    placeholder: 'Bore Photo',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: '',
                    required: false,
                    localSrc: '',
                    onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
                    onRemove: () => { this.onImageRemove(); }
                }
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase py-4 mr-2 ',
                    onClick: () => this.editBoreLog(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.props.history.goBack(),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        await this.loadBoreLog();
    }

    loadBoreLog = async () => {
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const boreId = this.props.match.params.boreId;
        const boreLogId = this.props.match.params.boreLogId;

        const boreLogResult = await getBoreLog(inventoryId, boreId, boreLogId, currentUser.userProfile.companyID, true);
        const form = {...this.state.addBoreLogForm};
        const fields = objectToFields(form.fields, boreLogResult.data);

        let dateField = fields.find(x => x.name === 'date');
        if(dateField) {
            dateField.value = moment(dateField.value.toDate()).format("M/DD/YY, h:mma");
        }

        form.fields = fields;
        this.setState({ boreLog: boreLogResult.data, addBoreLogForm: form, loading: false });
    }

    onImageDrop = (acceptedFiles) => {
        let form = { ...this.state.addBoreLogForm };
        let imageField = form.fields.find(x => x.name === 'image');

        const loc = window.URL.createObjectURL(acceptedFiles[0]);

        imageField.value = acceptedFiles[0];
        imageField.localSrc = loc;
        this.setState({ newEquipmentForm: form });
    }

    onImageRemove = () => {
        let form = { ...this.state.addBoreLogForm };
        let imageField = form.fields.find(x => x.name === 'image');

        imageField.value = '';
        imageField.localSrc = '';
        this.setState({ addBoreLogForm: form });
    }

    editBoreLog = async () => {
        const form = {...this.state.addBoreLogForm};
        form.saving = true;
        this.setState({ addBoreLogForm: form });

        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const boreLogId = this.props.match.params.boreLogId;
        const boreId = this.props.match.params.boreId;
        const jobId = this.props.match.params.id;

        const boreLog = fieldsToObject(this.state.addBoreLogForm.fields);
        delete boreLog.totalDistance;
        boreLog.edited = true;
        delete boreLog.date;

        const imageField = this.state.addBoreLogForm.fields.find(x => x.name === 'image');
        if(imageField.value !== imageField.defaultValue) {
            boreLog.imageTimeStamp = imageField.value ? new Date() : null;
        }

        const updateBoreLogResult = await updateBoreLog(inventoryId, boreId, boreLogId, boreLog);

        if(imageField.value !== imageField.defaultValue && boreLog.imageTimeStamp) {
            const boreLogResult = await getBoreLog(inventoryId, boreId, boreLogId);
            await uploadImage(currentUser.userProfile.companyID, 'boreLog', boreLogId, 
                boreLogResult.data.imageTimeStamp, imageField.value);
        }

        form.saving = false;
        this.setState({ addBoreLogForm: form });
        this.props.history.goBack();
    }

    prepareAddBoreForm = () => {
        let form = this.state.addBoreLogForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.addBoreLogForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.addBoreLogForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    render() {
        if(this.state.loading)
            return <Loader />

        const addBoreForm = this.prepareAddBoreForm();

        return (
            <div className="card">
                <div className="card-body">
                    <h3 className="mb-10 font-weight-bold text-dark">Edit bore log</h3>
                    <div className="mb-20">
                        <BasicForm {...addBoreForm} />
                    </div>
                </div>
            </div>
        );
    }
}

export default BorLogsEdit;