import { db } from "../../firebase";
import { TaskEntity, TaskStatus, TaskWithTimeLogsEntity } from "../data/taskmanagement/Task";
import {
  FirebaseInventoriesCollections,
  FirebaseRootCollections,
} from "../firebase/FirebaseSchema";
import { ResponseEntity } from "../data/Response"
import { TimeLogEntity } from "../data/taskmanagement/TimeLog";
import { SegmentAndTaskEntity, SegmentEntity } from "../data/taskmanagement/Segment";
import { getJob } from "../Api";

export const deleteSegment = async (inventoryId: string, segmentId: string) => {
  return db
    .collection(FirebaseRootCollections.INVENTORIES)
    .doc(inventoryId)
    .collection(FirebaseInventoriesCollections.SEGMENTS)
    .doc(segmentId)
    .delete()
    .then(() => {
      return {
        status: "success",
      };
    })
    .catch((error: any) => {
      return {
        status: "error",
        data: error,
      };
    });
};

export const getSegmentsAndTasks = async (
  inventoryId: string,
  jobID: string
) => {
  try {
    const segmentsAndTasks: SegmentAndTaskEntity[] = []
    const jobTasks = await getTasks(inventoryId, jobID)
    for (const task of jobTasks.data ?? []) {
      if (!task.segmentID) {
        segmentsAndTasks.push(new SegmentAndTaskEntity(task))
      } else {
        const segment = await getSegment(inventoryId, task.segmentID)
        segmentsAndTasks.push(new SegmentAndTaskEntity(task, segment.data))
      }
    }
    return new ResponseEntity<SegmentAndTaskEntity[]>(segmentsAndTasks, "")

  } catch (error: any) {
    return new ResponseEntity<SegmentAndTaskEntity[]>([], error)
  }
}

export const getSegment = async (inventoryId: string, segmentId: string) => {
  try {
    const response = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.SEGMENTS)
      .doc(segmentId)
      .get()

    const segment: SegmentEntity = { ...response.data(), jobID: response?.data()?.jobID ?? "" }

    return new ResponseEntity<SegmentEntity>(segment, "")
  } catch (error: any) {
    return new ResponseEntity<SegmentEntity>(null, error)
  }
}

export const getTask = async (
  inventoryId: string,
  taskId: string,
) => {
  try {
    const request = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TASKS)
      .doc(taskId)
      .get()

    if (request?.data()?.jobID) {
      const task: TaskEntity = {
        ...request.data(),
        id: request.id,
        jobID: request?.data()?.jobID
      }
      return new ResponseEntity<TaskEntity>(task, "")
    } else {
      return new ResponseEntity<TaskEntity>(null, "Job ID is undefined")

    }

  } catch (error: any) {
    return new ResponseEntity<TaskEntity>(null, error)

  }
};

export const getBoreTask = async (
  inventoryId: string,
  boreId: string
) => {
  try {
    const request = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TASKS)
      .where("boreID", "==", boreId)
      .get()

    if (!request.empty) {
      const task: TaskEntity = {
        ...request.docs[0].data(),
        id: request.docs[0].id,
        jobID: request?.docs[0]?.data()?.jobID
      }
      return new ResponseEntity<TaskEntity>(task, "")
    } else {
      return new ResponseEntity<TaskEntity>(null, "No Task for this bore")
    }

  } catch (error: any) {
    return new ResponseEntity<TaskEntity>(null, error)

  }
};

export const getTasks = async (
  inventoryId: string,
  jobId: string,
  segmentId?: string | null
) => {
  try {
    var query = db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TASKS)
      .where("jobID", "==", jobId)
    if (segmentId) {
      query = query.where("segmentID", "==", segmentId)
    }

    const request = await query
      .get()

    const tasks: TaskEntity[] = request.docs.map((doc: any) => {
      return { ...doc.data(), id: doc.id }
    }
    )
    return new ResponseEntity<TaskEntity[]>(tasks, "")

  } catch (error: any) {
    return new ResponseEntity<TaskEntity[]>(null, error)

  }
};

export const getTasksWithTimeLogs = async (
  inventoryId: string,
  jobId: string,
  segmentId?: string | null
) => {
  try {
    const tasksWithTimeLogs : TaskWithTimeLogsEntity[] = []
    const tasks = await getTasks(inventoryId, jobId, segmentId)
    for(const task of tasks.data??[]){
      if(task.id){
      const timeLogs = await getTaskTimeLogs(inventoryId, task.id)
      tasksWithTimeLogs.push(new TaskWithTimeLogsEntity(task, timeLogs.data??[]))
      }
    }
    return new ResponseEntity<TaskWithTimeLogsEntity[]>(tasksWithTimeLogs, "")

  } catch (error: any) {
    return new ResponseEntity<TaskWithTimeLogsEntity[]>(null, error)

  }
};

export const saveTask = async (inventoryId: string, task: TaskEntity) => {
  try {
    const taskCollection = db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TASKS)

    if (!task.taskID) task.taskID = Date.now().toString();

    const { id, ...taskWithoutId } = task
    if (!task.id) {
      const response = await taskCollection.add(taskWithoutId)
      task.id = response.id
    } else {
      const response = await taskCollection.doc(task.id).update(taskWithoutId)
    }
    return new ResponseEntity<TaskEntity>(task, "")
  } catch (error: any) {
    return new ResponseEntity<TaskEntity>(task, error)
  }
};

export const deleteTask = async (inventoryId: string, taskId: string) => {
  return db
    .collection(FirebaseRootCollections.INVENTORIES)
    .doc(inventoryId)
    .collection(FirebaseInventoriesCollections.TASKS)
    .doc(taskId)
    .delete()
    .then(() => {
      return {
        status: "success",
      };
    })
    .catch((error: any) => {
      return {
        status: "error",
        data: error,
      };
    });
};


export const getTimeLog = async (inventoryId: string, timeLogId: string) => {
  try {
    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TIMELOG)
      .doc(timeLogId)
      .get()

    const timeLog: TimeLogEntity = { ...result.data(), id: result.id, taskID: result?.data()?.taskID, jobID: result?.data()?.jobID, userID: result?.data()?.userID }

    return new ResponseEntity<TimeLogEntity>(timeLog, "")
  } catch (error: any) {
    return new ResponseEntity<TimeLogEntity>(null, error)
  }
};

export const getTaskTimeLogs = async (inventoryId: string, taskId: string) => {
  try {
    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TIMELOG)
      .where("taskID", "==", taskId)
      .get()

    const timelogs = result.docs.map((doc: any) => {
      return { ...doc.data(), id: doc.id }
    }
    )

    return new ResponseEntity<TimeLogEntity[]>(timelogs, "")
  } catch (error: any) {
    return new ResponseEntity<TimeLogEntity[]>([], error)
  }
};

export const getJobTimeLogs = async (inventoryId: string, jobId: string) => {
  try {
    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TIMELOG)
      .where("jobID", "==", jobId)
      .get()

    const timelogs = result.docs.map((doc: any) => {
      return { ...doc.data(), id: doc.id }
    }
    )

    return new ResponseEntity<TimeLogEntity[]>(timelogs, "")
  } catch (error: any) {
    return new ResponseEntity<TimeLogEntity[]>([], error)
  }
};

export const saveTimeLog = async (inventoryId: string, timeLog: TimeLogEntity) => {
  try {
    const request = db.collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.TIMELOG)

    const { id, ...timeLogWithoutId } = timeLog

    if (!timeLog.id) {
      const response = await request
        .add(timeLogWithoutId);
      timeLog.id = response.id
    } else {
      await request
        .doc(timeLog.id)
        .update(timeLogWithoutId)
    }
    return new ResponseEntity<TimeLogEntity>(timeLog, "")
  } catch (error: any) {
    return new ResponseEntity<TimeLogEntity>(timeLog, error)
  }
}

export const deleteTimeLog = async (inventoryId: string, timeLogId: string) => {
  const request = await db.collection(FirebaseRootCollections.INVENTORIES)
    .doc(inventoryId)
    .collection(FirebaseInventoriesCollections.TIMELOG)
    .doc(timeLogId)
    .delete()
}

export const getNextTaskId = async (inventoryId: string, jobID: string) => {
  try {
    const job = await getJob(inventoryId, jobID) as any
    const tasks = await getTasks(inventoryId, jobID)
    //filter all the task which ID ends with jobID and does not contain only jobID in its ID
    //split task ID by "-" because of patter {number}-{jobId} and remove any trailing zeros from number part
    const taskIdSequence = tasks.data?.filter((task) => (task.taskID?.endsWith(`-${job?.data?.jobID ?? ""}`) && task.taskID != job.data.jobID))?.map((task) => (parseInt(task.taskID?.split("-")[0] ?? "", 10)))?.sort() ?? []
    const nextTaskId = taskIdSequence.length > 0 ? `${taskIdSequence[taskIdSequence.length - 1] + 1}-${job?.data?.jobID ?? ""}` : `1-${job?.data?.jobID ?? ""}`
    return new ResponseEntity<string>(nextTaskId, "")
  } catch (error: any) {
    return new ResponseEntity<string>("", error)
  }
}