import React, { Component } from "react";
import app from "../../../firebase";
import { withRouter, Redirect } from "react-router";
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import LandingScreenInfo from "../Landing/LandingInfo";

class ErrorScreen extends Component {
  render() {
    return (
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex flex-column flex-root"
      >
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-aside order-1 order-lg-1 bgi-no-repeat bgi-position-x-right">
            <div className="login-conteiner bgi-position-x-right bgi-position-y-bottom">
              <LandingScreenInfo />
            </div>
          </div>
          <div className="login-container order-2 order-lg-1 d-flex flex-center-h flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
            <div className="login-content d-flex flex-column pt-md-32 pt-12">
              <div className="pb-5 pb-lg-15">
                <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                  We are really sorry
                </h3>
                <div className="text-muted font-weight-bold font-size-h4">
                  {this.props.message}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorScreen;
