import React, { useContext, useEffect, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  ImageEntity,
  TimeLogEntity,
} from "../../../../shared/data/taskmanagement/TimeLog";
import {
  getImageUrlFromFirebase,
  parseFromFirebaseDate,
} from "../../../../shared/Util";
import { Timestamp } from "firebase/firestore";
import DropzoneInput from "../../../../components/Form/Inputs/DropzoneInput";
import { saveTask, saveTimeLog } from "../../../../shared/api/TaskManagerApi";
import { AuthContext } from "../../../../shared/Auth";
import moment from "moment";
import { uploadImage } from "../../../../shared/Api";
import { useHistory } from "react-router";
import {
  TaskEntity,
  showFootage,
  showQuantity,
  showYards,
} from "../../../../shared/data/taskmanagement/Task";
import MultipleItems from "../../../../components/Carousels/MultipleItems";
import EQMarker from "../../../../components/GoogleMap/EQMarker";
import Geosuggest from "react-geosuggest";

export interface TimeLogProps {
  timeLog: TimeLogEntity;
  task: TaskEntity;
}

export const TimeLogNew = ({ timeLog, task }: TimeLogProps) => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const [formState, setFormState] = useState({
    mapTypeId: "satellite"
  })

  const taskMemo = useMemo<TaskEntity>(() => {
    return {
      ...task,
      currentDuration: (task.currentDuration ?? 0) - (timeLog?.duration ?? 0),
      currentFilledYards:
        (task.currentFilledYards ?? 0) - (timeLog?.yards ?? 0),
      currentFootage: (task.currentFootage ?? 0) - (timeLog?.footage ?? 0),
      currentQuantity: (task.currentQuantity ?? 0) - (timeLog?.quantity ?? 0),
    };
  }, []);

  const [timeLogState, setTimeLogState] = useState<TimeLogEntity>({
    ...timeLog,
    startDate: timeLog?.startDate ?? Timestamp.fromDate(new Date()),
    lat: task.lat,
    lng: task.lng,
  });
  const [timeLogImages, setTimeLogImages] = useState<ImageEntity[]>([]);

  const save = async () => {
    const user = authContext.currentUser;
    const response = await saveTimeLog(user.company.inventoryID, timeLogState);
    //after changing/adding time log we need to update related task
    await saveTask(user.company.inventoryID, buildTask());
    if (response.data?.id) {
      const newImages = await uploadImages(user, response.data?.id);
      const timeLogWithNewImages = {
        ...timeLogState,
        imageTimeStamp: newImages.firstPhoto,
        photos: newImages.photos,
      };
      await saveTimeLog(
        user.company.inventoryID,
        timeLogWithNewImages
      );
    }
    history.goBack();
  };

  const uploadImages = async (user: any, documentId: string) => {
    //collecting current images from time log
    const currentImages = [...(timeLog?.photos ?? []), timeLog.imageTimeStamp];
    //collecting new images that need to be uploaded by comparing them with current time log images
    const newImagesToUpload = timeLogImages.filter((image) => {
      return !currentImages.some((currentImage) => {
        return currentImage?.seconds == image.value?.seconds;
      });
    });
    //uploading new images if there is any
    if (newImagesToUpload.length) {
      newImagesToUpload.forEach(async (image) => {
        if (image.file && image.value) {
          const result = await uploadImage(
            user.userProfile.companyID,
            "timeLog",
            documentId,
            image.value,
            image.file
          );
        }
      });
    }

    const photos: Timestamp[] = timeLogImages
      .map((image) => image.value)
      .filter((timestamp) => timestamp) as Timestamp[];
    const firstPhoto = [...photos][0];
    photos.shift();
    return { firstPhoto: firstPhoto, photos: photos };
  };

  const buildTask = () => {
    const task: TaskEntity = {
      ...taskMemo,
      currentDuration:
        (taskMemo?.currentDuration ?? 0) + (timeLogState?.duration ?? 0),
      currentFilledYards:
        (taskMemo?.currentFilledYards ?? 0) + (timeLogState?.yards ?? 0),
      currentFootage:
        (taskMemo?.currentFootage ?? 0) + (timeLogState?.footage ?? 0),
      currentQuantity:
        (taskMemo?.currentQuantity ?? 0) + (timeLogState?.quantity ?? 0),
      taskStatus: "inProgress",
    };
    return task;
  };

  const onImageDrop = (acceptedFiles: any) => {
    const url = window.URL.createObjectURL(acceptedFiles[0]);
    setTimeLogImages((previousState) => {
      const imageEntity: ImageEntity = {
        file: acceptedFiles[0],
        value: Timestamp.now(),
        src: url,
      };
      return [...previousState, imageEntity];
    });
  };

  const getImages = async () => {
    setTimeLogImages(() => []);
    const images = TimeLogEntity.getAllImagesUrls(timeLog);
    images.map(async (image) => {
      const result = await getImageUrlFromFirebase(
        authContext.currentUser.userProfile.companyID,
        image.src
      );
      if (result)
        setTimeLogImages((previousState) => {
          return [...previousState, { value: image.value, src: result }];
        });
    });
  };

  useEffect(() => {
    if (timeLog?.imageTimeStamp) getImages();
  }, []);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h3 className="mb-10 font-weight-bold text-dark">Time Log</h3>
          <div key="startDate" className="form-group">
            <label>Start Date and Time</label>
            <input
              type="datetime-local"
              className="form-control"
              value={parseFromFirebaseDate(
                timeLogState?.startDate ?? Timestamp.fromDate(new Date()),
                "YYYY-MM-DD HH:mm"
              )}
              onChange={(input) => {
                setTimeLogState((previousState) => {
                  return {
                    ...previousState,
                    startDate: Timestamp.fromDate(
                      moment(input.target.value).toDate()
                    ),
                  };
                });
              }}
            />
          </div>
          <div key="time-log-time-spent" className="form-group">
            <label>Time spent (h)</label>
            <input
              type="number"
              className="form-control"
              value={timeLogState?.duration ?? undefined}
              onChange={(input) => {
                setTimeLogState((previousState) => {
                  return {
                    ...previousState,
                    duration: parseFloat(input?.target?.value),
                  };
                });
              }}
            />
            <div className="form-text text-muted">
              Time spent in hours for this entry.
            </div>
          </div>
          {showFootage(task) && (
            <div key="time-log-footage-achieved" className="form-group">
              <label>Footage Achieved (ft)</label>
              <input
                type="number"
                className="form-control"
                value={timeLogState?.footage ?? undefined}
                onChange={(input) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      footage: parseFloat(input.target.value),
                    };
                  });
                }}
              />
              <div className="form-text text-muted">
                Footage achieved in feets on this time log.
              </div>
            </div>
          )}
          {showYards(task) && (
            <div key="time-log-yards-achieved" className="form-group">
              <label>Yards Achieved (yd)</label>
              <input
                type="number"
                className="form-control"
                value={timeLogState?.yards ?? undefined}
                onChange={(input) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      yards: parseInt(input.target.value),
                    };
                  });
                }}
              />
              <div className="form-text text-muted">
                Yards achieved in yards on this time log.
              </div>
            </div>
          )}
          {showQuantity(task) && (
            <div key="time-log-quantity-achieved" className="form-group">
              <label>Quantity Achieved</label>
              <input
                type="number"
                className="form-control"
                value={timeLogState?.quantity ?? undefined}
                onChange={(input) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      quantity: parseInt(input.target.value),
                    };
                  });
                }}
              />
              <div className="form-text text-muted">
                Quantity achieved on this time log.
              </div>
            </div>
          )}
          <div key="time-log-notes" className="form-group">
            <label>Notes</label>
            <textarea
              rows={3}
              className="form-control"
              value={timeLogState?.notes ?? undefined}
              onChange={(input) => {
                setTimeLogState((previousState) => {
                  return { ...previousState, notes: input.target.value };
                });
              }}
            />
            <div className="form-text text-muted">
              Detailed notes about this time log
            </div>
          </div>

          <Geosuggest
            autoActivateFirstSuggest={true}
            inputClassName="form-control"
            suggestsClassName="sug-container"
            suggestItemClassName="dropdown-item"
            initialValue={timeLogState?.address ?? ""}
            suggestItemActiveClassName="sug-item-active"
            onSuggestSelect={(result: any) => {
              setTimeLogState((previousState) => {
                return {
                  ...previousState,
                  lat: result?.location?.lat ?? 0.0,
                  long: result?.location?.lng ?? 0.0,
                  address: result?.label ?? "",
                };
              });
            }}
            autoComplete="off"
          />

          <div className="form-group mt-6"></div>

          <div style={{ height: "40vh", width: "100%" }}>
            <GoogleMapReact
              options={{
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                },
                mapTypeId: formState.mapTypeId,
              }}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
              }}
              center={{
                lat: timeLogState?.lat ?? 0,
                lng: timeLogState?.lng ?? 0,
              }}
              zoom={14}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => {
                map.addListener("click", (e: any) => {
                  setTimeLogState((previousState) => {
                    return {
                      ...previousState,
                      lat: e.latLng.lat(),
                      lng: e.latLng.lng(),
                    };
                  });
                });
                map.addListener("maptypeid_changed", () => {
                  const mapTypeId = map.getMapTypeId() ?? "satellite";
                  setFormState((previousState) => {
                    if (mapTypeId != previousState.mapTypeId)
                      return { ...previousState, mapTypeId: mapTypeId };
                    else return previousState;
                  });
                });
              }}
            >
              <EQMarker
                key={103}
                lat={timeLogState?.lat}
                lng={timeLogState?.lng}
                pinColor={"darkgreen"}
              />
            </GoogleMapReact>
          </div>

          <div className="mt-10"></div>
          <DropzoneInput
            {...{
              field: {
                onDrop: onImageDrop,
              },
            }}
          />
          <div className="mt-5 mb-5">
            <MultipleItems
              slidesToShow={9}
              laptop={7}
              tablet={5}
              mobile={2}
              images={timeLogImages?.map((timeLogImage) => timeLogImage.src)}
              removePhoto={(index: number) => {
                const images = [...timeLogImages];
                images.splice(index, 1);
                setTimeLogImages(() => {
                  return images;
                });
              }}
            />
          </div>
          <div className="justify-content-between border-top pt-10">
            <button
              key="segment-new-btn-save"
              className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2"
              disabled={false}
              onClick={(event) => {
                event.preventDefault();
                save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimeLogNew;
