import React, { Component } from 'react';
import BasicForm from '../../../components/Form/BasicForm';
import { touchField, updateFormOnChange, fieldsToObject } from '../../../shared/FormHelper';
import { AuthContext } from "../../../shared/Auth";
import * as moment from 'moment';
import { uploadImage, getBore, addBoreLog, getBoreLog, getCrewmemberByUserId, updateBore, addSoilCondition } from "../../../shared/Api";
import { getMessages } from '../../../components/Messages/GetMessages';
import Loader from '../../../components/Layout/Loader/Loader';
import { isNullOrEmpty } from '../../../shared/Utils';
import { saveTimeLog } from '../../../shared/api/TaskManagerApi';

class BorLogsNew extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        addBoreLogForm: {
            name: 'add-new-bore-log',
            isValid: false,
            boreLogNumber: 1,
            submitErrorMessage: null,
            boreLogs: [],
            loading: false,
            saving: false,
            fields: [
                {
                    name: 'dateAndTime',
                    value: moment().format("M/DD/YY, h:mma"),
                    defaultValue: '',
                    type: 'static',
                    label: 'Date and Time',
                    placeholder: 'Date and Time',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: false
                },
                {
                    name: 'boreLogNumber',
                    value: '',
                    defaultValue: '',
                    type: 'static',
                    label: 'Entry No.',
                    placeholder: 'Entry No.',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: false
                },
                {
                    name: 'distance',
                    value: '',
                    defaultValue: '',
                    type: 'number',
                    dataType: 'float',
                    label: 'Distance',
                    placeholder: 'Distance',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_distance'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Distance is required field.'
                        }
                    ]
                },
                {
                    name: 'totalDistance',
                    value: "",
                    defaultValue: '',
                    type: 'static',
                    label: 'Total Distance Drilled',
                    placeholder: 'Total Distance Drilled',
                    dataType: 'float',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: null,
                    required: false
                },
                {
                    name: 'location',
                    value: {
                        lat:40.7127753,
                        long: -74.0059728
                    },
                    defaultValue: '',
                    type: 'location',
                    label: 'Bore Location',
                    placeholder: 'Location',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: "The location for this bore.",
                    onSuggest: (e) => this.onSuggestSelect(e),
                    setLoc: (e) => this.setLocation(e),
                },
                {
                    name: 'soilConditions',
                    value: 'Caliche',
                    defaultValue: 'None',
                    type: 'select',
                    label: 'Soil Conditions(optional)',
                    placeholder: 'Soil Conditions',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: false,
                    allowCustom: true,
                    custom: "Other",
                    customValue: "",
                    options: [
                        {
                            value: '',
                            display: 'None'
                        },
                        {
                            value: 'Caliche',
                            display: 'Caliche'
                        },
                        {
                            value: 'Clay',
                            display: 'Clay'
                        },
                        {
                            value: 'Cobbles',
                            display: 'Cobbles'
                        },
                        {
                            value: 'Dirt',
                            display: 'Dirt'
                        },
                        {
                            value: 'Gravel',
                            display: 'Gravel'
                        },
                        {
                            value: 'Hardpan',
                            display: 'Hardpan'
                        },
                        {
                            value: 'Sand',
                            display: 'Sand'
                        },
                        {
                            value: 'Sandstone',
                            display: 'Sandstone'
                        },
                        {
                            value: 'Shale',
                            display: 'Shale'
                        }
                    ],
                },
                {
                    name: 'publicEntry',
                    value: false,
                    defaultValue: false,
                    type: 'checkbox',
                    label: 'Public Entry',
                    placeholder: 'Public Entry',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: "Make soil condition for this location public to Equappment community. Your personal data will not be shared.",
                    required: false
                },
                {
                    name: 'depth',
                    value: 0,
                    defaultValue: 0,
                    type: 'number',
                    dataType: 'float',
                    label: 'Current depth reading',
                    placeholder: 'Current depth reading',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_depth'),
                    required: false
                },
                {
                    name: 'pitch',
                    value: 0,
                    defaultValue: 0,
                    type: 'number',
                    dataType: 'float',
                    label: 'Current pitch reading',
                    placeholder: 'Current pitch reading',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_pitch'),
                    required: false
                },
                {
                    name: 'notes',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    rows: 4,
                    label: 'Notes (optional)',
                    placeholder: 'Notes',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('bore_details'),
                    required: false
                },
                {
                    name: 'image',
                    value: '',
                    defaultValue: '',
                    type: 'dropzone',
                    label: 'Bore Photo',
                    placeholder: 'Bore Photo',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: '',
                    required: false,
                    localSrc: '',
                    onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
                    onRemove: () => { this.onImageRemove(); }
                }
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase py-4 mr-2 ',
                    onClick: () => this.addBore(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.props.history.goBack(),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        await this.updateNumber();
    }

    updateNumber = async () => {
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const boreId = this.props.match.params.boreId;
        const addBoreLogForm = this.state.addBoreLogForm;
        let fields = [...addBoreLogForm.fields];
        let locationField = fields.find(x => x.name === 'location');

        const boreResult = await getBore(inventoryId, boreId);
        const boreData = boreResult.data;        
        const logs = boreResult.data.boreLogs;

        locationField.value = {
            lat: boreData.lat,
            long: boreData.long
        };

        locationField.defaultValue = {
            lat: boreData.lat,
            long: boreData.long
        }

        this.setState({addBoreLogForm: addBoreLogForm});

        let maxBoreLogNumber = 0;
        let distance = 0;

        logs.forEach(item => {
            if(item.boreLogNumber > maxBoreLogNumber) {
                maxBoreLogNumber = item.boreLogNumber;
                distance += item.distance;
            }
        });

        maxBoreLogNumber = parseInt(maxBoreLogNumber) + 1;

        const form = {...this.state.addBoreLogForm};
        const entryNumberField = form.fields.find(x => x.name === 'boreLogNumber');
        if(entryNumberField) {
            entryNumberField.value = "#" + ('000' + maxBoreLogNumber).substr(-3);
        }

        const totalDistanceField = form.fields.find(x => x.name === 'totalDistance');
        if(totalDistanceField) {
            totalDistanceField.value = parseInt(distance) + " ft";
        }

        this.setState({ boreLogs: boreResult.data.boreLogs, boreLogNumber: maxBoreLogNumber, loading: false });
    }

    onSuggestSelect = (suggest) => {
        let form = this.state.addBoreLogForm;
        let fields = [...form.fields];

        let locationField = fields.find(x => x.name === 'location');

        if(suggest && suggest.location) {
            locationField.value = {
                lat: suggest.location.lat,
                long: suggest.location.lng
            }
            this.setState({ addBoreLogForm: form });
        }
    } 

     shallowEqual = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
          return false;
        }
        for (let key of keys1) {
          if (object1[key] !== object2[key]) {
            return false;
          }
        }
        return true;
      }
    setLocation = (coords) => {
        
        let form = this.state.addBoreLogForm;
        let fields = [...form.fields];
        
        let locationField = fields.find(x => x.name === 'location');
        
        locationField.value = {
            lat: coords.lat,
            long: coords.lng
        }
        
        this.setState({ addBoreLogForm: form });
    }   

    isValid = () => {
        const form = this.state.addBoreLogForm;
        return form ? form.isValid : false;
    }

    onImageDrop = (acceptedFiles) => {
        let form = { ...this.state.addBoreLogForm };
        let imageField = form.fields.find(x => x.name === 'image');

        const loc = window.URL.createObjectURL(acceptedFiles[0]);

        imageField.value = acceptedFiles[0];
        imageField.localSrc = loc;
        this.setState({ newEquipmentForm: form });
    }

    onImageRemove = () => {
        let form = { ...this.state.addBoreLogForm };
        let imageField = form.fields.find(x => x.name === 'image');

        imageField.value = '';
        imageField.localSrc = '';
        this.setState({ addBoreLogForm: form });
    }

    addBore = async () => {
        const form = {...this.state.addBoreLogForm};
        form.saving = true;
        this.setState({ addBoreLogForm: form });

        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const jobId = this.props.match.params.id;
        const boreId = this.props.match.params.boreId;
        const locationField = form.fields.find(x=> x.name === 'location');

        const boreResult = await getBore(inventoryId, boreId);

        const date = new Date();
        const crewmember = await getCrewmemberByUserId(inventoryId, currentUser.uid);
        
        const boreLog = fieldsToObject(this.state.addBoreLogForm.fields);
        const location = boreLog.location;
        
        delete boreLog.location;
        
        if(!this.shallowEqual(location, locationField.defaultValue)) {
            const boreResult = await updateBore(inventoryId, boreId, location);
        };

        if(boreLog.soilConditions) {
            const soilConditionsObj = {
                boreId: boreId,
                companyId: currentUser.userProfile.companyID,
                createdAt: new Date(),
                depth: boreLog.depth ? boreLog.depth : 0,
                lat: location.lat,
                long: location.long,
                publicEntry: boreLog.publicEntry,
                soilConditions: boreLog.soilConditions,
                userId: currentUser.uid,
                validatedBy: [currentUser.uid]
            }
            const soilConditionResult = await addSoilCondition(soilConditionsObj);
        }


        delete boreLog.dateAndTime;
        delete boreLog.totalDistance;
        boreLog.date = date;
        boreLog.edited = false;
        boreLog.boreLogNumber = this.state.boreLogNumber;
        boreLog.jobBoreID = boreId;
        boreLog.userID = crewmember.data.id;
        boreLog.lat = location.lat;
        boreLog.long = location.long;
        boreLog.strippedDate = moment(date).format("M/DD/YY");

        const imageField = this.state.addBoreLogForm.fields.find(x => x.name === 'image');
        if(imageField.value) {
            boreLog.imageTimeStamp = new Date();
        }

        const addBoreLogResult = await addBoreLog(inventoryId, boreId, boreLog);
        const boreLogResult = await getBoreLog(inventoryId, boreId, addBoreLogResult.data);

        if (!isNullOrEmpty(boreResult.data.taskID)) {
          const timeLog = {
            jobID: boreResult.data.jobID,
            taskID: boreResult.data.taskID,
            lat: boreLog.lat ?? null,
            lng: boreLog.long ?? null,
            startDate: boreLog.date,
            userID: boreLog.userID,
            notes: boreLog?.notes ?? "",
            footage: boreLog.distance ?? null,
            imageTimeStamp: null,
            duration: 8,
            address: boreLog.address ?? null,
            crewmember: null,
            crews: boreResult.data.crewID,
            yards: null,
            quantity: null,
            photos: null,
            equipment: null,
          };
          await saveTimeLog(inventoryId, timeLog);
        }

        if(imageField.value) {
            await uploadImage(currentUser.userProfile.companyID, 'boreLog', addBoreLogResult.data, 
                boreLogResult.data.imageTimeStamp, imageField.value);
        }

        form.saving = false;
        this.setState({ addBoreLogForm: form });
        this.props.history.push('/jobs/' + jobId + '/bores/' + boreId);
    }

    prepareAddBoreForm = () => {
        let form = this.state.addBoreLogForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.addBoreLogForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.addBoreLogForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    render() {
        if(this.state.loading)
            return <Loader />

        const addBoreForm = this.prepareAddBoreForm();

        return (
            <div className="card">
                <div className="card-body">
                    <h3 className="mb-10 font-weight-bold text-dark">Add new bore log</h3>
                    <div className="mb-20">
                        <BasicForm {...addBoreForm} />
                    </div>
                </div>
            </div>
        );
    }
}

export default BorLogsNew;