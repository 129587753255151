import React, { Component } from 'react';
import { getEquipment, addEquipment, getSingleEquipment, uploadImage, getReservations } from '../../../../shared/Api';
import { resetFormValues, touchField, updateFormOnChange, getSubmitClass, fieldsToObject } from '../../../../shared/FormHelper';
import { AuthContext } from "../../../../shared/Auth";
import BasicForm from '../../../../components/Form/BasicForm';
import Loader from '../../../../components/Layout/Loader/Loader';
import { Link } from 'react-router-dom';
import { getMessages } from '../../../../components/Messages/GetMessages';
import { EquipmentIos } from '../../../../components/Icons/Svg';

class EquipmentStep extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        savingEquipment: false,
        equipment: [],
        addEquipmentShow: false,
        search: '',
        selectedEquipment: this.props.obj.equipment ? this.props.obj.equipment : [],
        newEquipmentForm: {
            name: 'add-new-equipment',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            fields: [
                {
                    name: 'equipmentID',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Equipment Id',
                    placeholder: 'Equipment Id',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_identifier'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('equipment_id_required')
                        }
                    ]
                },
                {
                    name: 'make',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Equipment Make',
                    placeholder: 'Equipment Make',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_make'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('equipment_make_required')
                        }
                    ]
                },
                {
                    name: 'model',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Equipment Model',
                    placeholder: 'Equipment Model',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_model'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('equipment_model_required')
                        }
                    ]
                },
                {
                    name: 'serialNumber',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Serial Number (optional)',
                    placeholder: 'Serial Number',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_serial'),
                    required: false
                },
                {
                    name: 'year',
                    value: '',
                    defaultValue: '',
                    type: 'number',
                    label: 'Year (optional)',
                    placeholder: 'Year',
                    min: 0,
                    max: 2050,
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_year'),
                    required: false,
                    validators: [
                        {
                            type: 'numeric',
                            message: getMessages('year_span')
                        },
                        {
                            type: 'min',
                            value: 0,
                            message: getMessages('year_span')
                        },
                        {
                            type: 'max',
                            value: 2050,
                            message: getMessages('year_span')
                        }
                    ]
                },
                {
                    name: 'tag',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Tag (optional)',
                    placeholder: 'Tag',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_tag'),
                    required: false
                },
                {
                    name: 'description',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    label: 'Equipment Description',
                    placeholder: 'Equipment Description',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_description'),
                    required: false,
                    rows: 4
                },
                {
                    name: 'image',
                    value: '',
                    defaultValue: '',
                    type: 'dropzone',
                    label: 'Equipment Photo',
                    placeholder: 'Equipment Photo',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: '',
                    required: false,
                    localSrc: '',
                    onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
                    onRemove: () => { this.onImageRemove(); }
                }
            ],
            buttons: [
                {
                    title: 'Add Equipment',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addEquipment(),
                    disabled: () => false
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.closeAddNewEquipmentForm(),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        
        this.props.setStep('equipment');
        await this.loadEquipment();
        await this.loadSelectedEquipment();
        
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-equipment-title"]').tooltip({ trigger: 'manual' });
            window.$('[id="job-equipment-title"]').tooltip('hide');
            window.$('[id="job-equipment-title"]').tooltip('show');
            document.getElementById("skip-tutorial-button").style.display = "block";
        }
    }

    componentWillUnmount() {
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-equipment-title"]').tooltip('hide');
            document.getElementById("skip-tutorial-button").style.display = "none";
        }
    }

    loadEquipment = async () => {
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const companyId = currentUser.userProfile.companyID;
        const inventoryId = currentUser.company.inventoryID;

        const equipment = await getEquipment(inventoryId, companyId, true);
        const reservations = await getReservations();

        equipment.data.forEach(x => {
            x.available = !reservations.data.map(x => x.equipmentID).includes(x.id);
        });

        this.setState({ equipment: equipment.data, loading: false });
    }

    loadSelectedEquipment = async () => {
        this.setState({ selectedEquipment: this.props.obj.equipment ? this.props.obj.equipment  : [] });
    }

    getReservations = async () => {
        const reservations = await getReservations();

        const notAvailable = reservations.data.filter(x => this.isBetweenDates(x.startDate, this.props.obj.job.startDate, this.props.obj.job.endDate)
            || this.isBetweenDates(x.endDate, this.props.obj.job.startDate, this.props.obj.job.endDate));

        return notAvailable;
    }

    isBetweenDates = (check, from, to) => {
        return check.getTime() <= to.getTime() && check.getTime() >= from.getTime();
    }

    getClass = (className) => {
        if(this.props.saving)
            className += " spinner spinner-white spinner-right";

        return className;
    }

    onImageDrop = (acceptedFiles) => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        const loc = window.URL.createObjectURL(acceptedFiles[0]);

        imageField.value = acceptedFiles[0];
        imageField.localSrc = loc;
        this.setState({ newEquipmentForm: form });
    }

    selectEquipment = (equipmentID) => {
        let selectedEquipment = [...this.state.selectedEquipment];
        var index = selectedEquipment.indexOf(equipmentID);
        if (index !== -1) {
            selectedEquipment.splice(index, 1);
        } else {
            selectedEquipment.push(equipmentID);
        }

        this.setState({ selectedEquipment: selectedEquipment });
    }

    onImageRemove = () => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        imageField.value = '';
        imageField.localSrc = '';
        this.setState({ newEquipmentForm: form });
    }

    addEquipment = async () => {
        this.setState({savingEquipment: true})
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const companyId = currentUser.userProfile.companyID;
        const equipment = fieldsToObject(this.state.newEquipmentForm.fields);

        const imageField = this.state.newEquipmentForm.fields.find(x => x.name === 'image');
        if (imageField.value) {
            equipment.imageTimeStamp = new Date();
            equipment.hasProfileImage = true;
        }

        const eq = await addEquipment(inventoryId, companyId, equipment);

        if (imageField.value) {
            const uploadedEquipment = await getSingleEquipment(inventoryId, eq.data);
            await uploadImage(companyId, 'equipment', eq.data, uploadedEquipment.data.imageTimeStamp, imageField.value)
        }

        this.closeAddNewEquipmentForm();
        this.setState({savingEquipment: false})
        this.loadEquipment();
    }

    next = () => {
        this.props.setJobEquipment(this.state.selectedEquipment);
        this.props.history.push('/jobs/new/materials');
    }

    closeAddNewEquipmentForm = () => {
        let updatedForm = { ...this.state.newEquipmentForm };
        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            newEquipmentForm: updatedForm,
            addEquipmentShow: false
        });
    }

    prepareAddEquipmentForm = () => {
        let form = this.state.newEquipmentForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    equipment = () => {

         const sortedEquipment = this.state.equipment.sort((a, b) => {
          if (
            this.state?.selectedEquipment?.includes(a.id) &&
            !this.props.unavailableEquipment.includes(a.id) &&
            !this.state?.selectedEquipment?.includes(b.id)
          )
            return -1;
          else if (
            this.state?.selectedEquipment?.includes(b.id) &&
            !this.props.unavailableEquipment.includes(b.id) &&
            !this.state?.selectedEquipment?.includes(a.id) 
          )
            return 1;
          else return 0;
        });

        if (!this.state.search)
            return sortedEquipment;

        const searchWords = this.state.search.trim().split(/\s+/).map(word=>word.toLowerCase())

        const searchResult = sortedEquipment.filter((equipment)=>{
            return searchWords.some((word)=>equipment.make.toLowerCase().includes(word)) ||
            searchWords.some((word)=>equipment.model.toString().toLowerCase().includes(word)) ||
            searchWords.some((word)=>equipment.subCategory?.toString()?.toLowerCase()?.includes(word)) 
        })

        return searchResult
    }

    render() {
        const addEquipmentForm = this.prepareAddEquipmentForm();
        const equipment = this.equipment();

        return (
            <>
                <a id="job-equipment-title" data-toggle="tooltip" title="Select all the equipment and tools you need to have on this job.There will be an indication if it’s already booked."
                    data-placement="left">
                    <h3 className="mb-10 font-weight-bold text-dark">Equipment</h3>
                </a>

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        onChange={(e) => { this.setState({ search: e.target.value }); }}
                        placeholder="Search Equipment" />
                </div>

                <button onClick={() => this.setState({ addEquipmentShow: true })}
                    className="btn btn-block btn-light-primary font-weight-bold mb-8">
                    <i className="ki ki-plus icon-md mr-2" />Add New Equipment</button>

                {this.state.addEquipmentShow &&
                    <div className="mb-20">
                        <BasicForm {...addEquipmentForm} saving={this.state.savingEquipment}/>
                    </div>
                }

                {this.state.loading
                    ?
                    <Loader height="100px" />
                    :
                    <div>
                        {equipment.length > 0 ?
                            <div className='navbar-nav-scroll'>
                                {equipment.map((equipment,index) => (
                                    <div key={"eqmnt-stp"+index} className="d-flex align-items-center mb-10">
                                        <div className="d-flex align-items-center">

                                            <div className="symbol symbol-100 symbol-sm flex-shrink-0">
                                                {equipment.image ?
                                                    <img className="" src={equipment.image} alt="photo" />
                                                    : <span className="symbol-label"></span>}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
                                            <a className="text-dark text-hover-primary mb-1 font-size-lg">
                                                {equipment.make}
                                            </a>
                                            <span className="text-muted">{equipment.equipmentID}</span>
                                            <span className="text-muted">{equipment.model}</span>
                                            <span className="text-muted">{!this.props.unavailableEquipment.includes(equipment.id) ? "Available" : "Not Available"}</span>
                                        </div>
                                        <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0 mx-4">
                                            <input disabled={this.props.unavailableEquipment.includes(equipment.id)}
                                                onChange={() => this.selectEquipment(equipment.id)}
                                                checked={this.state.selectedEquipment.includes(equipment.id) 
                                                    && !this.props.unavailableEquipment.includes(equipment.id)}
                                                type="checkbox" defaultValue={1} />
                                            <span />
                                        </label>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className="d-flex align-items-center mb-10">
                                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                <div className="mainpage-graphic">
                                    <EquipmentIos width={'200px'} height={'200px'} opacity={'0.3'}/>
                                </div>
                                    <span className="font-italic">You don't have any equipment added to the job at the moment. Click the button above to start adding.</span>
                                </div>
                            </div>
                            }
                        <div className="justify-content-between border-top mt-5 pt-10">
                            <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                                onClick={this.next}>Next</button>
                            <button className={this.getClass("btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2")}
                                onClick={this.props.save}
                            >Skip and Save</button>
                            <Link className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                                to='/jobs/new/crews'>Back</Link>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default EquipmentStep;