import React, { Component } from "react";
import app from "../../../firebase";
import { withRouter, Redirect } from "react-router";
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import { getMessages } from "../../../components/Messages/GetMessages";
import LandingScreenInfo from "../Landing/LandingInfo";

class Signin extends Component {
  state = {
    email: "",
    submitError: false,
    submitErrorMessage: "",
    loading: false,
    emailSent: false,
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    try {
      await app.auth().sendPasswordResetEmail(this.state.email);
      this.setState({ loading: false, emailSent: true });
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        this.setState({
          submitError: true,
          submitErrorMessage: getMessages("cannot_email"),
        });
      } else if (error.code === "auth/invalid-email") {
        this.setState({
          submitError: true,
          submitErrorMessage: getMessages("invalid_email"),
        });
      } else {
        this.setState({
          submitError: true,
          submitErrorMessage: getMessages("something_wrong"),
        });
      }

      this.setState({ loading: false });
    }
  };

  handleChange = (event) => {
    let updatedState = { ...this.state };
    updatedState[event.target.name] = event.target.value;

    this.setState(updatedState);
  };

  render() {
    const submitBtnClasses =
      "btn btn-block btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3 rounded-0";
    const submitBtnClassesWithError = this.state.submitError
      ? submitBtnClasses + " is-invalid"
      : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading
      ? submitBtnClassesWithError + " spinner spinner-white spinner-right"
      : submitBtnClassesWithError;

    return (
      <div className="d-flex flex-column flex-root">
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-content d-flex flex-column pt-md-32 pt-12">
            {this.state.emailSent ? (
              <React.Fragment>
                <div className="pb-5 pb-lg-15">
                  <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                    We sent a link to your email. You may need to check your
                    Junk or Spam folder.
                  </h3>
                  <div className="text-muted font-weight-bold font-size-h4 mt-6">
                    Go back to <Link to="/signin">Sign In</Link>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="pb-5 pb-lg-15">
                  <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                    Don't worry. We've got you covered
                  </h3>
                  <div className="text-muted font-weight-bold font-size-h4">
                    Enter the email you use for Equappment, and we’ll help you
                    create a new password.
                  </div>
                </div>
                <div className="login-form">
                  <form
                    onSubmit={this.handleSubmit}
                    className="form"
                    id="kt_login_singin_form"
                    action="POST"
                    noValidate="novalidate"
                  >
                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">
                        Email
                      </label>
                      <input
                        autoFocus={true}
                        value={this.state.email}
                        className="form-control form-control-solid input-h-over py-7 px-6 rounded-0 border-0"
                        type="email"
                        name="email"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <button
                          type="submit"
                          id="kt_login_singin_form_submit_button"
                          className={submitBtnClassesFinal}
                          disabled={!this.state.email}
                        >
                          Send recovery email
                        </button>
                        <div className="invalid-feedback justify-content-center">
                          {this.state.submitErrorMessage}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Signin);
