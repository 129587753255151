import { Timestamp } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  LocateTicketStatusEntity,
  LocateTicketStatusType,
} from "../../shared/data/LocateTickets";

interface LocateTicketStatusModalProp {
  formId:string;
  show: boolean;
  locateTicketStatus: LocateTicketStatusEntity | null;
  onCancel?: () => void;
  onConfirm?: (
    status: LocateTicketStatusEntity | null,
    revisionId: string | null,
    expirationDate: Timestamp | null
  ) => void;
}

const LocateTicketStatusModal = ({
  formId,
  show = false,
  locateTicketStatus = null,
  onCancel = () => {},
  onConfirm = () => {},
}: LocateTicketStatusModalProp) => {
  const [newStatus, setNewStatus] = useState<LocateTicketStatusEntity>({
    userID: locateTicketStatus?.userID,
    userName: locateTicketStatus?.userName,
  });

  const [revisionId, setRevisionId] = useState<string | null>(null)

  const [expirationDate, setExpirationDate] = useState<Timestamp | null>(null)

  const hiddenBtnRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (show) hiddenBtnRef?.current?.click();
  }, [show]);

  const buildDialog = (status: LocateTicketStatusType | null) => {
    switch (status) {
      case "Ready to submit":
        return (
          <div>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="locate-ticket-status-dialog-header"
                  >
                    Change to submitted
                  </h5>
                </div>
                <div className="modal-body">
                  <div>
                    <label className="mr-10">Select Submit Date</label>
                    <input
                      className=" mr-10 form-control"
                      type="date"
                      onChange={(input) => {
                        setNewStatus((previousState) => {
                          return {
                            ...previousState,
                            createdAt: Timestamp.now(),
                            date: Timestamp.fromDate(
                              moment(input.target.value).toDate()
                            ),
                            type: "submitted",
                          };
                        });
                      }}
                    ></input>
                  </div>
                </div>{" "}
                <div className="modal-footer">
                  <button
                    onClick={() => onCancel()}
                    type="button"
                    className="btn btn-light-secondary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      if (newStatus) onConfirm(newStatus, null, null);
                    }}
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    data-dismiss="modal"
                    disabled={
                      newStatus.createdAt === null || newStatus.date == null
                    }
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>
            ;
          </div>
        );
      case "submitted":
        return (
          <div>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="locate-ticket-status-dialog-header"
                  >
                    Change to approved
                  </h5>
                </div>
                <div className="modal-body">
                  <div>
                    <label className="mr-10">Select Approved date</label>
                    <input
                      className=" mr-10 form-control"
                      type="date"
                      onChange={(input) => {
                        setNewStatus((previousState) => {
                          return {
                            ...previousState,
                            createdAt: Timestamp.now(),
                            date: Timestamp.fromDate(
                              moment(input.target.value).toDate()
                            ),
                            type: "approved",
                          };
                        });
                      }}
                    ></input>
                  </div>
                  <div>
                    <label className="mr-10">Select Expiration Date</label>
                    <input
                      className=" mr-10 form-control"
                      type="date"
                      onChange={(input) => {
                        setExpirationDate(
                          Timestamp.fromDate(moment(input.target.value).toDate())
                        )
                      }}
                    ></input>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => onCancel()}
                    type="button"
                    className="btn btn-light-secondary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      newStatus.createdAt === null || newStatus.date == null || expirationDate == null
                    }
                    onClick={() => {
                      if (newStatus) onConfirm(newStatus, null, expirationDate);
                    }}
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>
            ;
          </div>
        );
      case "expired":
        return (
          <div>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="locate-ticket-status-dialog-header"
                  >
                    Resubmit locale ticket and change status to "submitted"
                  </h5>
                </div>
                <div className="modal-body">
                  <div>
                    <label className="mr-10">Enter Submit Date</label>
                    <input
                      className=" mr-10 form-control"
                      type="date"
                      onChange={(input) => {
                        setNewStatus((previousState) => {
                          return {
                            ...previousState,
                            createdAt: Timestamp.now(),
                            date: Timestamp.fromDate(
                              moment(input.target.value).toDate()
                            ),
                            type: "submitted",
                          };
                        });
                      }}
                    ></input>
                  </div>
                  <div>
                    <label className="mr-10">Enter Revision #</label>
                    <input
                      className=" mr-10 form-control"
                      type="input"
                      value={revisionId ?? ""}
                      onChange={(input) => {
                        setRevisionId(input.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => onCancel()}
                    type="button"
                    className="btn btn-light-secondary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      newStatus.createdAt === null || revisionId == null
                    }
                    onClick={() => {
                      if (newStatus) onConfirm(newStatus, revisionId, null);
                    }}
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {show ? (
        <div>
          <div
            className="modal fade"
            id={`${formId}`}
            data-backdrop="static"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden={false}
          >
            {buildDialog(locateTicketStatus?.type ?? null)}
          </div>
          <button
            style={{ display: "none" }}
            ref={hiddenBtnRef}
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target={`#${formId}`}
          ></button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default LocateTicketStatusModal;
