import React, { useContext, useEffect, useState } from "react";
import BoresNewMap from "./BoresNewMap";
import EQInputText from "../../../components/Form/Inputs/EQInputText";
import EQDatePicker from "../../../components/Form/Inputs/EQDatePicker";
import EQSelect, {
  EQSelectItem,
} from "../../../components/Form/Inputs/EQSelect";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { getMessages } from "../../../components/Messages/GetMessages";
import {
  GroundConditions,
  TaskProductDataType,
  TaskProductEntity,
  TaskProductType,
} from "../../../shared/data/taskmanagement/Task";
import { isNullOrEmpty } from "../../../shared/Utils";
import { useHistory, useParams } from "react-router";
import { AuthContext } from "../../../shared/Auth";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import {
  addBore,
  getBores,
  getCrewAppointments,
  getCrews,
  getJob,
  getSegments,
  updateBore,
} from "../../../shared/Api";
import TaskProduct from "../TaskManagement/Task/TaskProduct";
import {
  parseFromFirebaseDate,
  showToast,
  toFirebaseDate,
} from "../../../shared/Util";
import { BoreEntity } from "../../../shared/data/bore/BoreEntity";
import { JobEntity } from "../../../shared/data/job/Job";
import { Location } from "../../../shared/data/Location";
import EQCheckBox from "../../../components/Form/Inputs/EQCheckBox";

interface BoresNewProps {
  boreEntity?: BoreEntity;
}

const BoresNew = ({ boreEntity }: BoresNewProps) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const context: ContextEntity = useContext(AuthContext);
  const [bore, setBore] = useState<BoreEntity>(boreEntity ?? new BoreEntity());
  const [formState, setFormState] = useState<any>({
    isSaving: false,
    showAddTaskConfirmationDialog: false,
    segments: [],
    crews: [],
    job: null,
  });

  const getSoilConditionItems = () => {
    return Object.keys(GroundConditions).map((key) => {
      const value = GroundConditions[key as keyof typeof GroundConditions];
      return { key: key, value: value };
    });
  };

  const getAvailableCrews = () => {
    const items: EQSelectItem[] = formState.crews.map((crew: any) => {
      return { key: crew.id, value: crew.crewName };
    });
    return items;
  };

  //this is used for transforming old product format to new product list
  const prepareOldProduct = () => {
    if (!isNullOrEmpty(bore.productPipe)) {
      const productType =
        (Object.entries(TaskProductType).find((productType) => {
          return productType[1] == bore.productPipe;
        })?.[1] as TaskProductDataType) ?? TaskProductType.none;

      const product = new TaskProductEntity(
        productType,
        bore.boreDiameter?.toString(),
        "1"
      );

      const products = [...(bore.products ?? [])];
      products.push(product);

      setBore((previousState) => {
        return {
          ...previousState,
          products: products,
          productPipe: null,
          boreDiameter: null,
        };
      });
    }
  };

  const canSave = () => {
    const hasProductWithMissingInfo = bore.products?.some((product)=>{
      return !product.quantity  || !product.type  || !product.size
    })
    return (
      !isNullOrEmpty(bore?.boreName) &&
      !isNullOrEmpty(bore?.boreLength?.toString()) &&
      !isNullOrEmpty(bore.startDate?.toString()) &&
      !hasProductWithMissingInfo
    );
  };

  const saveBore = async (addTask: boolean) => {
    var result;
    if (!bore.id) {
      result = await addBore(
        context.currentUser?.company?.inventoryID,
        bore,
        addTask
      );
    } else {
      const { id, ...boreWithoutId } = bore;
      result = await updateBore(
        context.currentUser?.company?.inventoryID,
        bore.id,
        boreWithoutId
      );
    }

    if (result.status == "error") showToast("danger", "Could not save bore");
    else history.goBack();
  };

  useEffect(() => {
    loadSegments();
    loadBores();
    loadCrews();
    loadJob();
    prepareOldProduct();
  }, []);

  const loadSegments = async () => {
    const segments = await getSegments(
      context.currentUser?.company?.inventoryID,
      params.id
    );
    setFormState((previousState: any) => {
      return { ...previousState, segments: segments.data };
    });
  };

  const loadBores = async () => {
    if (isNullOrEmpty(boreEntity?.boreNumber?.toString())) {
      const bores = await getBores(
        context.currentUser?.company?.inventoryID,
        params.id
      );
      setBoreNumber(bores.data as BoreEntity[]);
    }
  };

  const loadCrews = async () => {
    const crews = (await getCrews(context.currentUser?.company?.inventoryID))
      .data as any[];
    const crewAppointments = (
      await getCrewAppointments(context.currentUser?.company?.inventoryID)
    ).data as any[];
    const jobCrewAppointmentId = crewAppointments
      ?.filter((crewAppointment: any) => {
        return crewAppointment.jobID == params.id;
      })
      .map((crewAppointment: any) => crewAppointment.crewID);

    const availableCrews = crews.filter((crew: any) =>
      jobCrewAppointmentId.includes(crew.id)
    );
    setFormState((previousState: any) => {
      return { ...previousState, crews: availableCrews };
    });
  };

  const loadJob = async () => {
    const job:JobEntity = (
      await getJob(context.currentUser?.company?.inventoryID, params.id)
    ).data as JobEntity;
    setFormState((previousState: any) => {
      return { ...previousState, job: job };
    });
    setBore((previousState)=>{
      return {...previousState, jobID:job?.id}
    })
  };

  const setBoreNumber = (bores: BoreEntity[]) => {
    const highestNumber =
      bores.length > 0
        ? bores.reduce(
            (max, item) =>
              (item.boreNumber ?? 0) > (max ?? 0) ? item.boreNumber : max,
            bores[0].boreNumber
          )
        : 0;
    setBore((previousState) => {
      return { ...previousState, boreNumber: (highestNumber ?? 0) + 1 };
    });
  };

  const showCreateTaskDialog = () => {
    setFormState((previousState: any) => {
      return { ...previousState, showAddTaskConfirmationDialog: true };
    });
  };

  const getBoreCurrentLocation = ()=>{
    const lng = bore.lng??bore.long
    if(!bore.lat || !lng){
      return null
    }else{
      return new Location(bore.lat, lng, bore.address)
    }
  }

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div key="taskMap" className="form-group">
            <h3 className="mb-10 font-weight-bold text-dark">Add new bore</h3>
            <div className="mb-20">
              <div className="pb-5">
                <BoresNewMap
                  segments={formState.segments}
                  boreCurrentLocation={getBoreCurrentLocation()}
                  onLocationAdded={(location, segmentId) => {
                    setBore((previousState: any) => {
                      return {
                        ...previousState,
                        lat: location?.lat,
                        long: location?.lng,
                        lng: location?.lng,
                        segmentID: segmentId??null,
                      };
                    });
                  }}
                />
              </div>
              <EQInputText
                title="Bore Name"
                required={true}
                hint="Use a unique name to differentiate this bore from others."
                inputText={bore?.boreName ?? ""}
                onOnInputChange={(value) => {
                  setBore((previousState: any) => {
                    return { ...previousState, boreName: value };
                  });
                }}
              />
              <EQDatePicker
                title="Start Date"
                required={true}
                date={parseFromFirebaseDate(bore?.startDate)}
                minDate={parseFromFirebaseDate(formState?.job?.startDate)}
                maxDate={parseFromFirebaseDate(formState?.job?.endDate)}
                hint="The first day for the bore."
                onDateSelected={(date) => {
                  setBore((previousState: any) => {
                    return {
                      ...previousState,
                      startDate: toFirebaseDate(date),
                    };
                  });
                }}
              />
              <EQDatePicker
                title="End Date"
                date={parseFromFirebaseDate(bore?.endDate)}
                minDate={parseFromFirebaseDate(formState?.job?.startDate)}
                maxDate={parseFromFirebaseDate(formState?.job?.endDate)}
                hint="The last day for the bore."
                onDateSelected={(date) => {
                  setBore((previousState: any) => {
                    return { ...previousState, endDate: toFirebaseDate(date) };
                  });
                }}
              />
              <EQSelect
                title="Expected Soil Conditions (optional)"
                selectorTitle="Select Soil Conditions"
                selectedValue={bore?.soilConditions ?? ""}
                items={getSoilConditionItems()}
                onSelectionChanged={(item) => {
                  setBore((previousState: any) => {
                    return { ...previousState, soilConditions: item.value };
                  });
                }}
              />
              <EQInputText
                title="Bore Length"
                required={true}
                isNumerical={true}
                hint="The total distance expected for this bore (ft)."
                inputText={bore?.boreLength?.toString() ?? ""}
                onOnInputChange={(value) => {
                  setBore((previousState: any) => {
                    return { ...previousState, boreLength: value };
                  });
                }}
              />
              <div
                key="task-new-products"
                className="form-group card card-border p-2"
              >
                {(bore?.products?.length ?? 0) < 5 && (
                  <div className="mb-5">
                    <div className="h4 mb-5">Products</div>
                    <a
                      role="button"
                      onClick={() => {
                        setBore((previousState: any) => {
                          const products: TaskProductEntity[] = [
                            ...(previousState?.products ?? []),
                          ];
                          products.push(new TaskProductEntity());
                          return { ...previousState, products: products };
                        });
                      }}
                      className="navi-link"
                    >
                      Add product
                    </a>
                  </div>
                )}
                {bore?.products?.map((product: any, index: any) => {
                  return (
                    <TaskProduct
                      title={`Product #${index + 1}`}
                      productType={product}
                      onChange={(value: TaskProductEntity) => {
                        setBore((previousState: any) => {
                          const newProducts = [
                            ...(previousState?.products ?? []),
                          ];
                          newProducts[index] = value;
                          return { ...previousState, products: newProducts };
                        });
                      }}
                      onDelete={(product) => {
                        const itemIndex =
                          bore.products?.findIndex((boreProduct) => {
                            return (
                              boreProduct.type == product.type &&
                              boreProduct.quantity == product.quantity &&
                              boreProduct.size == product.size
                            );
                          }) ?? -1;
                        const boreProducts = [...(bore.products ?? [])];
                        if (itemIndex > -1 && boreProducts.length > 0) {
                          boreProducts.splice(itemIndex, 1);
                          setBore((previousState) => {
                            return { ...previousState, products: boreProducts };
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
              {bore.id && 
              <EQCheckBox
                title="Finished"
                checked={bore.finished}
                hint="Check here to finish and finalize this bore."
                onChecked={(value)=>{
                  setBore((previousState)=>{return {...previousState, finished:value}})
                }}
              />}
              <EQSelect
                title="Crew (optional)"
                selectorTitle="Select crew"
                selectedValue={
                  getAvailableCrews().find((item) => item.key == bore.crewID)
                    ?.value ?? ""
                }
                items={getAvailableCrews()}
                onSelectionChanged={(item) => {
                  setBore((previousState: any) => {
                    return { ...previousState, crewID: item.key };
                  });
                }}
              />
              <EQInputText
                title="Notes (optional)"
                numberOfLines={3}
                inputText={bore?.notes ?? ""}
                onOnInputChange={(value) => {
                  setBore((previousState: any) => {
                    return { ...previousState, notes: value };
                  });
                }}
              />
              <div>
                <button
                  key="segment-new-btn-save"
                  className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2"
                  disabled={!canSave()}
                  onClick={(event) => {
                    event.preventDefault();
                    //bore has id which means this bore is being edited and task was already created
                    //we are not editing task from bore
                    if (!bore.id) {
                      showCreateTaskDialog();
                    } else {
                      saveBore(false);
                    }
                  }}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
                >
                  Cancel
                </button>
              </div>
              <ConfirmModal
                show={formState.showAddTaskConfirmationDialog}
                title={getMessages("add_bore_task")}
                body={getMessages("should_we_automatically_add_task_for_bore")}
                yesButton={{
                  title: "Yes",
                  onClick: () => {
                    setFormState((previousState: any) => ({
                      ...previousState,
                      showAddTaskConfirmationDialog: false,
                    }));
                    saveBore(true);
                  },
                }}
                noButton={{
                  title: "No",
                  onClick: () => {
                    setFormState((previousState: any) => ({
                      ...previousState,
                      showAddTaskConfirmationDialog: false,
                    }));
                    saveBore(false);
                  },
                }}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BoresNew;
