import React, { useContext, useEffect, useState } from "react";
import LocateTicketNewMap from "./LocateTicketNewMap";
import {
  LocateTicketFileEntity,
  LocateTicketEntity,
  LocateTicketStatusEntity,
  getLocateTicketFilesUrl,
} from "../../../shared/data/LocateTickets";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { parseFromFirebaseDate } from "../../../shared/Util";
import {
  getLocateTicketFiles,
  saveLocateTicket,
  updateLocateTicketFiles,
} from "../../../shared/api/LocateTicketsApi";
import { useHistory } from "react-router";
import { Location } from "../../../shared/data/Location";
import { AuthContext } from "../../../shared/Auth";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import DropzoneInput from "../../../components/Form/Inputs/DropzoneInput";
import MultipleItems from "../../../components/Carousels/MultipleItems";
import { ImageEntity } from "../../../shared/data/taskmanagement/TimeLog";
import { isImageFile, uploadFile } from "../../../shared/api/Upload";
import { JobEntity } from "../../../shared/data/job/Job";

interface LocateTicketProps {
  locateTicket: LocateTicketEntity;
  jobs?: JobEntity[] | null;
}

const LocateTicketNew = ({ locateTicket, jobs = null }: LocateTicketProps) => {
  const context: ContextEntity = useContext(AuthContext);
  const history = useHistory();

  const [locateTicketState, setLocateTicketState] =
    useState<LocateTicketEntity | null>(locateTicket);

  const [locateTicketFiles, setLocateTicketFiles] = useState<ImageEntity[]>([]);

  const [initialLocateTicketFiles, setInitialLocateTicketFiles] = useState<
    LocateTicketFileEntity[]
  >([]);

  const [filesToHandle, setFilesToHandle] = useState<any>();

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, []);

  const loadData = async () => {
    if (locateTicketState != null && locateTicketState.id) {
      setLocateTicketFiles(() => []);
      const files = await getLocateTicketFiles(locateTicketState.id);
      setInitialLocateTicketFiles(() => files.data ?? []);
      const urls = await getLocateTicketFilesUrl(
        locateTicketState,
        files.data ?? []
      );
      setLocateTicketFiles(() => urls);
    }
  };

  const onRemoveFile = (index: number) => {
    const files = [...locateTicketFiles];
    const fileToRemove = files[index];
    files.splice(index, 1);
    setLocateTicketFiles(() => {
      return files;
    });

    const fileToDelete = initialLocateTicketFiles.find(
      (file) => file.createdAt == fileToRemove.value
    )?.id;
    if (fileToDelete) {
      setFilesToHandle((previousState: any) => {
        return {
          ...previousState,
          filesToDelete: [
            ...(previousState?.filesToDelete ?? []),
            fileToDelete,
          ],
        };
      });
    }
  };

  const onAddFile = (acceptedFiles: any) => {
    const url = window.URL.createObjectURL(acceptedFiles[0]);
    const imageEntity: ImageEntity = {
      file: acceptedFiles[0],
      value: Timestamp.now(),
      src: url,
    };
    setLocateTicketFiles((previousState) => {
      return [...previousState, imageEntity];
    });

    setFilesToHandle((previousState: any) => {
      return {
        ...previousState,
        filesToUpload: [...(previousState?.filesToUpload ?? []), imageEntity],
      };
    });
  };

  const save = async () => {
    const locateTicketToSave = { ...locateTicketState };
    if (locateTicketState) {
      if (locateTicketState.status?.length == 0) {
        const initialStatus: LocateTicketStatusEntity = {
          createdAt: Timestamp.now(),
          date: Timestamp.now(),
          type: "Ready to submit",
          userID: context.currentUser?.uid,
          userName: context.currentUser?.displayName,
        };
        locateTicketToSave.status = [initialStatus];
        setLocateTicketState((previousState) => {
          return { ...previousState, status: [initialStatus] };
        });
      }
      const result = await saveLocateTicket(locateTicketToSave);
      if (result.data?.id) {
        handleFiles(result.data?.id);
      }
    }
    history.goBack();
  };

  const validateForm = () => {
    return (
      locateTicketState?.ticketID != null && locateTicketState.startDate != null
    );
  };

  const handleFiles = async (documentId: string) => {
    if (locateTicketFiles.length > 0) {
      await filesToHandle?.filesToUpload?.forEach(async (file: any) => {
        if (file.file && file.value) {
          const result = await uploadFile(
            context.currentUser?.userProfile?.companyID ?? "",
            "locateTicket",
            documentId,
            file.value,
            file.file
          );
        }
      });

      const filesToUpload =
        filesToHandle?.filesToUpload?.map((file: ImageEntity) => {
          return {
            createdAt: file.value,
            name: file.file.name,
            fileName: file.file.name,
            type: isImageFile(file.file.name) ? "image" : "document",
          };
        }) ?? [];

      updateLocateTicketFiles(
        documentId,
        filesToHandle.filesToDelete ?? [],
        filesToUpload
      );
    }
  };

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h3 className="mb-10 font-weight-bold text-dark">
            {locateTicketState?.id ? `Edit locate ticket` : `Add new locate ticket`}
          </h3>
          <div key="locateTicketId" className="form-group">
            <label>
              Locate Ticket ID<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={locateTicketState?.ticketID ?? ""}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return { ...previousState, ticketID: input.target.value };
                });
              }}
            ></input>
            <div className="form-text text-muted">
              ID for this locate ticket.
            </div>
          </div>
          {jobs && (
            <div key="locate-ticket-new-job-selection" className="form-group">
              <label>Select job</label>
              <select
                key="task-new-task-type-select"
                className="form-control"
                onChange={(event) => {
                  const jobId = event.target.value;
                  setLocateTicketState((previousState) => {
                    return { ...previousState, jobID: jobId };
                  });
                }}
              >
                <option value="" disabled selected>
                  Select job
                </option>
                {jobs?.map((item, index) => {
                  return (
                    <option
                      key={"job" + index}
                      value={item.id ?? ""}
                      className="form-control"
                      selected={item.id == locateTicketState?.jobID}
                    >
                      {item.jobID}({item.title})
                    </option>
                  );
                })}
                ;
              </select>
            </div>
          )}

          <div key="locateTicketStartDate" className="form-group">
            <label>
              Work Start Date<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              value={parseFromFirebaseDate(
                locateTicketState?.startDate,
                "YYYY-MM-DD"
              )}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return {
                    ...previousState,
                    startDate: Timestamp.fromDate(
                      moment(input.target.value).toDate()
                    ),
                  };
                });
              }}
            ></input>
            <div className="form-text text-muted">
              The first day of the work.
            </div>
          </div>
          {/* <div key="locateTicketIdUtilityCompany" className="form-group">
            <label>Utility Location Company</label>
            <input
              type="text"
              className="form-control"
              value={locateTicketState?.utilityCompany ?? ""}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return {
                    ...previousState,
                    utilityCompany: input.target.value,
                  };
                });
              }}
            ></input>
            <div className="form-text text-muted">
              Company responsible to deliver approval for locate tickets.
            </div>
          </div> */}
          {/* <div key="locateTicketIdUtilityCompanyAddress" className="form-group">
            <label>Utility Location Company Address</label>
            <input
              type="text"
              className="form-control"
              value={locateTicketState?.utilityCompanyAddress ?? ""}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return {
                    ...previousState,
                    utilityCompanyAddress: input.target.value,
                  };
                });
              }}
            ></input>
            <div className="form-text text-muted">
              Address of company responsible to deliver approval for locate
              tickets.
            </div>
          </div> */}
          <div key="locateTicketNotes" className="form-group">
            <label>Notes</label>
            <input
              type="text"
              className="form-control"
              value={locateTicketState?.notes ?? ""}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return { ...previousState, notes: input.target.value };
                });
              }}
            ></input>
            <div className="form-text text-muted">Notes about this ticket.</div>
          </div>
          <div key="locateTicketMap" className="form-group">
            <label>
              Locate Ticket Location<span className="text-danger">*</span>
            </label>
            <LocateTicketNewMap
              locateTicketCurrentLocation={
                new Location(
                  locateTicket.lat ?? 0,
                  locateTicket.lng ?? 0,
                  locateTicket.address ?? ""
                )
              }
              onLocationAdded={(result) => {
                setLocateTicketState((previousState) => {
                  return {
                    ...previousState,
                    lat: result?.lat ?? null,
                    lng: result?.lng ?? null,
                    address: result?.address ?? null,
                  };
                });
              }}
            />
          </div>
          <DropzoneInput
            {...{
              field: {
                onDrop: onAddFile,
              },
              supportedTypes: "Photos or Documents",
            }}
          />
          <div className="mt-5 mb-5">
            <MultipleItems
              slidesToShow={9}
              laptop={7}
              tablet={5}
              mobile={2}
              images={locateTicketFiles?.map(
                (locateTicketFile) => locateTicketFile.src
              )}
              removePhoto={(index: number) => {
                onRemoveFile(index);
              }}
            />
          </div>
          <div className="justify-content-between border-top mt-5 pt-10">
            <button
              key="segment-new-btn-save"
              className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2"
              disabled={!validateForm()}
              onClick={(event) => {
                event.preventDefault();
                save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocateTicketNew;
